import React, {ForwardedRef, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {STATIC} from "../constants";
import {APIException, get_playlist, is_admin, modify_playlist, reorder_playlist} from "../api";
import {PlaylistTrackCard} from "../components/cards/SortableTrackCard";
import {DropdownButton, DropdownButtonImage, MIDTITLE_FONT_SIZE, PASTEL_PINK, PASTEL_PURPLE, REGULAR_FONT_SIZE, SMALL_FONT_SIZE} from "../components/reusable/styled/StyledComponents";
import styled from "styled-components";
import {CompletePlaylist, PlaylistUtils, SortDirection, SortTrackBy, TrackUtils} from "../types";
import {setWindowProperties} from "../util/format";
import {BodyContainer, ContentContainer} from "../components/reusable/styled/Container";
import {BannerButtonImage, BannerButtonText, WideBannerButton} from "../components/reusable/styled/BannerButton";
import {TrackSortHeaders} from "../components/SortHeader";
import {DragDropContext, Droppable, DropResult} from "react-beautiful-dnd";
import {Helmet} from "react-helmet";
import {PlaylistMenu, UserMenu} from "../components/reusable/functional/Menu";
import * as ContextMenu from "@radix-ui/react-context-menu";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";
import {showAddURLTrackOverlay} from "../redux-store/overlaySlice";
import {Lost} from "./lost";

const PlaylistContentContainer = styled(ContentContainer)`
    grid-template-columns: auto 15px auto auto 1fr 15px auto 15px auto;
    grid-template-rows: auto 10px auto 5px auto 25px auto 10px auto;
`;

const Cover = styled.img`
    grid-column: 1;
    grid-row: 1 / 6;
    width: 125px;
    height: 125px;
    cursor: pointer;
    box-shadow: 0 0 10px 5px #00000040;
    transition: all 0.1s ease-in-out;

    &:hover {
        transform: scale(1.02);
    }
`;

const Text = styled.p`
    color: ${PASTEL_PURPLE};
    justify-self: start;
    max-width: 100%;
`;

const Name = styled(Text)`
    height: 60px;
    line-height: 60px;

    font-size: ${MIDTITLE_FONT_SIZE};
    font-weight: bold;
    grid-column: 3 / 6;
    grid-row: 1;

    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    /*TODO figure out how to hide the elements underneath &:hover {
        white-space: normal;
        overflow: initial;
        text-overflow: initial;
    }*/
`;

const Description = styled(Name)`
    height: 30px;
    line-height: 30px;
    font-size: ${REGULAR_FONT_SIZE};
    font-weight: normal;
    grid-column: 3 / 6;
    grid-row: 3;
`;

const Owner = styled(Text)`
    padding: 0 0 0 15px;
    font-size: ${SMALL_FONT_SIZE};
    font-weight: normal;
    white-space: pre;
    height: 20px;
    line-height: 20px;
    grid-column: 3;
    grid-row: 5;
    transition: all 0.1s ease-in-out;

    &:hover {
        color: ${PASTEL_PINK};
    }
`;

const Length = styled(Text)`
    padding: 0 15px 0 0;
    font-size: ${SMALL_FONT_SIZE};
    font-weight: normal;
    white-space: pre;
    overflow: hidden;
    height: 20px;
    line-height: 20px;
    grid-column: 5;
    grid-row: 5;
`;

const Public = styled.img`
    width: 50px;
    height: 50px;
    cursor: pointer;
    grid-column: 7;
    grid-row: 1 / 6;
    transition: all 0.1s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

const PositionedDropdownButton = styled(DropdownButton)`
    grid-column: 9;
    grid-row: 1 / 6;
`;

const AddTrackByUrlButton = styled(WideBannerButton)`
    grid-column: 1 / span 9999;
    grid-row: 9;
`;

const PlaylistTrackResults = styled.div`
    width: 100%;
    grid-column: 1 / span 9999;
    grid-row: 10;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
`;

export function Playlist() {
    const params = useParams();
    const playlistId = Number(params.id);

    const dispatch = useDispatch();
    const [playlist, setPlaylist] = useState<CompletePlaylist | null>(PlaylistUtils.empty() as CompletePlaylist);
    const [isOwner, setIsOwner] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [sortBy, setSortBy] = useState<SortTrackBy>("default");
    const [sortDirection, setSortDirection] = useState<SortDirection>(null);

    const [cards, setCards] = useState<React.JSX.Element[]>([]);
    const [state, setState] = useState(true); // whenever playlist is modified
    const refreshState = () => setState(!state);
    const addTrackPayload = {playlistId, refresh: refreshState};

    useEffect(() => {
        (async() => {
            try {
                setIsAdmin(await is_admin());
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, []);

    useEffect(() => {
        (async() => {
            try {
                const results = await get_playlist(playlistId);
                const playlist = results.playlist;
                setPlaylist(playlist);
                setIsOwner(results.is_owner);
                setWindowProperties(playlist.name, "Listen to " + playlist.name + " on Amogustream.",
                    playlist.cover);
            } catch (e) {
                if (e instanceof APIException) {
                    toast.error(e.message);
                    setPlaylist(null);
                }
            }
        })();
    }, [state, playlistId]);

    useEffect(() => {
        if (playlist == null) return;
        let actualCards = [];
        for (const track of TrackUtils.sort(playlist.tracks, sortBy, sortDirection)) {
            actualCards.push(
                <PlaylistTrackCard
                    track={track}
                    playlist={playlist}
                    number={actualCards.length + 1}
                    draggable={sortBy == "default" && isOwner}
                    isOwner={isOwner}
                    isAdmin={isAdmin}
                    refreshState={refreshState}
                    sortTrackBy={sortBy}
                    sortDirection={sortDirection}
                    key={track.track_id}
                />
            );
        }
        setCards(actualCards);
    }, [playlist, sortBy, sortDirection]);

    // Other Functions
    const onPublicChange = async() => {
        try {
            await modify_playlist(playlistId, null, null, null, !playlist!.public);
            refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    const onSort = (newSortBy: SortTrackBy) => {
        if (newSortBy != sortBy) {
            setSortDirection("ascending");
            setSortBy(newSortBy);
        } else if (sortDirection == "ascending") {
            setSortDirection("descending");
        } else if (sortDirection == "descending") {
            setSortDirection(null);
            setSortBy("default");
        }
    };

    const onReorder = async(result: DropResult) => {
        const {source, destination} = result;
        if (destination == null || destination.index == source.index) return;

        const newCards = Array.from(cards);
        const [sourceCard] = newCards.splice(source.index, 1);
        newCards.splice(destination.index, 0, sourceCard);

        const updatedCards = [];
        let trackIds = [];
        for (const card of newCards) {
            updatedCards.push(<PlaylistTrackCard {...card.props} number={updatedCards.length + 1}/>);
            trackIds.push(card.props.track.track_id);
        }
        setCards(updatedCards);
        await reorder_playlist(playlistId, trackIds);
        refreshState();
    };

    if (playlist == null) return <Lost/>;

    return <BodyContainer>
        <Helmet>
            <meta property="og:title" content={playlist.name}/>
            <meta property="og:description" content={"Listen to " + playlist.name + " on Amogustream."}/>
            <meta property="og:image" content={playlist.cover}/>
        </Helmet>
        <PlaylistContentContainer>
            <Cover src={playlist.cover}/>
            <Name>{PlaylistUtils.formatName(playlist.name)}</Name>
            <Description>{PlaylistUtils.formatDescription(playlist.description)}</Description>
            <UserMenu
                menuType={ContextMenu}
                component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                    <Owner as={Link} to={"/user/" + playlist.owner.username} {...props} ref={forwardedRef}>
                        {playlist.owner.username}
                    </Owner>
                )}
                id={playlist.owner.username}
            />
            <Length>
                {"  ●  " + PlaylistUtils.formatNumTracks(playlist.tracks.length) + " (" +
                    PlaylistUtils.formatDuration(playlist.tracks) + ")"}
            </Length>
            {isOwner &&
                <Public src={STATIC + (playlist.public ? "public.png" : "private.png")} onClick={onPublicChange}/>
            }
            <PlaylistMenu
                menuType={DropdownMenu}
                component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                    <PositionedDropdownButton {...props} ref={forwardedRef}>
                        <DropdownButtonImage src={STATIC + "dropdown.png"}/>
                    </PositionedDropdownButton>
                )}
                playlist={playlist}
                isOwner={isOwner}
                refreshState={refreshState}
                navigate={true}
            />

            <TrackSortHeaders gridRow={7} onSort={onSort} stateSortBy={sortBy} stateSortDirection={sortDirection}/>

            {isOwner &&
                <AddTrackByUrlButton onClick={() => dispatch(showAddURLTrackOverlay(addTrackPayload))}>
                    <BannerButtonImage src={STATIC + "link.png"}/>
                    <BannerButtonText>Add a track by URL</BannerButtonText>
                </AddTrackByUrlButton>
            }

            <DragDropContext onDragEnd={onReorder}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <PlaylistTrackResults ref={provided.innerRef} {...provided.droppableProps}>
                            {cards}
                            {provided.placeholder}
                        </PlaylistTrackResults>
                    )}
                </Droppable>
            </DragDropContext>
        </PlaylistContentContainer>
    </BodyContainer>
}