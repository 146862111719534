import React, {useEffect, useState} from "react";
import {PlaylistTabContent} from "../components/library/PlaylistTabContent";
import {getStorageKey} from "../redux-store/userSlice";
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";
import styled from "styled-components";
import {ArtistTabContent} from "../components/library/ArtistTabContent";
import {setWindowProperties} from "../util/format";
import {startCase} from "lodash";
import {BodyContainer, ContentContainer} from "../components/reusable/styled/Container";
import {Tab} from "../components/reusable/styled/Tab";
import {Title} from "../components/reusable/styled/Title";

const LibraryContentContainer = styled(ContentContainer)`
    grid-template-columns: auto 15px auto 15px auto 15px auto 1fr;
    grid-template-rows: repeat(2, auto 15px) 1fr;
`;

const LibraryTab = styled(Tab)`
    grid-row: 3;
`;

const PlaylistsTab = styled(LibraryTab)`
    grid-column: 1;
`;

const ExternalPlaylistsTab = styled(LibraryTab)`
    grid-column: 3;
`;

const ArtistsTab = styled(LibraryTab)`
    grid-column: 5;
`;

const RecentlyPlayedTab = styled(LibraryTab)`
    grid-column: 7;
`;

type Tab = "playlists" | "externalPlaylists" | "artists" | "recentlyPlayed";

export function Library() {
    const [selected, setSelected] = useState<Tab>("playlists");

    if (!useSelector(getStorageKey)) {
        return <Navigate to="/"/>
    }

    useEffect(() => {
        setWindowProperties(startCase(selected));
    });

    return <BodyContainer>
        <LibraryContentContainer>
            <Title>Library</Title>

            <PlaylistsTab selected={selected == "playlists"} onClick={() => setSelected("playlists")}>
                Playlists
            </PlaylistsTab>
            <ExternalPlaylistsTab selected={selected == "externalPlaylists"} onClick={() => setSelected("externalPlaylists")}>
                External Playlists
            </ExternalPlaylistsTab>
            <ArtistsTab selected={selected == "artists"} onClick={() => setSelected("artists")}>
                Artists
            </ArtistsTab>
            <RecentlyPlayedTab selected={selected == "recentlyPlayed"} onClick={() => setSelected("recentlyPlayed")}>
                Recently Played
            </RecentlyPlayedTab>

            <PlaylistTabContent visible={selected == "playlists"}/>
            <ArtistTabContent visible={selected == "artists"}/>
        </LibraryContentContainer>
    </BodyContainer>
}