import React, {ForwardedRef, useContext} from "react";
import {ArtistBase, ContinuousPlaybackSource, ContinuousPlaybackSourceUtils, ExternalUtils, Platform, PlaylistBase, SortDirection, SortExternalTrackBy, SortTrackBy, TrackBase, TrackExternal, TrackUtils} from "../../types";
import {getDateFormatFromString, getExternalLink, getTimeFormat} from "../../util/format";
import {STATIC} from "../../constants";
import {BACKGROUND, PASTEL_NAVY, PASTEL_PINK, PASTEL_PURPLE, SELECTION, SmallCard, SmallCardImage, SmallCardLeftText, SmallCardRightText, SmallDropdownButton, SmallDropdownButtonImage, Tooltip, WHITE_TO_GRAY, WHITE_TO_PLATFORM} from "../reusable/styled/StyledComponents";
import styled, {css} from "styled-components";
import {globalContext} from "../../index";
import {useDispatch, useSelector} from "react-redux";
import {canPlayExternal, hasValidPlaybackPlatform} from "../../util/functions";
import {getPlaybackPlatforms} from "../../redux-store/oAuth2Slice";
import {omit} from "lodash";
import {Draggable} from "react-beautiful-dnd";
import {getExternalId, getPlatform, getPlaying, getTrack} from "../../redux-store/playbackSlice";
import {PlaybackExternalSources} from "../reusable/functional/PlaybackExternalSource";
import {ArtistMenu, PlaylistTrackMenu, RedirectMenu, TrackExternalMenu, TrackMenu} from "../reusable/functional/Menu";
import * as ContextMenu from "@radix-ui/react-context-menu";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {Link} from "react-router-dom";
import {showTrackExternalsOverlay} from "../../redux-store/overlaySlice";
import {APIException, remove_track_artist} from "../../api";
import toast from "react-hot-toast";

/* TRACK CARDS */
const BaseSortableTrackCardContainer = styled(SmallCard)`
    grid-template-columns: 30px 20px   40px 10px 310px 10px   360px 10px   80px   110px   110px   40px; // track
`;

const SortableExternalTrackCardContainer = styled(SmallCard)`
    grid-template-columns: 30px 20px 40px 10px 700px 10px 160px 10px 110px 40px; // external track
`;

const CoverContainer = styled.div`
    width: 40px;
    height: 40px;
    z-index: 10;
    display: grid;
    justify-items: center;
    align-items: center;
`;

const CoverImage = styled(SmallCardImage)`
    object-fit: contain;
    background-color: #00000040;
    grid-column: 1;
    grid-row: 1;
`;

const PlayButtonContainer = styled.div`
    width: 40px;
    height: 40px;
    z-index: 10;
    grid-column: 1;
    grid-row: 1;
    display: grid;
    justify-items: center;
    align-items: center;
    opacity: 0;
    transition: all 0.1s ease-in-out;

    ${BaseSortableTrackCardContainer}:hover &,
    ${SortableExternalTrackCardContainer}:hover & {
        cursor: pointer;
        background-color: ${BACKGROUND}80;
        opacity: 1;
    }
`;

const PlayButton = styled.img`
    width: 20px;
    height: 20px;
`;

interface SmallCardTextProps {
    canPlay: boolean;
    currentlyPlaying: boolean;
}

const SmallCardLeftTextColoured = styled(SmallCardLeftText)<SmallCardTextProps>`
    ${props => !props.canPlay && css`
        color: gray;
    `};
    ${props => props.currentlyPlaying && css`
        color: ${PASTEL_PINK};
    `}
`;

const SmallCardRightTextColoured = styled(SmallCardRightText)<SmallCardTextProps>`
    ${props => !props.canPlay && css`
        color: gray;
    `};
    ${props => props.currentlyPlaying && css`
        color: ${PASTEL_PINK};
    `}
`;

const Name = styled(SmallCardLeftTextColoured)`
    transition: all 0.1s ease-in-out;

    &:hover {
        color: ${PASTEL_PURPLE};
    }
`;

// TODO remove side scrolling in favour of something else idk what yet
const Artists = styled.div`
    width: 100%;

    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(9999, auto) 1fr;

    overflow-x: auto;
    overflow-y: hidden;
    
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track-piece:start,
    &::-webkit-scrollbar-track-piece:end {
        visibility: hidden;
    }
    
    &:hover::-webkit-scrollbar,
    &:hover::-webkit-scrollbar-thumb,
    &:hover::-webkit-scrollbar-track-piece:start,
    &:hover::-webkit-scrollbar-track-piece:end {
        visibility: visible;
    }
`;

const ArtistSeparator = styled(SmallCardLeftTextColoured)`
    min-width: max-content;
    padding: 0 0 0 0;
    white-space: pre;
`;

const Artist = styled(SmallCardLeftTextColoured)`
    min-width: max-content;
    padding: 0 0 0 0;
    white-space: pre;
    transition: all 0.1s ease-in-out;

    &:hover {
        color: ${PASTEL_PURPLE};
    }
`;

const Externals = styled.div`
    cursor: pointer;
    display: grid;
    justify-self: right;
    align-items: center;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    transition: transform 0.1s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

interface ExternalProps {
    canPlay: boolean;
    currentlyPlaying: boolean;
    platform: Platform;
}

const External = styled(Link)`
    justify-self: right;
    width: 25px;
    height: 25px;
    display: grid;
`;

const ExternalImage = styled.img<ExternalProps>`
    width: 25px;
    height: 25px;
    transition: all 0.1s ease-in-out;
    ${props => !props.canPlay && css`
        filter: ${WHITE_TO_GRAY};
    `};
    ${props => props.currentlyPlaying && css`
        filter: ${WHITE_TO_PLATFORM(props.platform)};
    `}

    &:hover {
        transform: scale(1.1);
    }
`;


interface BaseSortableTrackCardProps {
    track: TrackBase;
    number: number;
    continuousPlaybackSource: ContinuousPlaybackSource;
    dropdown: (props: any) => React.JSX.Element;
    dropdownProps: any;
    draggable: boolean;
    isAdmin: boolean;
    refreshState: () => void;
    sortTrackBy: SortTrackBy;
    sortDirection: SortDirection;
}

export function BaseSortableTrackCard(props: BaseSortableTrackCardProps) {
    const {macroController} = useContext(globalContext);
    const dispatch = useDispatch();
    const continuousPlaybackSource = props.continuousPlaybackSource;
    const track = props.track;
    const playingTrack = useSelector(getTrack);
    const playing = useSelector(getPlaying);
    const externalId = useSelector(getExternalId);
    const platform = useSelector(getPlatform);
    const currentlyPlaying = TrackUtils.currentlyPlaying(track, playingTrack, playing);

    const validPlatforms = useSelector(getPlaybackPlatforms);
    const canPlay = hasValidPlaybackPlatform(track, validPlatforms);

    const onPlay = async() => {
        await macroController.macroTogglePlay(track, continuousPlaybackSource);
    };

    /*const getStyle = (style: DraggingStyle | NotDraggingStyle, snapshot: DraggableStateSnapshot) => {
        if (!snapshot.isDropAnimating) return style;
        return {
            ...style,
        };
    };*/

    let artists: React.JSX.Element[] = [];
    for (const artist of track.artists) {
        const key = artist.artist_id;

        const onRemoveArtist = async() => {
            try {
                await remove_track_artist(props.track.track_id!, artist.artist_id!);
                toast.success(`Successfully removed artist ${artist.name} (${artist.artist_id}) from track "${track.name}" (${track.track_id}).`);
                props.refreshState();
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        };

        let extraItems: any[][];
        if (props.isAdmin) {
            extraItems = [
                [
                    ["remove", "Remove artist from track", onRemoveArtist],
                ]
            ]
        } else {
            extraItems = [
                []
            ];
        }

        artists.push(
            <ArtistMenu
                menuType={ContextMenu}
                component={React.forwardRef((nestedProps, forwardedRef: ForwardedRef<any>) =>
                    <Artist as={Link} to={"/artist/" + artist.artist_id} canPlay={canPlay} currentlyPlaying={currentlyPlaying} key={key} {...nestedProps} ref={forwardedRef}>
                        {artist.name}
                    </Artist>
                )}
                artist={artist}
                isAdmin={props.isAdmin}
                refreshState={props.refreshState}
                extraItems={extraItems}
            />,
            <ArtistSeparator canPlay={canPlay} currentlyPlaying={currentlyPlaying} key={key + "separator"}>
                {", "}
            </ArtistSeparator>
        );
    }
    artists.pop();

    return <Draggable draggableId={String(track.track_id)} key={track.track_id!} index={props.number - 1}
                      isDragDisabled={!props.draggable}>
        {(provided, snapshot) => (
            <BaseSortableTrackCardContainer ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            /*style={getStyle(provided.draggableProps.style!, snapshot)}*/>
                <SmallCardRightTextColoured canPlay={canPlay} currentlyPlaying={currentlyPlaying}>
                    {props.number}
                </SmallCardRightTextColoured>
                <div/>
                {track.externals.length > 1 ?
                    <Tooltip backgroundColour={`linear-gradient(to top, ${PASTEL_NAVY} 0%, ${SELECTION} 100%)`}
                             appendTo={document.body} interactive={true} placement="top-start"
                             content={<PlaybackExternalSources track={track}
                                                               continuousPlaybackSource={continuousPlaybackSource}/>}>
                        <CoverContainer onClick={onPlay}>
                            <CoverImage src={TrackUtils.equals(track, playingTrack) ?
                                TrackUtils.getCover(track, externalId, platform) :
                                TrackUtils.getCover(track)}/>
                            <PlayButtonContainer hidden={!canPlay}>
                                <PlayButton src={STATIC + (currentlyPlaying ? "pause.png" : "play.png")}/>
                            </PlayButtonContainer>
                        </CoverContainer>
                    </Tooltip> :
                    <CoverContainer onClick={onPlay}>
                        <CoverImage src={TrackUtils.equals(track, playingTrack) ?
                            TrackUtils.getCover(track, externalId, platform) :
                            TrackUtils.getCover(track)}/>
                        <PlayButtonContainer hidden={!canPlay}>
                            <PlayButton src={STATIC + (currentlyPlaying ? "pause.png" : "play.png")}/>
                        </PlayButtonContainer>
                    </CoverContainer>
                }
                <div/>
                <props.dropdown
                    {...props.dropdownProps}
                    menuType={ContextMenu}
                    component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                        <Name
                            as={Link}
                            to={"/track/" + track.track_id}
                            currentlyPlaying={currentlyPlaying}
                            canPlay={canPlay}
                            {...props}
                            ref={forwardedRef}
                        >
                            {track.name}
                        </Name>
                    )}
                />
                <div/>
                <Artists>{artists}</Artists>
                <div/>
                <SmallCardLeftTextColoured canPlay={canPlay} currentlyPlaying={currentlyPlaying}>
                    {getDateFormatFromString(TrackUtils.getEarliestReleaseDate(track))}
                </SmallCardLeftTextColoured>
                <SmallCardRightTextColoured canPlay={canPlay} currentlyPlaying={currentlyPlaying}>
                    {getTimeFormat((TrackUtils.equals(track, playingTrack) ?
                        TrackUtils.getDuration(track, externalId, platform) :
                        TrackUtils.getDuration(track)) / 1000)}
                </SmallCardRightTextColoured>
                <Externals onClick={() => dispatch(showTrackExternalsOverlay(track))}>
                    {ExternalUtils.toJsx(track.externals, canPlay, track, playingTrack, playing, platform, 25)}
                </Externals>
                <props.dropdown
                    {...props.dropdownProps}
                    menuType={DropdownMenu}
                    component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                        <SmallDropdownButton {...props} ref={forwardedRef}>
                            <SmallDropdownButtonImage src={STATIC + "dropdown.png"}/>
                        </SmallDropdownButton>
                    )}
                />
            </BaseSortableTrackCardContainer>
        )}
    </Draggable>
}

interface PlaylistTrackCardProps {
    track: TrackBase;
    playlist: PlaylistBase;
    number: number;
    draggable: boolean;
    isOwner: boolean;
    isAdmin: boolean;
    refreshState: () => void;
    sortTrackBy: SortTrackBy;
    sortDirection: SortDirection;
}

export function PlaylistTrackCard(props: PlaylistTrackCardProps) {
    return <BaseSortableTrackCard
        {...omit(props, ["playlist", "isOwner"])}
        continuousPlaybackSource={{
            playlistId: props.playlist.playlist_id,
            artistId: null,
            trackId: props.track.track_id,
            sortTrackBy: props.sortTrackBy,
            sortDirection: props.sortDirection,
            cachedSourceName: props.playlist.name,
        }}
        dropdown={PlaylistTrackMenu}
        dropdownProps={{
            playlistId: props.playlist.playlist_id!,
            track: props.track,
            isOwner: props.isOwner,
            isAdmin: props.isAdmin,
            refreshState: props.refreshState,
        }}
    />
}

interface ArtistTrackCardProps {
    track: TrackBase;
    artist: ArtistBase;
    number: number;
    isAdmin: boolean;
    refreshState: () => void;
    sortTrackBy: SortTrackBy;
    sortDirection: SortDirection;
}

export function ArtistTrackCard(props: ArtistTrackCardProps) {
    return <BaseSortableTrackCard
        {...omit(props, ["artist"])}
        draggable={false}
        continuousPlaybackSource={{
            playlistId: null,
            artistId: props.artist.artist_id,
            trackId: props.track.track_id,
            sortTrackBy: props.sortTrackBy,
            sortDirection: props.sortDirection,
            cachedSourceName: props.artist.name,
        }}
        dropdown={TrackMenu}
        dropdownProps={{
            track: props.track,
            isAdmin: props.isAdmin,
            refreshState: props.refreshState,
            extraItems: [[]],
        }}
    />
}

/* EXTERNAL CARDS */
interface SortableExternalTrackCardProps {
    track: TrackBase;
    external: TrackExternal;
    number: number;
    refreshState: () => void;
    sortTrackBy: SortExternalTrackBy;
    sortDirection: SortDirection;
    isAdmin: boolean;
}

export function SortableExternalTrackCard(props: SortableExternalTrackCardProps) {
    const track = props.track;
    const external = props.external;
    const platform = useSelector(getPlatform);
    const currentlyPlaying = TrackUtils.currentlyPlaying(track, useSelector(getTrack), useSelector(getPlaying),
        external.external_id, useSelector(getExternalId), external.platform, useSelector(getPlatform));
    const url = getExternalLink(external, "trackExternal");

    const validPlatforms = useSelector(getPlaybackPlatforms);
    const canPlay = canPlayExternal(track, external, validPlatforms);

    // Playback
    const {macroController} = useContext(globalContext);
    const onPlay = async() => {
        await macroController.macroTogglePlay(track, ContinuousPlaybackSourceUtils.empty(), external);
    };

    return <SortableExternalTrackCardContainer>
        <SmallCardRightTextColoured canPlay={canPlay} currentlyPlaying={currentlyPlaying}>
            {props.number}
        </SmallCardRightTextColoured>
        <div/>
        <CoverContainer onClick={onPlay}>
            <CoverImage src={external.cover}/>
            <PlayButtonContainer hidden={!canPlay}>
                <PlayButton src={STATIC + (currentlyPlaying ? "pause.png" : "play.png")}/>
            </PlayButtonContainer>
        </CoverContainer>
        <div/>
        <SmallCardLeftTextColoured canPlay={canPlay} currentlyPlaying={currentlyPlaying}>
            {external.name}
        </SmallCardLeftTextColoured>
        <div/>
        <SmallCardLeftTextColoured canPlay={canPlay} currentlyPlaying={currentlyPlaying}>
            {getDateFormatFromString(external.release_date)}
        </SmallCardLeftTextColoured>
        <div/>
        <RedirectMenu
            menuType={ContextMenu}
            component={React.forwardRef((nestedProps, forwardedRef: ForwardedRef<any>) =>
                <External to={url} target="_blank" {...nestedProps} ref={forwardedRef}>
                    <ExternalImage src={STATIC + external.platform + ".png"} canPlay={canPlay} currentlyPlaying={currentlyPlaying} platform={platform!}/>
                </External>
            )}
            url={url}
        />
        <TrackExternalMenu
            track={track}
            menuType={DropdownMenu}
            component={React.forwardRef((nestedProps, forwardedRef: ForwardedRef<any>) =>
                <SmallDropdownButton {...nestedProps} ref={forwardedRef}>
                    <SmallDropdownButtonImage src={STATIC + "dropdown.png"}/>
                </SmallDropdownButton>
            )}
            external={external}
            isAdmin={props.isAdmin}
            refreshState={props.refreshState}
        />
    </SortableExternalTrackCardContainer>
}