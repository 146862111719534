import React from "react";
import styled, {css} from "styled-components";
import {LARGE_FONT_SIZE, PASTEL_PINK, PASTEL_PURPLE, UnselectableText, WHITE_TO_PINK, WHITE_TO_PURPLE} from "./reusable/styled/StyledComponents";
import {SortBy, SortDirection, SortExternalTrackBy, SortPlaylistBy, SortTrackBy} from "../types";
import {STATIC} from "../constants";

const SortHeadersContainer = styled.div`
    width: 100%;
    grid-column: 1 / span 9999;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
`;

interface SortHeaderContainerProps {
    sortable: boolean;
    align: "left" | "right";
}

const SortHeaderContainer = styled.div<SortHeaderContainerProps>`
    height: 100%;
    display: grid;
    align-items: center;
    
    ${props => props.align == "left" ?
        css`grid-template-columns: auto 5px auto 1fr;` :
        css`grid-template-columns: 1fr auto 5px auto;`
    }

    ${props => props.sortable && css`
        cursor: pointer;
    `}
`;

interface SortHeaderTextProps {
    align: "left" | "right";
    sortable: boolean;
}

const NonSortHeaderText = styled(UnselectableText)<SortHeaderTextProps>`
    color: ${PASTEL_PURPLE};
    font-size: ${LARGE_FONT_SIZE};
    text-align: ${props => props.align};
    grid-column: ${props => props.align == "left" ? 1 : 4};
    grid-row: 1;
    transition: all 0.1s ease-in-out;

    ${props => props.align == "left" ?
        css`padding: 0 5px 0 0;` :
        css`padding: 0 0 0 5px;`
    }

    ${props => props.sortable && css`
        cursor: pointer;
    `}
`;

const SortHeaderText = styled(NonSortHeaderText)<SortHeaderTextProps>`
    ${SortHeaderContainer}:hover & {
        color: ${PASTEL_PINK};
    }
`;

interface SortHeaderIndicatorProps {
    align: "left" | "right";
}

const SortHeaderIndicator = styled.img<SortHeaderIndicatorProps>`
    width: 12px;
    height: 12px;
    filter: ${WHITE_TO_PURPLE};
    grid-column: ${props => props.align == "left" ? 3 : 2};
    grid-row: 1;
    transition: all 0.1s ease-in-out;
    
    ${SortHeaderContainer}:hover & {
        filter: ${WHITE_TO_PINK};
    }
`;

interface SortHeaderProps {
    align: "left" | "right";
    text: string;
    sortBy: SortBy; // refers to this header's sortBy

    onSort: Function;
    stateSortBy: SortBy; // refers to the page's sort state
    stateSortDirection: SortDirection; // refers to the page's sort state
}

function SortHeader(props: SortHeaderProps) {
    return <SortHeaderContainer onClick={() => props.onSort(props.sortBy)} sortable={true} align={props.align}>
        <SortHeaderText align={props.align} sortable={true}>{props.text}</SortHeaderText>
        <SortHeaderIndicator hidden={props.sortBy != props.stateSortBy} align={props.align}
                             src={STATIC + (props.stateSortDirection == "ascending" ? "ascending.png" : "descending.png")}/>
    </SortHeaderContainer>
}

interface NonSortHeaderProps {
    align: "left" | "right";
    text: string;
}

function NonSortHeader(props: NonSortHeaderProps) {
    return <SortHeaderContainer sortable={false} align={props.align}>
        <NonSortHeaderText align={props.align} sortable={false}>{props.text}</NonSortHeaderText>
    </SortHeaderContainer>
}

interface TrackSortHeadersContainerProps {
    gridRow: number;
}

const TrackSortHeadersContainer = styled(SortHeadersContainer)<TrackSortHeadersContainerProps>`
    grid-row: ${props => props.gridRow};
    padding: 0 10px 0 10px;
    grid-template-columns: 30px 20px 370px 370px 80px 110px 110px 40px; // track
`;

interface TrackSortHeadersProps {
    gridRow: number;
    onSort: (newSortBy: SortTrackBy) => void;
    stateSortBy: SortTrackBy; // refers to the page's sort state
    stateSortDirection: SortDirection; // refers to the page's sort state
}

export function TrackSortHeaders(props: TrackSortHeadersProps) {
    return <TrackSortHeadersContainer gridRow={props.gridRow}>
        <NonSortHeader align="right" text="#"/>
        <div/>
        <SortHeader align="left" text="NAME" sortBy="name"
                    onSort={props.onSort} stateSortBy={props.stateSortBy}
                    stateSortDirection={props.stateSortDirection}/>
        <SortHeader align="left" text="ARTISTS" sortBy="artists"
                    onSort={props.onSort} stateSortBy={props.stateSortBy}
                    stateSortDirection={props.stateSortDirection}/>
        <SortHeader align="left" text="DATE" sortBy="releaseDate"
                    onSort={props.onSort} stateSortBy={props.stateSortBy}
                    stateSortDirection={props.stateSortDirection}/>
        <SortHeader align="right" text="DURATION" sortBy="duration"
                    onSort={props.onSort} stateSortBy={props.stateSortBy}
                    stateSortDirection={props.stateSortDirection}/>
        <NonSortHeader align="right" text="PLATFORMS"/>
    </TrackSortHeadersContainer>
}

const ExternalTrackSortHeadersContainer = styled(SortHeadersContainer)<TrackSortHeadersContainerProps>`
    grid-row: ${props => props.gridRow};
    padding: 0 10px 0 10px;
    grid-template-columns: 30px 20px 760px 170px 110px 40px; // external track
`;

interface ExternalTrackSortHeadersProps {
    gridRow: number;
    onSort: (newSortBy: SortExternalTrackBy) => void;
    stateSortBy: SortExternalTrackBy; // refers to the page's sort state
    stateSortDirection: SortDirection; // refers to the page's sort state
}

export function ExternalTrackSortHeaders(props: ExternalTrackSortHeadersProps) {
    return <ExternalTrackSortHeadersContainer gridRow={props.gridRow}>
        <NonSortHeader align="right" text="#"/>
        <div/>
        <SortHeader align="left" text="NAME" sortBy="name"
                    onSort={props.onSort} stateSortBy={props.stateSortBy}
                    stateSortDirection={props.stateSortDirection}/>
        <SortHeader align="left" text="DATE" sortBy="releaseDate"
                    onSort={props.onSort} stateSortBy={props.stateSortBy}
                    stateSortDirection={props.stateSortDirection}/>
        <SortHeader align="right" text="PLATFORM" sortBy="platform"
                    onSort={props.onSort} stateSortBy={props.stateSortBy}
                    stateSortDirection={props.stateSortDirection}/>
    </ExternalTrackSortHeadersContainer>
}

const PlaylistSortHeadersContainer = styled(SortHeadersContainer)<TrackSortHeadersContainerProps>`
    grid-row: ${props => props.gridRow};
    padding: 0 10px 0 10px;
    grid-template-columns: 30px 20px 760px 170px 110px 40px; // playlist
`;

interface PlaylistSortHeadersProps {
    gridRow: number;
    onSort: (newSortBy: SortPlaylistBy) => void;
    stateSortBy: SortPlaylistBy; // refers to the page's sort state
    stateSortDirection: SortDirection; // refers to the page's sort state
}

export function PlaylistSortHeaders(props: PlaylistSortHeadersProps) {
    return <PlaylistSortHeadersContainer gridRow={props.gridRow}>
        <NonSortHeader align="right" text="#"/>
        <div/>
        <SortHeader align="left" text="NAME" sortBy="name"
                    onSort={props.onSort} stateSortBy={props.stateSortBy}
                    stateSortDirection={props.stateSortDirection}/>
        <SortHeader align="left" text="CREATION DATE" sortBy="creationDate"
                    onSort={props.onSort} stateSortBy={props.stateSortBy}
                    stateSortDirection={props.stateSortDirection}/>
        <SortHeader align="right" text="LENGTH" sortBy="length"
                    onSort={props.onSort} stateSortBy={props.stateSortBy}
                    stateSortDirection={props.stateSortDirection}/>
    </PlaylistSortHeadersContainer>
}