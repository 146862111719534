import {Platform} from "../../types";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {APIException, get_external_playlists, import_personal_playlist, is_admin} from "../../api";
import toast from "react-hot-toast";
import {ExternalPlaylistCard} from "../cards/ExternalCard";
import {Overlay, OverlayContentContainer} from "../reusable/styled/Overlay";
import {onOverlayClick} from "../../util/functions";
import {OverlayTitle} from "../reusable/styled/Title";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {getImportPersonalPlaylistOverlayVisibility, setImportPersonalPlaylistOverlayVisibility} from "../../redux-store/overlaySlice";

const ImportPersonalPlaylistOverlayContentContainer = styled(OverlayContentContainer)`
    // TODO grid template for filters by platform, maybe search bar but probably not
    width: 1200px;
`;

const ImportPersonalPlaylistResults = styled.div`
    width: 100%;
    grid-row: 3;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
`;

export function ImportPersonalPlaylistOverlay() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);
    const [playlists, setPlaylists] = useState<React.JSX.Element[]>([]);
    const visible = useSelector(getImportPersonalPlaylistOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setImportPersonalPlaylistOverlayVisibility(newVisibility));

    useEffect(() => {
        (async() => {
            try {
                setIsAdmin(await is_admin());
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, []);

    const importPlaylist = async (external_id: string, platform: Platform) => {
        try {
            const newPlaylistId = await import_personal_playlist(external_id, platform);
            navigate("/playlist/" + newPlaylistId);
            setVisibility(false);
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    useEffect(() => {
        if (!visible) return;
        (async () => {
            try {
                let actualPlaylists: React.JSX.Element[] = [];
                const results = await get_external_playlists();
                for (const playlist of results) {
                    actualPlaylists.push(
                        <ExternalPlaylistCard
                            isAdmin={isAdmin}
                            onClickMain={async () => await importPlaylist(playlist.external_id, playlist.platform)}
                            externalPlaylist={playlist} key={playlist.external_id + playlist.platform}/>
                    )
                }
                setPlaylists(actualPlaylists);
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, [visible]);

    const overlayId = "importPersonalPlaylistOverlay";
    return <Overlay id={overlayId + "Bg"} visible={visible} setVisibility={setVisibility} onMouseDown={(e) => onOverlayClick(e, overlayId, setVisibility)}>
        <ImportPersonalPlaylistOverlayContentContainer id={overlayId}>
            <OverlayTitle>Import Personal Playlist</OverlayTitle>
            <ImportPersonalPlaylistResults>{playlists}</ImportPersonalPlaylistResults>
        </ImportPersonalPlaylistOverlayContentContainer>
    </Overlay>
}