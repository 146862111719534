import styled from "styled-components";
import {FOREGROUND, PASTEL_PINK, PASTEL_PURPLE, REGULAR_FONT_SIZE, SELECTION} from "../reusable/styled/StyledComponents";
import {OverlayProps} from "../../types";
import React, {useEffect, useState} from "react";
import {onOverlayClick} from "../../util/functions";
import {STATIC} from "../../constants";
import {pick, startCase} from "lodash";
import {Overlay, OverlayContentContainer} from "../reusable/styled/Overlay";
import {Input} from "../reusable/styled/Input";
import {OverlayTitle} from "../reusable/styled/Title";
import {getAddArtistOverlayRefresh, getAddArtistOverlayTrackId, getAddArtistOverlayVisibility, getAddExternalOverlayRefresh, getAddExternalOverlayTrackId, getAddExternalOverlayVisibility, getAddURLTrackOverlayPlaylistId, getAddURLTrackOverlayRefresh, getAddURLTrackOverlayVisibility, getEditArtistOverlayArtist, getEditArtistOverlayRefresh, getEditArtistOverlayVisibility, getEditTrackOverlayRefresh, getEditTrackOverlayTrack, getEditTrackOverlayVisibility, getImportPlaylistHastebinOverlayVisibility, getImportPlaylistURLOverlayVisibility, getMergeOverlayId, getMergeOverlayRefresh, getMergeOverlayType, getMergeOverlayVisibility, setAddArtistOverlayVisibility, setAddExternalOverlayVisibility, setAddURLTrackOverlayVisibility, setEditArtistOverlayVisibility, setEditTrackOverlayVisibility, setImportPlaylistHastebinOverlayVisibility, setImportPlaylistURLOverlayVisibility, setMergeOverlayVisibility} from "../../redux-store/overlaySlice";
import {useDispatch, useSelector} from "react-redux";
import {add_external, add_track_artist, add_url_track_playlist, APIException, edit_artist, edit_track, import_hastebin_playlist, import_url_playlist, merge_matched_artists, merge_matched_tracks} from "../../api";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

const UrlOverlayContentContainer = styled(OverlayContentContainer)`
    width: 1200px;
`;

const UrlForm = styled.form`
    background-color: ${SELECTION};
    border-radius: 5px;
    
    width: 100%;
    height: 45px;
    grid-row: 3;
    
    padding-right: 10px;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr auto;
`;

const UrlInput = styled(Input)`
    font-size: ${REGULAR_FONT_SIZE};
`;

const UrlButton = styled.img`
    grid-column: 2;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        transform: scale(1.1);
    }
`;

interface TextInputOverlayProps extends OverlayProps {
    overlayId: string;
    title: string;
    placeholder: string;
    default?: string;
    onSubmit: (url: string) => void;
}

export function TextInputOverlay(props: TextInputOverlayProps) {
    const defaultInput = props.default ? props.default : "";
    const [input, setInput] = useState(defaultInput);

    useEffect(() => {
        if (props.visible) setInput(defaultInput);
    }, [props.visible]);

    const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setInput((e.target as HTMLInputElement).value);
    };

    const onSubmit = async(e: React.SyntheticEvent<HTMLElement>) => {
        e.preventDefault();
        await props.onSubmit(input);
    };

    return <Overlay id={props.overlayId + "Bg"} visible={props.visible} setVisibility={props.setVisibility} onMouseDown={(e) => onOverlayClick(e, props.overlayId, props.setVisibility)}>
        <UrlOverlayContentContainer id={props.overlayId}>
            <OverlayTitle>{props.title}</OverlayTitle>
            <UrlForm onSubmit={onSubmit}>
                <UrlInput autoComplete="false" onChange={onInputChange}
                          value={input} placeholder={props.placeholder}/>
                <UrlButton onClick={onSubmit} src={STATIC + "enter.png"}/>
            </UrlForm>
        </UrlOverlayContentContainer>
    </Overlay>
}

export function MergeOverlay() {
    const dispatch = useDispatch();
    const refreshState = useSelector(getMergeOverlayRefresh);
    const visible = useSelector(getMergeOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setMergeOverlayVisibility(newVisibility));
    const destId = useSelector(getMergeOverlayId);
    const type = useSelector(getMergeOverlayType);

    const onSubmit = async(srcId: string) => {
        try {
            if (type == "track") {
                await merge_matched_tracks(Number(srcId), destId!);
            } else if (type == "artist") {
                await merge_matched_artists(Number(srcId), destId!);
            }
            setVisibility(false);
            refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <TextInputOverlay
        visible={visible}
        setVisibility={setVisibility}
        title={"Merge Matched " + startCase(type)}
        placeholder={"Enter " + type + " ID here..."}
        onSubmit={onSubmit}
        overlayId="mergeOverlay"
    />
}

export function EditTrackOverlay() {
    const dispatch = useDispatch();
    const refreshState = useSelector(getEditTrackOverlayRefresh);
    const visible = useSelector(getEditTrackOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setEditTrackOverlayVisibility(newVisibility));
    const track = useSelector(getEditTrackOverlayTrack);

    const onSubmit = async(name: string) => {
        try {
            await edit_track(track.track_id!, name);
            setVisibility(false);
            refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <TextInputOverlay
        visible={visible}
        setVisibility={setVisibility}
        title="Edit Track Name"
        placeholder="Enter track name here..."
        default={track.name}
        onSubmit={onSubmit}
        overlayId="editTrackOverlay"
    />
}

export function EditArtistOverlay() {
    const dispatch = useDispatch();
    const refreshState = useSelector(getEditArtistOverlayRefresh);
    const visible = useSelector(getEditArtistOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setEditArtistOverlayVisibility(newVisibility));
    const artist = useSelector(getEditArtistOverlayArtist);

    const onSubmit = async(name: string) => {
        try {
            await edit_artist(artist.artist_id!, name);
            setVisibility(false);
            refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <TextInputOverlay
        visible={visible}
        setVisibility={setVisibility}
        title="Edit Artist Name"
        placeholder="Enter artist name here..."
        default={artist.name}
        onSubmit={onSubmit}
        overlayId="editArtistOverlay"
    />
}

export function AddArtistOverlay() {
    const dispatch = useDispatch();
    const refreshState = useSelector(getAddArtistOverlayRefresh);
    const visible = useSelector(getAddArtistOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setAddArtistOverlayVisibility(newVisibility));
    const trackId = useSelector(getAddArtistOverlayTrackId);

    const onSubmit = async(artistId: string) => {
        try {
            await add_track_artist(trackId, Number(artistId));
            setVisibility(false);
            refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <TextInputOverlay
        visible={visible}
        setVisibility={setVisibility}
        title="Add Artist to Track"
        placeholder="Enter artist ID here..."
        default=""
        onSubmit={onSubmit}
        overlayId="addArtistOverlay"
    />
}

export function AddExternalOverlay() {
    const dispatch = useDispatch();
    const refreshState = useSelector(getAddExternalOverlayRefresh);
    const visible = useSelector(getAddExternalOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setAddExternalOverlayVisibility(newVisibility));
    const trackId = useSelector(getAddExternalOverlayTrackId);

    const onSubmit = async(url: string) => {
        try {
            await add_external(trackId!, url);
            setVisibility(false);
            refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <TextInputOverlay
        visible={visible}
        setVisibility={setVisibility}
        title="Add External"
        placeholder="Enter URL here..."
        onSubmit={onSubmit}
        overlayId="addExternalOverlay"
    />
}

export function AddURLTrackOverlay() {
    const dispatch = useDispatch();
    const refreshState = useSelector(getAddURLTrackOverlayRefresh);
    const visible = useSelector(getAddURLTrackOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setAddURLTrackOverlayVisibility(newVisibility));
    const playlistId = useSelector(getAddURLTrackOverlayPlaylistId);

    const onSubmit = async(url: string) => {
        try {
            await add_url_track_playlist(playlistId!, url);
            setVisibility(false);
            refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <TextInputOverlay
        visible={visible}
        setVisibility={setVisibility}
        title="Add Track By URL"
        placeholder="Enter URL here..."
        onSubmit={onSubmit}
        overlayId="addURLTrackOverlay"
    />
}

export function ImportPlaylistURLOverlay() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const visible = useSelector(getImportPlaylistURLOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setImportPlaylistURLOverlayVisibility(newVisibility));

    const onSubmit = async(url: string) => {
        try {
            const newPlaylistId = await import_url_playlist(url);
            setVisibility(false);
            navigate("/playlist/" + newPlaylistId);
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <TextInputOverlay
        visible={visible}
        setVisibility={setVisibility}
        title="Import Playlist from URL"
        placeholder="Enter URL here..."
        onSubmit={onSubmit}
        overlayId="importPlaylistURLOverlay"
    />
}

export function ImportPlaylistHastebinOverlay() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const visible = useSelector(getImportPlaylistHastebinOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setImportPlaylistHastebinOverlayVisibility(newVisibility));

    const onSubmit = async(url: string) => {
        try {
            const newPlaylistId = await import_hastebin_playlist(url);
            setVisibility(false);
            navigate("/playlist/" + newPlaylistId);
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <TextInputOverlay
        visible={visible}
        setVisibility={setVisibility}
        title="Import Playlist from Hastebin"
        placeholder="Enter URL here..."
        onSubmit={onSubmit}
        overlayId="importPlaylistHastebinOverlay"
    />
}

const ClientIdOverlayContentContainer = styled(OverlayContentContainer)`
    width: 1200px;
    grid-template-rows: auto 25px auto 15px 1fr;
`;

const ClientIdInstructions = styled.p`
    color: ${FOREGROUND};
    font-size: ${REGULAR_FONT_SIZE};
    width: 100%;
    grid-row: 3;
`;

const ClientIdLink = styled.span`
    color: ${PASTEL_PURPLE};
    font-size: ${REGULAR_FONT_SIZE};
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        color: ${PASTEL_PINK};
    }
`;

const ClientIdForm = styled(UrlForm)`
    grid-row: 5;
`;

export function ClientIdOverlay(props: TextInputOverlayProps) {
    const [input, setInput] = useState("");

    const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setInput((e.target as HTMLInputElement).value);
    };

    const onSubmit = async(e: React.SyntheticEvent<HTMLElement>) => {
        e.preventDefault();
        await props.onSubmit(input);
    };

    const overlayId = "clientIdOverlay";
    return <Overlay id={overlayId + "Bg"} {...pick(props, ["visible", "setVisibility"])}
                    onMouseDown={(e) => onOverlayClick(e, overlayId, props.setVisibility)}>
        <ClientIdOverlayContentContainer id={overlayId}>
            <OverlayTitle>{props.title}</OverlayTitle>
            <ClientIdInstructions>
                {"1. Navigate to "}
                <ClientIdLink onClick={() => window.open("https://soundcloud.com")}>
                    SoundCloud
                </ClientIdLink> and log in.<br/>
                2. Press F12, go to the Networking tab, and search for "client_id".<br/>
                3. Your client ID will appear as client_id="value". Paste this value here.<br/>
                4. You may need to refresh this value when it expires. Simply repeat the above steps.
            </ClientIdInstructions>
            <ClientIdForm onSubmit={onSubmit}>
                <UrlInput autoComplete="false" onInput={onInputChange} placeholder="Enter your client ID here..."/>
                <UrlButton onClick={onSubmit} src={STATIC + "enter.png"}/>
            </ClientIdForm>
        </ClientIdOverlayContentContainer>
    </Overlay>
}