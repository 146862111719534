import React, {useEffect, useState} from "react";
import {getStorageKey} from "../redux-store/userSlice";
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {ConnectionsTabContent} from "../components/settings/ConnectionsTabContent";
import {setWindowProperties} from "../util/format";
import {startCase} from "lodash";
import {BodyContainer, ContentContainer} from "../components/reusable/styled/Container";
import {Tab} from "../components/reusable/styled/Tab";
import {Title} from "../components/reusable/styled/Title";

const SettingsContentContainer = styled(ContentContainer)`
    grid-template-columns: auto 15px auto 15px auto 1fr;
    grid-template-rows: repeat(2, auto 15px) 1fr;
`;

const LibraryTab = styled(Tab)`
    grid-row: 3;
`;

const ProfileTab = styled(LibraryTab)`
    grid-column: 1;
`;

const ConnectionsTab = styled(LibraryTab)`
    grid-column: 3;
`;

const PreferencesTab = styled(LibraryTab)`
    grid-column: 5;
`;

export function Settings() {
    const [selected, setSelected] = useState("profile");

    if (!useSelector(getStorageKey)) {
        return <Navigate to="/"/>
    }

    useEffect(() => {
        setWindowProperties(startCase(selected));
    });

    return <BodyContainer>
        <SettingsContentContainer>
            <Title>Settings</Title>

            <ProfileTab selected={selected == "profile"} onClick={() => setSelected("profile")}>
                Profile
            </ProfileTab>
            <ConnectionsTab selected={selected == "connections"} onClick={() => setSelected("connections")}>
                Connections
            </ConnectionsTab>
            <PreferencesTab selected={selected == "preferences"} onClick={() => setSelected("preferences")}>
                Preferences
            </PreferencesTab>

            <ConnectionsTabContent visible={selected == "connections"}/>
        </SettingsContentContainer>
    </BodyContainer>
}
