import React from "react";
import {CreatePlaylistOverlay, ModifyPlaylistOverlay} from "./PlaylistDetailsOverlay";
import {MergePlaylistOverlay} from "./MergePlaylistOverlay";
import {AddArtistOverlay, AddExternalOverlay, AddURLTrackOverlay, EditArtistOverlay, EditTrackOverlay, ImportPlaylistHastebinOverlay, ImportPlaylistURLOverlay, MergeOverlay} from "./TextInputOverlay";
import {AddTrackPlaylistOverlay} from "./AddTrackPlaylistOverlay";
import {TrackExternalsOverlay} from "./ExternalsOverlay";
import {ImportPersonalPlaylistOverlay} from "./ImportPersonalPlaylistOverlay";
import {NewPlaylistOverlay} from "./NewPlaylistOverlay";
import {ImportArtistOverlay} from "./ImportArtistOverlay";


export function MasterOverlay() {
    return <>
        <ModifyPlaylistOverlay key="modify"/>
        <CreatePlaylistOverlay key="create"/>

        <MergePlaylistOverlay key="mergePlaylist"/>

        <MergeOverlay key="merge"/>
        <EditTrackOverlay key="editTrack"/>
        <EditArtistOverlay key="editArtist"/>
        <AddArtistOverlay key="addArtist"/>
        <AddExternalOverlay key="addExternal"/>

        <AddURLTrackOverlay key="addURLTrack"/>
        <ImportPlaylistURLOverlay key="importPlaylistURL"/>
        <ImportPlaylistHastebinOverlay key="importPlaylistHastebin"/>

        <AddTrackPlaylistOverlay key="addTrackPlaylist"/>

        <TrackExternalsOverlay key="trackExternals"/>

        <NewPlaylistOverlay key="newPlaylist"/>
        <ImportPersonalPlaylistOverlay key="importPersonalPlaylist"/>

        <ImportArtistOverlay key="importArtist"/>
    </>
}