import styled, {css} from "styled-components";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away-subtle.css";
import React from "react";
import {omit} from "lodash";
import {Platform} from "../../../types";
import {Link} from "react-router-dom";

export const BACKGROUND = "#282a36";
// export const BACKGROUND2 = "#383a59";
export const PASSIVE = "#383a50"; // blue value in between the one above and below
// export const PASSIVE = "#373944";
export const SELECTION = "#44475a";
export const FOREGROUND = "#f8f8f2";

export const PASTEL_PURPLE = "#bd93f9";
export const PASTEL_PINK = "#ff79c6";
export const PASTEL_RED = "#ff5555";
export const PASTEL_ORANGE = "#ffb86c";
export const PASTEL_YELLOW = "#f1fa8c";
export const PASTEL_GREEN = "#50fa7b";
export const PASTEL_CYAN = "#8be9fd";
export const PASTEL_NAVY = "#6272a4";

export function PLATFORM_COLOUR(platform: Platform) {
    return {
        soundcloud: PASTEL_ORANGE,
        spotify: PASTEL_GREEN,
        youtube: PASTEL_RED,
    }[platform];
}

// https://codepen.io/sosuke/pen/Pjoqqp invert(100%) first to get to black
export const WHITE_TO_PURPLE = "invert(100%) invert(65%) sepia(12%) saturate(1711%) hue-rotate(220deg) brightness(97%) contrast(102%)";
export const WHITE_TO_PINK = "invert(100%) invert(79%) sepia(18%) saturate(7375%) hue-rotate(288deg) brightness(101%) contrast(102%)";
export const WHITE_TO_RED = "invert(100%) invert(74%) sepia(97%) saturate(5505%) hue-rotate(330deg) brightness(106%) contrast(102%)";
export const WHITE_TO_ORANGE = "invert(100%) invert(76%) sepia(33%) saturate(838%) hue-rotate(329deg) brightness(103%) contrast(104%)";
export const WHITE_TO_GREEN = "invert(100%) invert(82%) sepia(22%) saturate(1271%) hue-rotate(72deg) brightness(104%) contrast(96%)";
export const WHITE_TO_NAVY = "invert(100%) invert(41%) sepia(50%) saturate(398%) hue-rotate(188deg) brightness(95%) contrast(85%)";
export const WHITE_TO_BACKGROUND = "invert(100%) invert(15%) sepia(29%) saturate(350%) hue-rotate(194deg) brightness(97%) contrast(98%)";
export const WHITE_TO_GRAY = "invert(100%) invert(54%) sepia(0%) saturate(79%) hue-rotate(171deg) brightness(92%) contrast(89%)";

export function WHITE_TO_PLATFORM(platform: Platform) {
    return {
        soundcloud: WHITE_TO_ORANGE,
        spotify: WHITE_TO_GREEN,
        youtube: WHITE_TO_RED,
    }[platform];
}

// only use these if they are a title, header etc. or it defeats the purpose of being able to edit all at once
export const TITLE_FONT_SIZE = "64px";
export const MIDTITLE_FONT_SIZE = "56px";
export const SUBTITLE_FONT_SIZE = "32px";
export const HEADER_FONT_SIZE = "16px"; // top headers
export const LARGE_FONT_SIZE = "16px";
export const REGULAR_FONT_SIZE = "15px";
export const SMALL_FONT_SIZE = "13px";

export const SLIDER_STYLE = (percent: number, direction: string = "to right") => {
    return `linear-gradient(${direction}, ${PASTEL_PURPLE} 0%, ${PASTEL_PURPLE} ${percent}%, ${BACKGROUND} ${percent}%, ${BACKGROUND} 100%)`;
};

export const UnselectableText = styled.p`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
`;

export const LargeCardWithButtons = styled.div`
    border-radius: 5px;
    width: 100%;
    height: 100px;
    padding: 15px 15px 15px 15px;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: 30% 30% 40%;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        background-color: ${SELECTION};
    }
`;

export const LargeCardWithoutButtons = styled(LargeCardWithButtons)`
    grid-template-rows: 40% 30% 30%;
`;

// TODO migrate to below <Link><Image/></Link< and rename the below stuff
export const LargeCardImage = styled.img`
    width: 70px;
    height: 70px;
    cursor: pointer;
    
    grid-column: 1;
    grid-row: 1 / span 9999;
    box-shadow: 0 0 10px 5px #00000040;
`;

export const LargeCardImageLink = styled(Link)`
    width: 70px;
    height: 70px;
    grid-column: 1;
    grid-row: 1 / span 9999;
    box-shadow: 0 0 10px 5px #00000040;
    display: grid;
`;

export const LargeCardImageLinkImage = styled.img`
    width: 70px;
    height: 70px;
`;

export const LargeCardText = styled.p`
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${FOREGROUND};
    text-align: left;
    transition: all 0.1s ease-in-out;
`;

export const LargeCardButtonContainer = styled.div`
    width: 100%;
    height: 90%;
    display: grid;
    grid-gap: 5px;
    align-items: center;
    grid-auto-flow: column;
`;

export const LargeCardButton = styled.button`
    border-radius: 5px;
    border: 1px solid ${FOREGROUND};
    width: 100%;
    height: 100%;
    padding: 0 5px 0 5px;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: auto 5px;
    transition: all 0.1s ease-in-out;

    &:hover {
        border: 1px solid transparent;
        background-color: ${PASTEL_PURPLE};
    }
`;

export const LargeCardButtonImage = styled.img`
    width: 15px;
    height: 15px;
    grid-column: 1;
`;

export const LargeCardButtonText = styled.p`
    color: ${FOREGROUND};
    font-size: ${SMALL_FONT_SIZE};
    line-height: normal;
    grid-column: 3;
`;

export const SmallCard = styled.div`
    border-radius: 5px;
    width: 100%;
    height: 60px;
    padding: 10px 10px 10px 10px;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: column;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        background-color: ${SELECTION};
    }
`;

export const SmallCardImage = styled.img`
    width: 40px;
    height: 40px;
    box-shadow: 0 0 10px 5px #00000040;
`;

const SmallCardText = styled.p`
    width: min-content;
    max-width: 100%;
    font-size: ${SMALL_FONT_SIZE};
    color: ${FOREGROUND};
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SmallCardLeftText = styled(SmallCardText)`
    text-align: left;
    justify-self: left;
`;

export const SmallCardRightText = styled(SmallCardText)`
    text-align: right;
    justify-self: right;
`;

interface TooltipProps {
    backgroundColour?: string;
    arrowColour?: string;
    placement?: string;
}

const StyledTippy = styled(Tippy)<TooltipProps>`
    background: ${props => props.backgroundColour ? props.backgroundColour : PASTEL_NAVY};
    text-align: center;

    ${props => props.zIndex && css`
        z-index: ${props.zIndex};
    `}

    .tippy-arrow {
        color: ${props => props.arrowColour ? props.arrowColour : PASTEL_NAVY};
        ${props => props.placement == "top-start" && css`
            // transform: translateX(calc(-100% + 38px)) !important;
        `}
    }
`;

// TODO max width according to width of playback panel according to screen
export function Tooltip(props: any) {
    return props.hideOnClick == undefined ?
        <StyledTippy animation="shift-away-subtle" maxWidth="1100px"
                     {...omit(props, ["children"])}>
            {props.children}
        </StyledTippy> :
        <StyledTippy animation="shift-away-subtle" hideOnClick={props.hideOnClick} maxWidth="1100px"
                     {...omit(props, ["children"])}>
            {props.children}
        </StyledTippy>;
}

interface DimProps {
    dim: number;
}

export const DimImage = styled.img<DimProps>`
    width: ${props => props.dim}px;
    height: ${props => props.dim}px;
`;

export const DropdownButton = styled.button`
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: grid;
`;

export const DropdownButtonImage = styled.img`
    width: 50px;
    height: 50px;
    transition: all 0.1s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

export const SmallDropdownButton = styled(DropdownButton)`
    width: 20px;
    height: 20px;
`;

export const SmallDropdownButtonImage = styled(DropdownButtonImage)`
    width: 20px;
    height: 20px;
    
    &:hover {
        transform: scale(1.2);
    }
`;