import styled from "styled-components";
import {BACKGROUND, PASTEL_NAVY} from "./StyledComponents";

export const BodyContainer = styled.div`
    background: linear-gradient(to bottom, ${BACKGROUND}, ${PASTEL_NAVY}20);
    background-color: ${BACKGROUND};
    width: 100%;
    position: fixed;
    top: 70px;
    bottom: 70px;
    overflow-y: scroll;
`;

export const ContentContainer = styled.div`
    position: relative;
    margin: auto;
    width: 1200px;
    padding: 25px 25px 25px 25px;
    display: grid;
    justify-items: center;
    align-items: center;
`;

export const AuthContentContainer = styled(ContentContainer)`
    height: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto 2fr auto 3fr;
`;