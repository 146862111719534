import React, {useContext} from "react";
import styled, {css} from "styled-components";
import {CanPlayProps, ContinuousPlaybackSource, Platform, TrackBase, TrackExternal, TrackUtils} from "../../../types";
import {BACKGROUND, FOREGROUND, HEADER_FONT_SIZE, PLATFORM_COLOUR, SMALL_FONT_SIZE, SmallCardImage, WHITE_TO_GRAY, WHITE_TO_PLATFORM} from "../styled/StyledComponents";
import {getTimeFormat} from "../../../util/format";
import {STATIC} from "../../../constants";
import {globalContext} from "../../../index";
import {useSelector} from "react-redux";
import {getExternalId, getPlatform, getPlaying, getTrack} from "../../../redux-store/playbackSlice";
import {canPlayExternal} from "../../../util/functions";
import {getPlaybackPlatforms} from "../../../redux-store/oAuth2Slice";

const PlaybackExternalSourceContainer = styled.div`
    padding: 10px 10px 10px 10px;
    display: grid;
    justify-items: left;
    align-items: center;
    grid-template-columns: auto 10px 15px 5px auto;
    grid-row-gap: 2px;
`;

const CoverContainer = styled.div`
    width: 40px;
    height: 40px;
    z-index: 10;
    display: grid;
    justify-items: center;
    align-items: center;

    grid-column: 1;
    grid-row: 1 / 3;
`;

const CoverImage = styled(SmallCardImage)`
    object-fit: contain;
    background-color: #00000040;
    grid-column: 1;
    grid-row: 1;
`;

const PlayButtonContainer = styled.div`
    width: 40px;
    height: 40px;
    z-index: 10;
    grid-column: 1;
    grid-row: 1;
    display: grid;
    justify-items: center;
    align-items: center;
    opacity: 0;
    transition: all 0.1s ease-in-out;

    ${PlaybackExternalSourceContainer}:hover & {
        cursor: pointer;
        background-color: ${BACKGROUND}80;
        opacity: 1;
    }
`;

const PlayButton = styled.img`
    width: 20px;
    height: 20px;
`;

const Text = styled.p`
    max-width: 100%;
    color: ${FOREGROUND};
    font-size: ${SMALL_FONT_SIZE};
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface TextProps extends CanPlayProps {
    currentlySelected: boolean;
    platform: Platform;
}

const External = styled.img<TextProps>`
    width: 15px;
    height: 15px;
    align-self: end;
    grid-column: 3;
    grid-row: 1;

    ${props => !props.canPlay && css`
        filter: ${WHITE_TO_GRAY};
    `}
    ${props => props.currentlySelected && css`
        filter: ${WHITE_TO_PLATFORM(props.platform)}
    `}
`;

const Name = styled(Text)<TextProps>`
    font-weight: bold;
    align-self: end;
    grid-column: 5;
    grid-row: 1;

    ${props => !props.canPlay && css`
        color: gray;
    `}
    ${props => props.currentlySelected && css`
        color: ${PLATFORM_COLOUR(props.platform)};
    `}
`;

const Duration = styled(Text)<TextProps>`
    align-self: start;
    grid-column: 3 / 6;
    grid-row: 2;

    ${props => !props.canPlay && css`
        color: gray;
    `}
    ${props => props.currentlySelected && css`
        color: ${PLATFORM_COLOUR(props.platform)};
    `}
`;

interface PlaybackExternalSourceProps {
    external: TrackExternal;
    onPlay: () => void;
    canPlay: boolean;
    currentlyPlaying: boolean;
    currentlySelected: boolean;
}

function PlaybackExternalSource(props: PlaybackExternalSourceProps) {
    return <PlaybackExternalSourceContainer>
        <CoverContainer onClick={props.onPlay}>
            <CoverImage src={props.external.cover}/>
            <PlayButtonContainer hidden={!props.canPlay}>
                <PlayButton src={STATIC + (props.currentlyPlaying ? "pause.png" : "play.png")}/>
            </PlayButtonContainer>
        </CoverContainer>
        <External canPlay={props.canPlay} currentlySelected={props.currentlySelected} platform={props.external.platform} src={STATIC + props.external.platform + ".png"}/>
        <Name canPlay={props.canPlay} currentlySelected={props.currentlySelected} platform={props.external.platform}>{props.external.name}</Name>
        <Duration canPlay={props.canPlay} currentlySelected={props.currentlySelected} platform={props.external.platform}>{getTimeFormat(props.external.duration_ms / 1000)}</Duration>
    </PlaybackExternalSourceContainer>
}

const PlaybackExternalSourcesContainer = styled.div`
    display: grid;
    justify-items: left;
    align-items: center;
    grid-auto-flow: row;
    overflow-x: auto;
    max-height: ${window.innerHeight / 2}px;
`;

export const Title = styled.p`
    padding: 10px 10px 10px 10px;
    line-height: ${HEADER_FONT_SIZE};
    font-size: ${HEADER_FONT_SIZE};
    font-weight: bold;
    color: ${FOREGROUND};
`;

interface PlaybackExternalSourcesProps {
    track: TrackBase;
    continuousPlaybackSource: ContinuousPlaybackSource;
}

export function PlaybackExternalSources(props: PlaybackExternalSourcesProps) {
    const track = props.track;
    const {macroController} = useContext(globalContext);
    const playingTrack = useSelector(getTrack);
    const playing = useSelector(getPlaying);
    const externalId = useSelector(getExternalId);
    const platform = useSelector(getPlatform);
    const validPlatforms = useSelector(getPlaybackPlatforms);

    let sources = [];
    const onPlay = async(external: TrackExternal) => {
        await macroController.macroTogglePlay(props.track, props.continuousPlaybackSource, external);
    };

    for (const external of props.track.externals) {
        const canPlay = canPlayExternal(track, external, validPlatforms);
        const currentlyPlaying = TrackUtils.currentlyPlaying(track, playingTrack, playing,
            external.external_id, externalId, external.platform, platform);
        const currentlySelected = external.external_id == externalId && external.platform == platform;
        sources.push(
            <PlaybackExternalSource external={external} onPlay={() => onPlay(external)}
                                    canPlay={canPlay}
                                    currentlyPlaying={currentlyPlaying}
                                    currentlySelected={currentlySelected}
                                    key={external.external_id + external.platform}/>
        )
    }
    return <PlaybackExternalSourcesContainer>
        <Title>Select playback source</Title>
        {sources}
    </PlaybackExternalSourcesContainer>
}