import styled from "styled-components";
import {OverlayProps} from "../../types";
import React, {useEffect, useState} from "react";
import {FOREGROUND, LARGE_FONT_SIZE, PASTEL_PINK, PASTEL_PURPLE, PASTEL_RED, SELECTION, Tooltip} from "../reusable/styled/StyledComponents";
import {isOverlayClickOff, onOverlayClick} from "../../util/functions";
import {STATIC} from "../../constants";
import {APIException, create_playlist, modify_playlist} from "../../api";
import {Overlay, OverlayContentContainer} from "../reusable/styled/Overlay";
import {Input} from "../reusable/styled/Input";
import {OverlayTitle} from "../reusable/styled/Title";
import {isEqual, pick} from "lodash";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {getCreatePlaylistOverlayRefresh, getCreatePlaylistOverlayVisibility, getModifyPlaylistId, getModifyPlaylistOverlayRefresh, getModifyPlaylistOverlayVisibility, getPlaylistDetails, setCreatePlaylistOverlayVisibility, setModifyPlaylistOverlayVisibility} from "../../redux-store/overlaySlice";

const ModifyPlaylistOverlayContentContainer = styled(OverlayContentContainer)`
    width: 1200px;
    grid-template-columns: auto 15px 1fr 15px auto;
    grid-template-rows: auto 25px repeat(3, auto 15px);
`;

const PlaylistInput = styled(Input)`
    background-color: ${SELECTION};
    font-size: ${LARGE_FONT_SIZE};
    height: 50px;
    
    &:focus,
    &:hover {
        background-color: ${SELECTION};
    }
`;

const Cover = styled.img`
    width: 215px;
    height: 215px;
    
    grid-column: 1;
    grid-row: 3 / 6;
    box-shadow: 0 0 10px 5px #00000040;
`;

const Name = styled(PlaylistInput)`
    grid-column: 3;
    grid-row: 3;
`;

const Public = styled.img`
    width: 35px;
    height: 35px;
    cursor: pointer;
    grid-column: 5;
    grid-row: 3;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        transform: scale(1.1);
    }
`;

const Description = styled.textarea`
    background-color: ${SELECTION};
    width: 100%;
    color: ${PASTEL_PURPLE};
    border: none;
    border-radius: 5px;
    padding: 10px 15px 10px 15px;
    font-size: ${LARGE_FONT_SIZE};
    grid-column: 3 / 6;
    line-height: 25px;
    height: 150px;
    grid-row: 5;
    transition: all 0.2s ease-in-out;
    
    &:focus,
    &:hover {
        color: ${PASTEL_PINK};
    }
    
    &::placeholder {
        color: ${PASTEL_PURPLE + "80"};
    }
    
    &:focus::placeholder,
    &:hover::placeholder {
        color: ${PASTEL_PINK + "80"};
    }
`;

const Url = styled(PlaylistInput)`
    grid-column: 1 / 6;
    grid-row: 7;
`;

const ButtonContainer = styled.div`
    width: 100%;
    grid-column: 3 / 6;
    grid-row: 1;
    
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto 10px auto;
`;

const Button = styled.button`
    background-color: ${SELECTION};
    border-radius: 5px;
    padding: 0 15px 0 15px;
    height: 40px;
    line-height: 40px;

    display: grid;
    justify-items: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
`;

const ButtonText = styled.p`
    color: ${FOREGROUND};
    font-size: ${LARGE_FONT_SIZE};
`;

const SubmitButton = styled(Button)`
    width: min-content;
    grid-column: 2;
    &:hover {
        background-color: ${PASTEL_PURPLE};
    }
`;

const CancelButton = styled(Button)`
    width: min-content;
    grid-column: 4;
    &:hover {
        background-color: ${PASTEL_RED};
    }
`;

export interface PlaylistDetails {
    name: string;
    description: string;
    cover: string;
    public: boolean;
    [key: string]: any;
}

interface PlaylistDetailsOverlayProps extends OverlayProps {
    overlayId: string;
    titleText: string;
    submitName: string;
    onSubmit: (details: PlaylistDetails) => void;
}

function PlaylistDetailsOverlay(props: PlaylistDetailsOverlayProps) {
    const playlistId = useSelector(getModifyPlaylistId);
    const initialDetails = useSelector(getPlaylistDetails);
    const [details, setDetails] = useState<PlaylistDetails>(initialDetails);

    useEffect(() => {
        setDetails(initialDetails)
    }, [playlistId, initialDetails]);

    const onChange = (field: string, v: string | boolean) => {
        setDetails({...details, [field]: v});
    };

    const onClickOff = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isOverlayClickOff(e, props.overlayId)) return;
        if (isEqual(details, initialDetails)) {
            onOverlayClick(e, props.overlayId, props.setVisibility)
        } else {
            toast.error("You have unsaved changes. Please save or discard your changes before proceeding.")
        }
    };

    const onCancel = () => {
        props.setVisibility(false);
    };

    // keys used to force rerender
    return <Overlay id={props.overlayId + "Bg"} {...pick(props, ["visible", "setVisibility"])} onMouseDown={onClickOff}>
        <ModifyPlaylistOverlayContentContainer id={props.overlayId}>
            <OverlayTitle>{props.titleText}</OverlayTitle>
            <Cover src={details.cover}/>
            <Tooltip content="Name" placement="left" zIndex={11500} hideOnClick={false}>
                <Name value={details.name} placeholder="Playlist Name"
                      onChange={(e) => onChange("name", e.target.value)} key="name"/>
            </Tooltip>
            <Tooltip content="Public / Private" placement="top" zIndex={11500} hideOnClick={false}>
                <Public src={STATIC + (details.public ? "public.png" : "private.png")}
                        onClick={() => onChange("public", !details.public)} key="public"/>
            </Tooltip>
            <Tooltip content="Description" placement="left" zIndex={11500} hideOnClick={false}>
                <Description value={details.description} rows={3} placeholder="Playlist Description"
                             onChange={(e) => onChange("description", e.target.value)}
                             key="description"/>
            </Tooltip>
            <Tooltip content="Cover URL" placement="left" zIndex={11500} hideOnClick={false}>
                <Url value={details.cover} placeholder="Playlist Cover URL"
                     onChange={(e) => onChange("cover", e.target.value)} key="cover"/>
            </Tooltip>

            <ButtonContainer>
                <SubmitButton onClick={() => props.onSubmit(details)}>
                    <ButtonText>{props.submitName}</ButtonText>
                </SubmitButton>
                <CancelButton onClick={onCancel}>
                    <ButtonText>Cancel</ButtonText>
                </CancelButton>
            </ButtonContainer>
        </ModifyPlaylistOverlayContentContainer>
    </Overlay>
}

export function ModifyPlaylistOverlay() {
    const dispatch = useDispatch();
    const playlistId = useSelector(getModifyPlaylistId);
    const refreshState = useSelector(getModifyPlaylistOverlayRefresh);
    const visible = useSelector(getModifyPlaylistOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setModifyPlaylistOverlayVisibility(newVisibility));

    const onSave = async(details: PlaylistDetails) => {
        try {
            await modify_playlist(playlistId!, details.name, details.description, details.cover, details.public);
            setVisibility(false);
            refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };
    return <PlaylistDetailsOverlay
        visible={visible}
        setVisibility={setVisibility}
        titleText="Modify Playlist"
        submitName="Save"
        onSubmit={onSave}
        overlayId="modifyPlaylistOverlay"
    />
}

export function CreatePlaylistOverlay() {
    const dispatch = useDispatch();
    const refreshState = useSelector(getCreatePlaylistOverlayRefresh);
    const visible = useSelector(getCreatePlaylistOverlayVisibility);
    const setVisibility = (newVisibility: boolean) => dispatch(setCreatePlaylistOverlayVisibility(newVisibility));

    const onCreate = async(details: PlaylistDetails) => {
        try {
            const playlist_id = await create_playlist(details.name, details.description, new Date(), details.cover, details.public);
            setVisibility(false);
            refreshState(playlist_id);
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };
    return <PlaylistDetailsOverlay
        visible={visible}
        setVisibility={setVisibility}
        titleText="Create New Playlist"
        submitName="Create"
        onSubmit={onCreate}
        overlayId="createPlaylistOverlay"
    />
}