import {useDispatch, useSelector} from "react-redux";
import {clearSpotifyToken, clearYoutubeToken, getSpotifyToken, getYoutubeToken, setAllTokens} from "../../redux-store/oAuth2Slice";
import {FRONTEND_URL, SPOTIFY_CLIENT_ID, STATIC, YOUTUBE_CLIENT_ID} from "../../constants";
import {APIException, get_tokens, spotify_remove_token, youtube_remove_token} from "../../api";
import React, {useEffect} from "react";
import styled, {css} from "styled-components";
import {PASTEL_GREEN, PASTEL_RED} from "../reusable/styled/StyledComponents";
import {VisibleProps} from "../../types";
import {BannerButtonImage, BannerButtonText, WideBannerButton} from "../reusable/styled/BannerButton";
import {TabContentContainer} from "../reusable/styled/Tab";
import toast from "react-hot-toast";

const SettingsContainer = styled(TabContentContainer)`
    grid-auto-flow: row;
`;

interface ConnectionProps {
    connected: boolean;
}

/*const Soundcloud = styled(WideBannerButton)<ConnectionProps>`
    ${props => props.connected && css`
        background-color: ${PASTEL_ORANGE}C0;
    `}
    
    &:hover {
        background-color: ${PASTEL_ORANGE};
    }
`;*/

const Spotify = styled(WideBannerButton)<ConnectionProps>`
    ${props => props.connected && css`
        background-color: ${PASTEL_GREEN}C0;
    `}
    
    &:hover {
        background-color: ${PASTEL_GREEN};
    }
`;

const Youtube = styled(WideBannerButton)<ConnectionProps>`
    ${props => props.connected && css`
        background-color: ${PASTEL_RED}C0;
    `}
    
    &:hover {
        background-color: ${PASTEL_RED};
    }
`;

export function ConnectionsTabContent(props: VisibleProps) {
    // const [overlayVisible, setOverlayVisibility] = useState(false);
    // const soundcloudToken = useSelector(getSoundcloudToken);
    const spotifyToken = useSelector(getSpotifyToken);
    const youtubeToken = useSelector(getYoutubeToken);
    const dispatch = useDispatch();

    // connect
    // TODO soundcloud removed until oauth2
    /*const redirectToSoundcloud = async(clientId: string) => {
        try {
            await soundcloud_callback(clientId);
            dispatch(setAllTokens(await get_tokens()));
            setOverlayVisibility(false);
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
        /!*const params = new URLSearchParams({
            response_type: "code",
            client_id: // code would go here,
            redirect_uri: FRONTEND_URL + "soundcloud",
        });
        window.location.assign("https://api-v2.soundcloud.com/connect?" + params.toString());*!/
    };*/
    const redirectToSpotify = () => {
        const params = new URLSearchParams({
            response_type: "code",
            client_id: SPOTIFY_CLIENT_ID,
            scope: "playlist-read-collaborative playlist-read-private user-follow-read streaming user-read-email " +
                "user-modify-playback-state user-read-playback-state user-read-currently-playing user-read-private",
            redirect_uri: FRONTEND_URL + "spotify",
            state: JSON.stringify({}),
        });
        window.location.assign("https://accounts.spotify.com/authorize?" + params.toString());
    };
    const redirectToYoutube = () => {
        const params = new URLSearchParams({
            response_type: "code",
            client_id: YOUTUBE_CLIENT_ID,
            scope: "https://www.googleapis.com/auth/youtube.readonly",
            include_granted_scopes: "true",
            redirect_uri: FRONTEND_URL + "youtube",
            access_type: "offline",
        });
        window.location.assign("https://accounts.google.com/o/oauth2/auth?" + params.toString());
    };

    // disconnect
    /*const disconnectFromSoundcloud = async() => {
        try {
            await soundcloud_remove_token();
            dispatch(clearSoundcloudToken());
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };*/
    const disconnectFromSpotify = async() => {
        try {
            await spotify_remove_token();
            dispatch(clearSpotifyToken());
            window.open("https://www.spotify.com/account/apps/");
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    const disconnectFromYoutube = async() => {
        try {
            await youtube_remove_token();
            dispatch(clearYoutubeToken());
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    useEffect(() => {
        (async() => {
            try {
                dispatch(setAllTokens(await get_tokens()));
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, []);

    return <SettingsContainer {...props}>
        {/*<ClientIdOverlay title="Connect to SoundCloud" onInputSubmit={redirectToSoundcloud}
                         visible={overlayVisible} setVisibility={setOverlayVisibility}/>*/}

        {/*<Soundcloud connected={soundcloudToken != ""}
                    onClick={soundcloudToken == "" ? (() => setOverlayVisibility(true)) : disconnectFromSoundcloud}>
            <BannerButtonImage src={STATIC + "soundcloud.png"}/>
            <BannerButtonText>{(soundcloudToken == "" ? "Connect to" : "Disconnect from") + " SoundCloud"}</BannerButtonText>
        </Soundcloud>*/}

        <Spotify connected={spotifyToken != ""} onClick={spotifyToken == "" ? redirectToSpotify : disconnectFromSpotify}>
            <BannerButtonImage src={STATIC + "spotify.png"}/>
            <BannerButtonText>{(spotifyToken == "" ? "Connect to" : "Disconnect from") + " Spotify"}</BannerButtonText>
        </Spotify>

        <Youtube connected={youtubeToken != ""} onClick={youtubeToken == "" ? redirectToYoutube : disconnectFromYoutube}>
            <BannerButtonImage src={STATIC + "youtube.png"}/>
            <BannerButtonText>{(youtubeToken == "" ? "Connect to" : "Disconnect from") + " YouTube"}</BannerButtonText>
        </Youtube>
    </SettingsContainer>
}