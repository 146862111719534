import {configureStore} from "@reduxjs/toolkit";
import userReducer, {userSlice} from "./userSlice";
import oAuth2Reducer, {oAuth2Slice} from "./oAuth2Slice";
import overlayReducer, {overlaySlice} from "./overlaySlice";
import playbackReducer, {playbackSlice} from "./playbackSlice";
import {Platform, ContinuousPlaybackSource, Tokens, TrackBase} from "../types";
import {createStateSyncMiddleware, initMessageListener,} from "redux-state-sync";
import storage from "redux-persist/lib/storage";
import {persistReducer, persistStore} from "redux-persist";

/*const persistConfig = {
    key: "root",
    storage,
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);
const persistedOAuth2Reducer = persistReducer(persistConfig, oAuth2Reducer);
const persistedPlaybackReducer = persistReducer(persistConfig, playbackReducer);*/

export const store = configureStore({
    reducer: {
        user: userReducer,
        oAuth2: oAuth2Reducer,
        overlay: overlayReducer,
        playback: playbackReducer,
    }
    /*reducer: {
        user: persistedUserReducer,
        oAuth2: persistedOAuth2Reducer,
        playback: persistedPlaybackReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createStateSyncMiddleware({
        blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
    })),*/
});

/*export const persistor = persistStore(store);

initMessageListener(store);*/

// oauth2
export function reduxGetPlaybackPlatforms() {
    const state = store.getState();
    return state.oAuth2.playbackPlatforms;
}

/*export function reduxGetSoundcloudToken() {
    const state = store.getState();
    return state.oAuth2.soundcloudToken;
}*/

export function reduxGetSpotifyToken() {
    const state = store.getState();
    return state.oAuth2.spotifyToken;
}

export function reduxGetYoutubeToken() {
    const state = store.getState();
    return state.oAuth2.youtubeToken;
}

export function reduxSetAllTokens(tokens: Tokens) {
    store.dispatch(oAuth2Slice.actions.setAllTokens(tokens));
}

// playback
export function reduxGetTrack() {
    const state = store.getState();
    return state.playback.track;
}

export function reduxGetPlaying() {
    const state = store.getState();
    return state.playback.playing;
}

export function reduxGetShuffle() {
    const state = store.getState();
    return state.playback.shuffle;
}

export function reduxGetShuffleHistory() {
    const state = store.getState();
    return state.playback.shuffleHistory;
}

export function reduxGetRepeat() {
    const state = store.getState();
    return state.playback.repeat;
}

export function reduxGetContinuousPlaybackSource() {
    const state = store.getState();
    return state.playback.continuousPlaybackSource;
}

export function reduxGetVolume() {
    const state = store.getState();
    return state.playback.volume;
}

export function reduxGetPrevVolume() {
    const state = store.getState();
    return state.playback.prevVolume;
}

export function reduxGetCurrentTime() {
    const state = store.getState();
    return state.playback.currentTime;
}

export function reduxGetExternalId() {
    const state = store.getState();
    return state.playback.externalId;
}

export function reduxGetPlatform() {
    const state = store.getState();
    return state.playback.platform as Platform | null;
}

export function reduxSetTrack(track: TrackBase) {
    store.dispatch(playbackSlice.actions.setTrack(track));
}

export function reduxSetPlaying(playing: boolean) {
    store.dispatch(playbackSlice.actions.setPlaying(playing));
}

export function reduxToggleShuffle() {
    store.dispatch(playbackSlice.actions.toggleShuffle());
}

export function reduxAddToShuffleHistory(trackId: number) {
    store.dispatch(playbackSlice.actions.addToShuffleHistory(trackId));
}

export function reduxClearShuffleHistory() {
    store.dispatch(playbackSlice.actions.clearShuffleHistory());
}

export function reduxCycleRepeat() {
    store.dispatch(playbackSlice.actions.cycleRepeat());
}

export function reduxSetContinuousPlaybackSource(continuousPlaybackSource: ContinuousPlaybackSource) {
    store.dispatch(playbackSlice.actions.setContinuousPlaybackSource(continuousPlaybackSource));
}

export function reduxSetVolume(volume: number) {
    store.dispatch(playbackSlice.actions.setVolume(volume));
}

export function reduxSetPrevVolume(prevVolume: number) {
    store.dispatch(playbackSlice.actions.setPrevVolume(prevVolume));
}

export function reduxSetCurrentTime(currentTime: number) {
    store.dispatch(playbackSlice.actions.setCurrentTime(currentTime));
}

export function reduxSetExternalId(externalId: string) {
    store.dispatch(playbackSlice.actions.setExternalId(externalId));
}

export function reduxSetPlatform(platform: Platform | null) {
    store.dispatch(playbackSlice.actions.setPlatform(platform));
}