import React from "react";
import {Platform, PLATFORMS, TrackBase, TrackExternal} from "../types";
import {APIException} from "../api";
import {isEqual} from "lodash";
import {NavigateFunction} from "react-router-dom";
import {FRONTEND_URL} from "../constants";

export function sharesElement(a: any[], b: any[]) {
    return a.some(elementA => b.some(elementB => isEqual(elementA, elementB)));
}

export function hasParentWithId(target: HTMLElement, selector: string, id: string) {
    return Array.from(document.querySelectorAll(selector)).some(element => {
        let ret: boolean[] = [];
        element.childNodes.forEach((child) => ret.push(child == target && element.id == id));
        return ret.some(x => x);
    });
}

export function hasAncestorWithId(target: HTMLElement, selector: string, id: string) {
    return Array.from(document.querySelectorAll(selector)).some(element =>
        element.contains(target) && element.id == id
    );
}

export function hasAncestor(target: HTMLElement, selector: string) {
    return Array.from(document.querySelectorAll(selector)).some(element =>
        element.contains(target)
    );
}

export function isOverlayClickOff(e: React.MouseEvent<HTMLElement>, overlayId: string) {
    return hasAncestorWithId(e.target as HTMLElement, "div", overlayId + "Bg") &&
        !hasAncestorWithId(e.target as HTMLElement, "div", overlayId);
}

export function onOverlayClick(e: React.MouseEvent<HTMLElement>, overlayId: string, setVisibility: (v: boolean) => void) {
    if (isOverlayClickOff(e, overlayId)) {
        setVisibility(false);
    }
}

export function getIndex(trackIds: number[], trackId: number) {
    return trackIds.findIndex((id) => id == trackId);
}

// TODO use of this function in types.tsx needs to go
export function onClickLink(e: React.MouseEvent, navigate: NavigateFunction, route: string) {
    if (e.ctrlKey) {
        window.open(FRONTEND_URL + route);
    } else {
        navigate("/" + route); // trailing slash at front for absolute; otherwise will append
    }
}

export function hasValidPlaybackPlatform(track: TrackBase, validPlatforms: Platform[]): boolean {
    try {
        getValidPlaybackExternal(track, validPlatforms);
        return true;
    } catch (e) {
        return false;
    }
}

export function getValidPlaybackExternal(track: TrackBase, validPlatforms: Platform[] = PLATFORMS): TrackExternal {
    const externals = [...track.externals]
        .filter((a) => validPlatforms.includes(a.platform))
        .sort((a, b) => a.duration_ms < b.duration_ms ? -1 : 1);
    // TODO identify if geoblocked
    if (externals.length > 0) return externals[0]; // shortest external

    throw new APIException("Could not find a valid playback source for \"" + track.name + "\" " +
        "(for Spotify playback, user must be logged in, connected to Spotify, and own Premium). " +
        "To connect an external service, navigate to Settings > Connections.", "401");
}

export function canPlayExternal(track:TrackBase, external: TrackExternal, validPlatforms: Platform[]) {
    return track.externals.filter((e) => e.external_id == external.external_id && e.platform == external.platform &&
        validPlatforms.includes(e.platform)).length > 0;
}