import {createGlobalStyle} from "styled-components";
import {PASTEL_NAVY, PASTEL_PINK, PASTEL_PURPLE} from "./components/reusable/styled/StyledComponents";

// https://google-webfonts-helper.herokuapp.com/
// TODO scrollbar support for other browsers
export const GlobalStyle = createGlobalStyle`
    html, body {
        font-family: Figtree, sans-serif;
    }
    
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    button {
        background-color: transparent;
        border: none;
        padding: 0 0 0 0;
    }

    a {
        text-decoration: none;
    }

    label, p {
        margin: 0 0 0 0;
    }
    
    img {
        object-fit: cover;
    }
    
    textarea {
        resize: none;
    }
    
    input:focus,
    textarea:focus {
        outline: none;
    }
    
    ::selection {
        background-color: ${PASTEL_NAVY} !important;
    }
    
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    
    ::-webkit-scrollbar-thumb {
        background-color: ${PASTEL_PURPLE};
        border-radius: 2px;
    }
    
    ::-webkit-scrollbar-thumb:hover {
        background-color: ${PASTEL_PINK};
    }
    
    ::-webkit-scrollbar-track-piece:start {
        background-color: transparent;
        border-radius: 2px;
    }
    
    ::-webkit-scrollbar-track-piece:end {
        background-color: transparent;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;