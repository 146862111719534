import {createSlice} from "@reduxjs/toolkit";
import {PlaylistDetails} from "../components/overlays/PlaylistDetailsOverlay";
import {ArtistBase, ArtistUtils, Model, TrackBase, TrackUtils} from "../types";

const emptyDetails = {
    name: "",
    description: "",
    cover: "",
    public: false,
} as PlaylistDetails;

export const overlaySlice = createSlice({
    name: "overlay",
    initialState: {
        // modify playlist overlay + create playlist overlay
        playlistDetails: emptyDetails,

        // modify playlist overlay
        modifyPlaylistId: null,
        modifyPlaylistOverlayVisibility: false,
        modifyPlaylistOverlayRefresh: () => {},

        // create playlist overlay
        createPlaylistOverlayVisibility: false,
        createPlaylistOverlayRefresh: (playlistId: number) => {},

        // merge playlist overlay
        mergePlaylistDestId: null,
        mergePlaylistOverlayVisibility: false,
        mergePlaylistOverlayRefresh: () => {},

        // merge overlay
        mergeOverlayId: null,
        mergeOverlayType: null,
        mergeOverlayVisibility: false,
        mergeOverlayRefresh: () => {},

        // edit track overlay
        editTrackOverlayTrack: TrackUtils.empty(),
        editTrackOverlayVisibility: false,
        editTrackOverlayRefresh: () => {},

        // edit artist overlay
        editArtistOverlayArtist: ArtistUtils.empty(),
        editArtistOverlayVisibility: false,
        editArtistOverlayRefresh: () => {},

        // add artist overlay
        addArtistOverlayTrackId: null,
        addArtistOverlayVisibility: false,
        addArtistOverlayRefresh: () => {},

        // add external overlay
        addExternalOverlayTrackId: null,
        addExternalOverlayVisibility: false,
        addExternalOverlayRefresh: () => {},

        // add url track overlay
        addURLTrackOverlayPlaylistId: null,
        addURLTrackOverlayVisibility: false,
        addURLTrackOverlayRefresh: () => {},

        // import playlist url overlay
        importPlaylistURLOverlayVisibility: false,

        // import playlist hastebin overlay
        importPlaylistHastebinOverlayVisibility: false,

        // add track playlist overlay
        addTrackOverlayTrack: TrackUtils.empty(),
        addTrackOverlayVisibility: false,
        addTrackOverlayRefresh: () => {},

        // track externals overlay
        trackExternalsOverlayTrack: TrackUtils.empty(),
        trackExternalsOverlayVisibility: false,

        // import personal playlist overlay
        importPersonalPlaylistOverlayVisibility: false,

        // new playlist overlay
        newPlaylistOverlayVisibility: false,

        // import artist overlay
        importArtistOverlayVisibility: false,
        importArtistOverlayRefresh: () => {},
    },
    reducers: {
        showModifyPlaylistOverlay: (state, action) => {
            const playlist = action.payload.playlist;
            state.modifyPlaylistId = playlist.playlist_id;
            state.playlistDetails = {
                name: playlist.name,
                description: playlist.description,
                cover: playlist.cover,
                public: playlist.public,
            };
            state.modifyPlaylistOverlayVisibility = true;
            state.modifyPlaylistOverlayRefresh = action.payload.refresh;
        },

        setModifyPlaylistOverlayVisibility: (state, action) => {
            state.modifyPlaylistOverlayVisibility = action.payload;
        },

        showCreatePlaylistOverlay: (state, action) => {
            state.createPlaylistOverlayVisibility = true;
            state.playlistDetails = emptyDetails;
            state.createPlaylistOverlayRefresh = action.payload;
        },

        setCreatePlaylistOverlayVisibility: (state, action) => {
            state.createPlaylistOverlayVisibility = action.payload;
        },

        showMergePlaylistOverlay: (state, action) => {
            state.mergePlaylistDestId = action.payload.playlistId;
            state.mergePlaylistOverlayVisibility = true;
            state.mergePlaylistOverlayRefresh = action.payload.refresh;
        },

        setMergePlaylistOverlayVisibility: (state, action) => {
            state.mergePlaylistOverlayVisibility = action.payload;
        },

        showMergeOverlay: (state, action) => {
            state.mergeOverlayId = action.payload.id;
            state.mergeOverlayType = action.payload.type;
            state.mergeOverlayRefresh = action.payload.refresh;
            state.mergeOverlayVisibility = true;
        },

        setMergeOverlayVisibility: (state, action) => {
            state.mergeOverlayVisibility = action.payload;
        },

        showEditTrackOverlay: (state, action) => {
            state.editTrackOverlayTrack = action.payload.track;
            state.editTrackOverlayRefresh = action.payload.refresh;
            state.editTrackOverlayVisibility = true;
        },

        setEditTrackOverlayVisibility: (state, action) => {
            state.editTrackOverlayVisibility = action.payload;
        },

        showEditArtistOverlay: (state, action) => {
            state.editArtistOverlayArtist = action.payload.artist;
            state.editArtistOverlayRefresh = action.payload.refresh;
            state.editArtistOverlayVisibility = true;
        },

        setEditArtistOverlayVisibility: (state, action) => {
            state.editArtistOverlayVisibility = action.payload;
        },

        showAddArtistOverlay: (state, action) => {
            state.addArtistOverlayTrackId = action.payload.trackId;
            state.addArtistOverlayRefresh = action.payload.refresh;
            state.addArtistOverlayVisibility = true;
        },

        setAddArtistOverlayVisibility: (state, action) => {
            state.addArtistOverlayVisibility = action.payload;
        },

        showAddExternalOverlay: (state, action) => {
            state.addExternalOverlayTrackId = action.payload.trackId;
            state.addExternalOverlayRefresh = action.payload.refresh;
            state.addExternalOverlayVisibility = true;
        },

        setAddExternalOverlayVisibility: (state, action) => {
            state.addExternalOverlayVisibility = action.payload;
        },

        showAddURLTrackOverlay: (state, action) => {
            state.addURLTrackOverlayPlaylistId = action.payload.playlistId;
            state.addURLTrackOverlayRefresh = action.payload.refresh;
            state.addURLTrackOverlayVisibility = true;
        },

        setAddURLTrackOverlayVisibility: (state, action) => {
            state.addURLTrackOverlayVisibility = action.payload;
        },

        setImportPlaylistURLOverlayVisibility: (state, action) => {
            state.importPlaylistURLOverlayVisibility = action.payload;
        },

        setImportPlaylistHastebinOverlayVisibility: (state, action) => {
            state.importPlaylistHastebinOverlayVisibility = action.payload;
        },

        showAddTrackOverlay: (state, action) => {
            state.addTrackOverlayTrack = action.payload.track;
            state.addTrackOverlayRefresh = action.payload.refresh;
            state.addTrackOverlayVisibility = true;
        },

        setAddTrackOverlayVisibility: (state, action) => {
            state.addTrackOverlayVisibility = action.payload;
        },

        showTrackExternalsOverlay: (state, action) => {
            state.trackExternalsOverlayTrack = action.payload;
            state.trackExternalsOverlayVisibility = true;
        },

        setTrackExternalsOverlayVisibility: (state, action) => {
            state.trackExternalsOverlayVisibility = action.payload;
        },

        setImportPersonalPlaylistOverlayVisibility: (state, action) => {
            state.importPersonalPlaylistOverlayVisibility = action.payload;
        },

        setNewPlaylistOverlayVisibility: (state, action) => {
            state.newPlaylistOverlayVisibility = action.payload;
        },

        showImportArtistOverlay: (state, action) => {
            state.importArtistOverlayRefresh = action.payload;
            state.importArtistOverlayVisibility = true;
        },

        setImportArtistOverlayVisibility: (state, action) => {
            state.importArtistOverlayVisibility = action.payload;
        },
    }
});

export const getModifyPlaylistId = (state: any): number | null => state.overlay.modifyPlaylistId;
export const getPlaylistDetails = (state: any): PlaylistDetails => state.overlay.playlistDetails;
export const getModifyPlaylistOverlayVisibility = (state: any): boolean => state.overlay.modifyPlaylistOverlayVisibility;
export const getModifyPlaylistOverlayRefresh = (state: any): () => void => state.overlay.modifyPlaylistOverlayRefresh;

export const getCreatePlaylistOverlayVisibility = (state: any): boolean => state.overlay.createPlaylistOverlayVisibility;
export const getCreatePlaylistOverlayRefresh = (state: any): (playlistId: number) => void => state.overlay.createPlaylistOverlayRefresh;

export const getMergePlaylistDestId = (state: any): number | null => state.overlay.mergePlaylistDestId;
export const getMergePlaylistOverlayVisibility = (state: any): boolean => state.overlay.mergePlaylistOverlayVisibility;
export const getMergePlaylistOverlayRefresh = (state: any): () => void => state.overlay.mergePlaylistOverlayRefresh;

export const getMergeOverlayId = (state: any): number | null => state.overlay.mergeOverlayId;
export const getMergeOverlayType = (state: any): Model => state.overlay.mergeOverlayType;
export const getMergeOverlayRefresh = (state: any): () => void => state.overlay.mergeOverlayRefresh;
export const getMergeOverlayVisibility = (state: any): boolean => state.overlay.mergeOverlayVisibility;

export const getEditTrackOverlayTrack = (state: any): TrackBase => state.overlay.editTrackOverlayTrack;
export const getEditTrackOverlayRefresh = (state: any): () => void => state.overlay.editTrackOverlayRefresh;
export const getEditTrackOverlayVisibility = (state: any): boolean => state.overlay.editTrackOverlayVisibility;

export const getEditArtistOverlayArtist = (state: any): ArtistBase => state.overlay.editArtistOverlayArtist;
export const getEditArtistOverlayRefresh = (state: any): () => void => state.overlay.editArtistOverlayRefresh;
export const getEditArtistOverlayVisibility = (state: any): boolean => state.overlay.editArtistOverlayVisibility;

export const getAddArtistOverlayTrackId = (state: any): number => state.overlay.addArtistOverlayTrackId;
export const getAddArtistOverlayRefresh = (state: any): () => void => state.overlay.addArtistOverlayRefresh;
export const getAddArtistOverlayVisibility = (state: any): boolean => state.overlay.addArtistOverlayVisibility;

export const getAddExternalOverlayTrackId = (state: any): number | null => state.overlay.addExternalOverlayTrackId;
export const getAddExternalOverlayRefresh = (state: any): () => void => state.overlay.addExternalOverlayRefresh;
export const getAddExternalOverlayVisibility = (state: any): boolean => state.overlay.addExternalOverlayVisibility;

export const getAddURLTrackOverlayPlaylistId = (state: any): number | null => state.overlay.addURLTrackOverlayPlaylistId;
export const getAddURLTrackOverlayRefresh = (state: any): () => void => state.overlay.addURLTrackOverlayRefresh;
export const getAddURLTrackOverlayVisibility = (state: any): boolean => state.overlay.addURLTrackOverlayVisibility;

export const getImportPlaylistURLOverlayVisibility = (state: any): boolean => state.overlay.importPlaylistURLOverlayVisibility;

export const getImportPlaylistHastebinOverlayVisibility = (state: any): boolean => state.overlay.importPlaylistHastebinOverlayVisibility;

export const getAddTrackOverlayTrack = (state: any): TrackBase => state.overlay.addTrackOverlayTrack;
export const getAddTrackOverlayRefresh = (state: any): () => void => state.overlay.addTrackOverlayRefresh;
export const getAddTrackOverlayVisibility = (state: any): boolean => state.overlay.addTrackOverlayVisibility;

export const getTrackExternalsOverlayTrack = (state: any): TrackBase => state.overlay.trackExternalsOverlayTrack;
export const getTrackExternalsOverlayVisibility = (state: any): boolean => state.overlay.trackExternalsOverlayVisibility;

export const getImportPersonalPlaylistOverlayVisibility = (state: any): boolean => state.overlay.importPersonalPlaylistOverlayVisibility;

export const getNewPlaylistOverlayVisibility = (state: any): boolean => state.overlay.newPlaylistOverlayVisibility;

export const getImportArtistOverlayRefresh = (state: any): () => void => state.overlay.importArtistOverlayRefresh;
export const getImportArtistOverlayVisibility = (state: any): boolean => state.overlay.importArtistOverlayVisibility;

export const {
    showModifyPlaylistOverlay, setModifyPlaylistOverlayVisibility,
    showCreatePlaylistOverlay, setCreatePlaylistOverlayVisibility,
    showMergePlaylistOverlay, setMergePlaylistOverlayVisibility,
    showMergeOverlay, setMergeOverlayVisibility,
    showEditTrackOverlay, setEditTrackOverlayVisibility,
    showEditArtistOverlay, setEditArtistOverlayVisibility,
    showAddArtistOverlay, setAddArtistOverlayVisibility,
    showAddExternalOverlay, setAddExternalOverlayVisibility,
    showAddURLTrackOverlay, setAddURLTrackOverlayVisibility,
    setImportPlaylistURLOverlayVisibility,
    setImportPlaylistHastebinOverlayVisibility,
    showAddTrackOverlay, setAddTrackOverlayVisibility,
    showTrackExternalsOverlay, setTrackExternalsOverlayVisibility,
    setImportPersonalPlaylistOverlayVisibility,
    setNewPlaylistOverlayVisibility,
    showImportArtistOverlay, setImportArtistOverlayVisibility,
} = overlaySlice.actions;
export default overlaySlice.reducer
