import dateFormat from "dateformat";

/**
 * Formats to (HH:M)M:SS given some number of seconds.
 */
import {ExternalBase, ExternalModel} from "../types";
import {LOGO} from "../constants";

export function getTimeFormat(seconds: number): string {
    const s = Math.floor(seconds % 60);
    const m = Math.floor(seconds / 60);
    const h = Math.floor(seconds / 3600);
    return (h > 0 ? `${h}:${String(m % 60).padStart(2, "0")}` : m) + `:${String(s).padStart(2, "0")}`;
}

/**
 * Formats window title to "<title> · Amogustream" and sets meta properties. TODO verify this works dynamically
 */
export function setWindowProperties(title: string, metaDesc: string = "A web app to create unified playlists from " +
                                    "multiple streaming platforms.", metaImg: string = LOGO): void {
    document.title = title + " · Amogustream";
    // document.querySelector('meta[property="og:title"]')!.setAttribute("content", title);
    // document.querySelector('meta[property="og:description"]')!.setAttribute("content", metaDesc);
    // document.querySelector('meta[property="og:image"]')!.setAttribute("content", metaImg);
}

export function getExternalLink(external: ExternalBase, type: ExternalModel) {
    switch (external.platform) {
        case "soundcloud": {
            return external.external_url;
        } case "spotify": {
            return "https://open.spotify.com/" + type.toLowerCase().replace("external", "") + "/" + external.external_id;
        } case "youtube": {
            if (type == "artistExternal") {
                return "https://youtube.com/channel/" + external.external_id;
            } else if (type == "trackExternal") {
                return "https://youtube.com/watch?v=" + external.external_id;
            } else {
                return "https://youtube.com/playlist?list=" + external.external_id;
            }
        }
    }
}

export function getDateFormatFromString(date: string) {
    return dateFormat(new Date(date), "mmm d, yyyy");
}