import styled from "styled-components";
import {BannerButton, BannerButtonImage, BannerButtonText} from "../reusable/styled/BannerButton";
import {PASTEL_GREEN, PASTEL_ORANGE, PASTEL_RED} from "../reusable/styled/StyledComponents";
import {Platform} from "../../types";
import {APIException, import_external_subscriptions} from "../../api";
import toast from "react-hot-toast";
import {Overlay, OverlayContentContainer} from "../reusable/styled/Overlay";
import {onOverlayClick} from "../../util/functions";
import {OverlayTitle} from "../reusable/styled/Title";
import {STATIC} from "../../constants";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getImportArtistOverlayRefresh, getImportArtistOverlayVisibility, setImportArtistOverlayVisibility} from "../../redux-store/overlaySlice";

const ImportSoundcloudPlaylistButton = styled(BannerButton)`
    grid-row: 3;
    
    &:hover {
        background-color: ${PASTEL_ORANGE}C0;
    }
`;

const ImportSpotifyPlaylistButton = styled(BannerButton)`
    grid-row: 4;
    
    &:hover {
        background-color: ${PASTEL_GREEN}C0;
    }
`;

const ImportYoutubePlaylistButton = styled(BannerButton)`
    grid-row: 5;
    
    &:hover {
        background-color: ${PASTEL_RED}C0;
    }
`;

export function ImportArtistOverlay() {
    const dispatch = useDispatch();
    const visible = useSelector(getImportArtistOverlayVisibility);
    const refreshState = useSelector(getImportArtistOverlayRefresh);
    const setVisibility = (newVisibility: boolean) => dispatch(setImportArtistOverlayVisibility(newVisibility));

    const importExternalSubscriptions = async (platform: Platform) => {
        try {
            await import_external_subscriptions(platform);
            setVisibility(false);
            refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    const overlayId = "importArtistOverlay";
    return <Overlay id={overlayId + "Bg"} visible={visible} setVisibility={setVisibility} onMouseDown={(e) => onOverlayClick(e, overlayId, setVisibility)}>
        <OverlayContentContainer id={overlayId}>
            <OverlayTitle>Import Subscriptions</OverlayTitle>

            <ImportSoundcloudPlaylistButton onClick={() => importExternalSubscriptions("soundcloud")}>
                <BannerButtonImage src={STATIC + "soundcloud.png"}/>
                <BannerButtonText>Import SoundCloud Following</BannerButtonText>
            </ImportSoundcloudPlaylistButton>

            <ImportSpotifyPlaylistButton onClick={() => importExternalSubscriptions("spotify")}>
                <BannerButtonImage src={STATIC + "spotify.png"}/>
                <BannerButtonText>Import Spotify Following</BannerButtonText>
            </ImportSpotifyPlaylistButton>

            <ImportYoutubePlaylistButton onClick={() => importExternalSubscriptions("youtube")}>
                <BannerButtonImage src={STATIC + "youtube.png"}/>
                <BannerButtonText>Import YouTube Subscriptions</BannerButtonText>
            </ImportYoutubePlaylistButton>
        </OverlayContentContainer>
    </Overlay>
}