import React, {useEffect, useState} from "react";
import {APIException, get_playlists} from "../../api";
import styled from "styled-components";
import {PlaylistCard} from "../cards/PlaylistCard";
import {VisibleProps} from "../../types";
import {STATIC} from "../../constants";
import {BannerButtonImage, BannerButtonText, WideBannerButton} from "../reusable/styled/BannerButton";
import {TabContentContainer} from "../reusable/styled/Tab";
import toast from "react-hot-toast";
import {setNewPlaylistOverlayVisibility} from "../../redux-store/overlaySlice";
import {useDispatch} from "react-redux";

const PlaylistsContainer = styled(TabContentContainer)`
    grid-template-rows: auto 1fr;
`;

const NewPlaylistButton = styled(WideBannerButton)`
    grid-row: 1;
`;

const PlaylistResults = styled.div`
    width: 100%;
    grid-row: 2;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
`;

export function PlaylistTabContent(props: VisibleProps) {
    const dispatch = useDispatch();
    const [playlists, setPlaylists] = useState<React.JSX.Element[]>([]);
    const [state, setState] = useState(true); // whenever playlist is modified
    const refreshState = () => setState(!state);

    useEffect(() => {
        if (!props.visible) return;
        (async() => {
            try {
                const results = await get_playlists();
                let actualPlaylists = [];
                for (const playlist of results) {
                    actualPlaylists.push(
                        <PlaylistCard
                            playlist={playlist}
                            refreshState={refreshState}
                            key={playlist.playlist_id}
                        />
                    )
                }
                setPlaylists(actualPlaylists);
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, [props.visible, state]);

    return <PlaylistsContainer {...props}>
        <NewPlaylistButton onClick={() => dispatch(setNewPlaylistOverlayVisibility(true))}>
            <BannerButtonImage src={STATIC + "plus.png"}/>
            <BannerButtonText>New Playlist</BannerButtonText>
        </NewPlaylistButton>
        <PlaylistResults>{playlists}</PlaylistResults>

    </PlaylistsContainer>
}

