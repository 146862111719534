import React from "react";
import {BodyContainer, ContentContainer} from "./reusable/styled/Container";
import {Title} from "./reusable/styled/Title";

export function HomePage() {
    return <BodyContainer>
        <ContentContainer style={{"width": "1200px"}}>
            <Title>Home</Title>
        </ContentContainer>
    </BodyContainer>
}