import React from "react";
import styled from "styled-components";
import {setWindowProperties} from "../util/format";
import {BodyContainer, ContentContainer} from "../components/reusable/styled/Container";
import {Title} from "../components/reusable/styled/Title";
import {FOREGROUND, LARGE_FONT_SIZE} from "../components/reusable/styled/StyledComponents";
import {ANIMATED_LOGO} from "../constants";

const NewTitle = styled(Title)`
    justify-self: center;
    grid-column: 1;
`;

const Text = styled.p`
    justify-self: center;
    color: ${FOREGROUND};
    font-size: ${LARGE_FONT_SIZE};
`;

const LoadingImage = styled.img`
    width: 30px;
    height: 30px;
`;

export function Lost() {
    setWindowProperties("404");
    return <BodyContainer>
        <ContentContainer>
            <NewTitle>ERROR 404</NewTitle>
            <Text>NOT FOUND</Text>
            <LoadingImage src={ANIMATED_LOGO}/>
        </ContentContainer>
    </BodyContainer>;
}
