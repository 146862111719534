import React, {useEffect, useState} from "react";
import {APIException, get_followed_artists, is_admin} from "../../api";
import {STATIC} from "../../constants";
import styled from "styled-components";
import {VisibleProps} from "../../types";
import {ArtistCard} from "../cards/ArtistCard";
import {BannerButtonImage, BannerButtonText, WideBannerButton} from "../reusable/styled/BannerButton";
import {TabContentContainer} from "../reusable/styled/Tab";
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";
import {showImportArtistOverlay} from "../../redux-store/overlaySlice";

// TODO arrange in grid instead of rows? not enough info for each artist to warrant a row

const ArtistsContainer = styled(TabContentContainer)`
    grid-template-rows: auto 1fr;
`;

const ImportArtistButton = styled(WideBannerButton)`
    grid-row: 1;
`;

const ArtistsResults = styled.div`
    width: 100%;
    grid-row: 2;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(8, 1fr);
`;

export function ArtistTabContent(props: VisibleProps) {
    const dispatch = useDispatch();
    const [artists, setArtists] = useState<React.JSX.Element[]>([]);
    const [state, setState] = useState(true); // whenever artist follows change
    const [isAdmin, setIsAdmin] = useState(false);
    const refreshState = () => setState(!state);

    useEffect(() => {
        (async() => {
            try {
                setIsAdmin(await is_admin());
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, []);

    useEffect(() => {
        if (!props.visible) return;
        (async() => {
            try {
                const results = await get_followed_artists();

                let actualArtists = [];
                for (const artist of results) {
                    actualArtists.push(
                        <ArtistCard artist={artist} key={artist.artist_id} isAdmin={isAdmin} refreshState={refreshState}/>
                    )
                }
                setArtists(actualArtists);
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, [props.visible, state]);

    return <ArtistsContainer {...props}>
        <ImportArtistButton onClick={() => dispatch(showImportArtistOverlay(refreshState))}>
            <BannerButtonImage src={STATIC + "subscriptions.png"}/>
            <BannerButtonText>Import Subscriptions</BannerButtonText>
        </ImportArtistButton>

        <ArtistsResults>{artists}</ArtistsResults>
    </ArtistsContainer>
}

