import React, {useEffect, useState} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {Lost} from "./lost";
import {APIException, get_artist_id_by_external, get_track_id_by_external} from "../api";
import {Platform, PLATFORMS} from "../types";
import toast from "react-hot-toast";
import {BodyContainer} from "../components/reusable/styled/Container";


export function External() {
    const queryString = new URLSearchParams(useLocation().search);
    const type = queryString.get("type");
    const external_id = queryString.get("external_id");
    const platform = queryString.get("platform");

    const [id, setId] = useState<Number | null>(null);

    if ([type, external_id, platform].includes(null) ||
        !["track", "artist"].includes(type!) ||
        !PLATFORMS.includes(platform as Platform)) {
        return <Lost/>
    }

    useEffect(() => {
        (async() => {
            try {
                if (type == "track") {
                    setId(await get_track_id_by_external(external_id!, platform as Platform));
                } else if (type == "artist") {
                    setId(await get_artist_id_by_external(external_id!, platform as Platform));
                }
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, []);

    if (id == null) {
        return <BodyContainer/>
    }

    return <Navigate replace to={"/" + type + "/" + id}/>
}