import React from "react";
import {Navigate} from "react-router-dom";
import {APIException, logout} from "../api";
import {clearStorageKey, clearStorageUsername, getStorageKey} from "../redux-store/userSlice";
import {useDispatch, useSelector} from "react-redux";
import {clearAllTokens} from "../redux-store/oAuth2Slice";
import {setWindowProperties} from "../util/format";
import toast from "react-hot-toast";

export function Logout() {
    const dispatch = useDispatch();

    if (!useSelector(getStorageKey)) {
        return <Navigate to="/"/>
    }

    setWindowProperties("Logout");

    (async() => {
        try {
            await logout();
            dispatch(clearStorageKey());
            dispatch(clearStorageUsername());
            dispatch(clearAllTokens());
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    })();

    return <Navigate to="/login"/>
}