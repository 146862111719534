import styled from "styled-components";
import {FOREGROUND, LARGE_FONT_SIZE, SELECTION} from "./StyledComponents";

export const BannerButton = styled.button`
    border-radius: 5px;
    padding: 15px 15px 15px 15px;
    width: 400px;
    height: 60px;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: auto 15px auto 1fr;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        background-color: ${SELECTION};
    }
`;

export const BannerButtonImage = styled.img`
    width: 30px;
    height: 30px;
    grid-column: 1;
`;

export const BannerButtonText = styled.p`
    color: ${FOREGROUND};
    font-size: ${LARGE_FONT_SIZE};
    grid-column: 3;
`;

export const WideBannerButton = styled(BannerButton)`
    width: 100%;
`;