import styled, {css} from "styled-components";
import {TabProps, VisibleProps} from "../../../types";
import {FOREGROUND, LARGE_FONT_SIZE, PASTEL_PINK, PASTEL_PURPLE} from "./StyledComponents";

export const TabContentContainer = styled.div<VisibleProps>`
    width: 100%;
    grid-column: 1 / span 9999;
    grid-row: 5;
    
    display: grid;
    justify-items: center;
    align-items: center;
    
    ${props => props.visible ? css`
        opacity: 1;
        visibility: visible;
        transition: opacity 0.1s ease-in-out 0s, visibility 0s ease-in-out 0s;
        height: auto;
    ` : css`
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.1s ease-in-out 0s, visibility 0s ease-in-out 0.1s;
        height: 0;
        overflow-y: hidden;
    `}
`;

export const Tab = styled.button<TabProps>`
    border-bottom: 3px solid transparent;
    color: ${FOREGROUND};
    font-size: ${LARGE_FONT_SIZE};
    text-align: center;
    height: 40px;
    transition: all 0.1s ease-in-out;
    
    ${props => props.selected && css`
        border-color: ${PASTEL_PURPLE};
    `}
    &:hover {
        border-color: ${PASTEL_PINK};
    }
`;