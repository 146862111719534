import {resolveValue, toast, Toaster, ToastType} from "react-hot-toast";
import {BACKGROUND, FOREGROUND, PASTEL_GREEN, PASTEL_RED, REGULAR_FONT_SIZE} from "./reusable/styled/StyledComponents";
import styled, {css} from "styled-components";
import React from "react";

interface ToastProps {
    type: ToastType;
    visible: boolean;
}

const Toast = styled.div<ToastProps>`
    border-radius: 5px;
    ${props => props.type == "error" && css`
        background-color: color-mix(in srgb, ${PASTEL_RED} 80%, ${BACKGROUND});
    `}
    ${props => props.type == "success" && css`
        background-color: color-mix(in srgb, ${PASTEL_GREEN} 70%, ${BACKGROUND});
    `}
    max-width: 25%;
    padding: 15px 15px 15px 15px;
    cursor: pointer;

    opacity: ${props => props.visible ? 1 : 0};
    visibility: ${props => props.visible ? "visible" : "hidden"};
    transition: opacity 0.1s ease-in-out, visibility 0s ${props => props.visible ? "0s" : "0.1s"},
        background-color 0.1s ease-in-out;

    &:hover {
        ${props => props.type == "error" && css`
            background-color: color-mix(in srgb, ${PASTEL_RED} 90%, ${BACKGROUND});
        `}
        ${props => props.type == "success" && css`
            background-color: color-mix(in srgb, ${PASTEL_GREEN} 80%, ${BACKGROUND});
        `}
    }
`;

const ToastText = styled.div`
    height: min-content;
    max-height: 100px;
    overflow-y: auto;
    color: ${FOREGROUND};
    font-size: ${REGULAR_FONT_SIZE};
    text-align: left;
    white-space: break-spaces;
    line-height: 20px;
    word-wrap: break-word;
`;

// TODO maybe dismiss button instead of clicking entire toast
export function CustomToaster() {
    return <Toaster
        position="bottom-right"
        gutter={15}
        containerStyle={{
            bottom: 100,
            right: 30,
            zIndex: 12000,
        }}
        toastOptions={{
            duration: 15000,
        }}
    >
        {(t) => (
            <Toast type={t.type} visible={t.visible} onClick={() => toast.dismiss(t.id)}>
                <ToastText>{(resolveValue(t.message, t))}</ToastText>
            </Toast>
        )}
    </Toaster>
}