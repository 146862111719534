import React from "react";
import styled from "styled-components";
import {ANIMATED_LOGO} from "../constants";
import {FOREGROUND, TITLE_FONT_SIZE} from "./reusable/styled/StyledComponents";
import {BodyContainer, ContentContainer} from "./reusable/styled/Container";

const LoadingContentContainer = styled(ContentContainer)`
    height: 100%;
    grid-template-rows: 1fr auto auto 5fr;
`;

const LoadingText = styled.p`
    color: ${FOREGROUND};
    font-size: ${TITLE_FONT_SIZE};
    grid-row: 2;
`;

const LoadingImage = styled.img`
    width: 100px;
    height: 100px;
    grid-row: 3;
`;

export function Loading() {
    return <BodyContainer>
        <LoadingContentContainer>
            <LoadingText>Loading...</LoadingText>
            <LoadingImage src={ANIMATED_LOGO}/>
        </LoadingContentContainer>
    </BodyContainer>
}