import {HomePage} from "../components/HomePage";
import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {getStorageKey} from "../redux-store/userSlice";
import {setWindowProperties} from "../util/format";
import {clear_duplicates} from "../api";

export function Home() {
    if (!useSelector(getStorageKey)) {
        return <Navigate to="/login"/>
    }

    setWindowProperties("Home", "Welcome to Amogustream.");

    return <HomePage/>
}

export function Clear() {
    useEffect(() => {
        (async() => await clear_duplicates())();
    }, []);
    return <Home/>
}