import React, {useContext} from "react";
import styled, {css} from "styled-components";
import {IncompletePlaylist, PlaylistUtils} from "../../types";
import {BACKGROUND, PASTEL_PINK, PASTEL_PURPLE, SmallCard, SmallCardImage, SmallCardLeftText, SmallCardRightText, SmallDropdownButton, SmallDropdownButtonImage} from "../reusable/styled/StyledComponents";
import {STATIC} from "../../constants";
import {getDateFormatFromString} from "../../util/format";
import {get_playlist} from "../../api";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {globalContext} from "../../index";
import {getShuffle} from "../../redux-store/playbackSlice";
import {sample} from "lodash";

/**
 * TODO move to sortabletrackcard and rename to sortablecard, then group by classes / subclasses
 */

const BasePlaylistCardContainer = styled(SmallCard)`
    grid-template-columns: 30px 20px 40px 10px 700px 10px 160px 10px 110px 40px; // playlist
`;

const CoverContainer = styled.div`
    width: 40px;
    height: 40px;
    z-index: 10;
    display: grid;
    justify-items: center;
    align-items: center;
`;

const CoverImage = styled(SmallCardImage)`
    grid-column: 1;
    grid-row: 1;
`;

const PlayButtonContainer = styled.div`
    width: 40px;
    height: 40px;
    z-index: 10;
    grid-column: 1;
    grid-row: 1;
    display: grid;
    justify-items: center;
    align-items: center;
    opacity: 0;
    transition: all 0.1s ease-in-out;

    ${BasePlaylistCardContainer}:hover & {
        cursor: pointer;
        background-color: ${BACKGROUND}80;
        opacity: 1;
    }
`;

const PlayButton = styled.img`
    width: 20px;
    height: 20px;
`;

interface SmallCardTextProps {
    currentlyPlaying: boolean;
}

const SmallCardLeftTextColoured = styled(SmallCardLeftText)<SmallCardTextProps>`
    line-height: 15px;
    ${props => props.currentlyPlaying && css`
        color: ${PASTEL_PINK};
    `}
`;

const SmallCardRightTextColoured = styled(SmallCardRightText)<SmallCardTextProps>`
    line-height: 15px;
    ${props => props.currentlyPlaying && css`
        color: ${PASTEL_PINK};
    `}
`;

const TextContainer = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
    grid-template-columns: 100%;
`;

const Name = styled(SmallCardLeftTextColoured)`
    font-weight: bold;
    transition: all 0.1s ease-in-out;

    &:hover {
        color: ${PASTEL_PURPLE};
    }
`;

interface BaseSortablePlaylistCardProps {
    playlist: IncompletePlaylist;
    number: number;
    onPlay: () => void;
    refreshState: () => void;
}

export function BaseSortablePlaylistCard(props: BaseSortablePlaylistCardProps) {
    const playlist = props.playlist;
    // TODO const currentlyPlaying = TrackUtils.currentlyPlaying(track, playingTrack, playing);
    const currentlyPlaying = false;

    return <BasePlaylistCardContainer>
        <SmallCardRightTextColoured currentlyPlaying={currentlyPlaying}>{props.number}</SmallCardRightTextColoured>
        <div/>
        <CoverContainer onClick={props.onPlay}>
            <CoverImage src={playlist.cover}/>
            <PlayButtonContainer hidden={false}>
                <PlayButton src={STATIC + (currentlyPlaying ? "pause.png" : "play.png")}/>
            </PlayButtonContainer>
        </CoverContainer>
        <div/>
        <TextContainer>
            <Name as={Link} to={"/playlist/" + playlist.playlist_id} currentlyPlaying={currentlyPlaying}>
                {PlaylistUtils.formatName(playlist.name)}
            </Name>
            <SmallCardLeftTextColoured currentlyPlaying={currentlyPlaying}>
                {PlaylistUtils.formatDescription(playlist.description)}
            </SmallCardLeftTextColoured>
        </TextContainer>
        <div/>
        <SmallCardLeftTextColoured currentlyPlaying={currentlyPlaying}>
            {getDateFormatFromString(playlist.creation_date)}
        </SmallCardLeftTextColoured>
        <div/>
        <SmallCardRightTextColoured currentlyPlaying={currentlyPlaying}>
            {PlaylistUtils.formatNumTracks(playlist.tracks.length)}
        </SmallCardRightTextColoured>
        <SmallDropdownButton>
            <SmallDropdownButtonImage src={STATIC + "dropdown.png"}/>
        </SmallDropdownButton>
    </BasePlaylistCardContainer>
}

interface UserPlaylistCardProps {
    playlist: IncompletePlaylist;
    number: number;
    refreshState: () => void;
}

export function UserPlaylistCard(props: UserPlaylistCardProps) {
    const {macroController} = useContext(globalContext);
    const shuffle = useSelector(getShuffle);
    const playlist = props.playlist;

    const onPlay = async() => {
        if (playlist.tracks.length > 0) {
            const results = await get_playlist(playlist.playlist_id!);
            const track = shuffle ? sample(results.playlist.tracks)! : results.playlist.tracks[0];
            await macroController.macroTogglePlay(track, {
                playlistId: playlist.playlist_id,
                artistId: null,
                trackId: track.track_id,
                sortTrackBy: "default",
                sortDirection: null,
                cachedSourceName: playlist.name,
            });
        }
    };

    return <BaseSortablePlaylistCard {...props} onPlay={onPlay}/>
}