import styled from "styled-components";
import {OverlayProps} from "../../../types";
import {BACKGROUND} from "./StyledComponents";

export const Overlay = styled.div<OverlayProps>`
    width: 100%;
    height: 100%;
    position: fixed;
    inset: 0;
    background-color: #000000C0;
    opacity: ${props => props.visible ? 1 : 0};
    visibility: ${props => props.visible ? "visible" : "hidden"};
    transition: opacity 0.1s ease-in-out, visibility 0s ${props => props.visible ? "0s" : "0.1s"};
    
    display: grid;
    justify-items: center;
    align-items: center;
`;

export const OverlayContentContainer = styled.div`
    background-color: ${BACKGROUND};
    border-radius: 5px;
    max-height: calc(100vh - 100px);
    position: fixed;
    padding: 25px 25px 25px 25px;
    overflow-y: auto;
    box-shadow: 0 0 50px 25px #00000040;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto 25px 1fr;
`;