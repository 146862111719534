import {createSlice} from "@reduxjs/toolkit";
import {Platform} from "../types";

export const oAuth2Slice = createSlice({
    name: "oAuth2",
    initialState: {
        playbackPlatforms: [] as Platform[],
        // soundcloudToken: "",
        spotifyToken: "",
        youtubeToken: "",
    },
    reducers: {
        setPlaybackPlatforms: (state, action) => {
            state.playbackPlatforms = action.payload;
        },
        setAllTokens: (state, action) => {
            const tokens = action.payload;
            console.log(tokens);
            // state.soundcloudToken = tokens.soundcloud;
            state.spotifyToken = tokens.spotify;
            state.youtubeToken = tokens.youtube;
        },
        /*clearSoundcloudToken: (state) => {
            state.soundcloudToken = "";
        },*/
        clearSpotifyToken: (state) => {
            state.spotifyToken = "";
        },
        clearYoutubeToken: (state) => {
            state.youtubeToken = "";
        },
        clearAllTokens: (state) => {
            // state.soundcloudToken = "";
            state.spotifyToken = "";
            state.youtubeToken = "";
        }
    }
});

export const getPlaybackPlatforms = (state: any): Platform[] => state.oAuth2.playbackPlatforms;
// export const getSoundcloudToken = (state: any): string => state.oAuth2.soundcloudToken;
export const getSpotifyToken = (state: any): string => state.oAuth2.spotifyToken;
export const getYoutubeToken = (state: any): string => state.oAuth2.youtubeToken;
export const {
    setPlaybackPlatforms,
    setAllTokens,
    // clearSoundcloudToken,
    clearSpotifyToken,
    clearYoutubeToken,
    clearAllTokens
} = oAuth2Slice.actions;
export default oAuth2Slice.reducer
