import React, {ForwardedRef} from "react";
import {ArtistBase, ArtistUtils} from "../../types";
import styled from "styled-components";
import {LargeCardText, PASTEL_PURPLE, REGULAR_FONT_SIZE, SELECTION} from "../reusable/styled/StyledComponents";
import {Link} from "react-router-dom";
import {ArtistMenu} from "../reusable/functional/Menu";
import * as ContextMenu from "@radix-ui/react-context-menu";

/**
 * TODO
 * - add unfollow button
 */

export const ArtistCardContainer = styled(Link)`
    border-radius: 5px;
    width: 150px;
    height: 100%;
    cursor: pointer;
    padding: 15px 15px 15px 15px;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: auto 10px auto;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        background-color: ${SELECTION};
    }
`;

const Image = styled.img`
    width: 120px;
    height: 120px;
    cursor: pointer;

    grid-column: 1;
    grid-row: 1;
    box-shadow: 0 0 10px 5px #00000040;
`;

const Name = styled(LargeCardText)`
    font-size: ${REGULAR_FONT_SIZE};
    font-weight: bold;
    text-align: center;

    grid-column: 1;
    grid-row: 3;
    
    ${ArtistCardContainer}:hover & {
        color: ${PASTEL_PURPLE};
    }
`;

interface ArtistCardProps {
    artist: ArtistBase;
    isAdmin: boolean;
    refreshState: () => void;
}

export function ArtistCard(props: ArtistCardProps) {
    const artist = props.artist;
    return <ArtistMenu
        menuType={ContextMenu}
        component={React.forwardRef((nestedProps, forwardedRef: ForwardedRef<any>) =>
            <ArtistCardContainer to={"/artist/" + artist.artist_id} {...nestedProps} ref={forwardedRef}>
                <Image src={ArtistUtils.getImage(artist)}/>
                <Name>{props.artist.name}</Name>
            </ArtistCardContainer>
        )}
        artist={artist}
        isAdmin={props.isAdmin}
        refreshState={props.refreshState}
    />
}