import {createSlice} from "@reduxjs/toolkit";
import {Platform, ContinuousPlaybackSource, ContinuousPlaybackSourceUtils, Repeat, TrackBase, TrackUtils} from "../types";
import {shuffle} from "lodash";

export const playbackSlice = createSlice({
    name: "playback",
    initialState: {
        spotifyDeviceId: "", // TODO maybe redux across tabs?

        track: localStorage.getItem("track") != null ? JSON.parse(localStorage.getItem("track")!) as TrackBase : TrackUtils.empty(),
        playing: false,
        shuffle: localStorage.getItem("shuffle") != null ? localStorage.getItem("shuffle") == "true" : false,
        shuffleHistory: [] as number[],
        repeat: localStorage.getItem("repeat") != null ? localStorage.getItem("repeat") as Repeat : "none",
        continuousPlaybackSource: ContinuousPlaybackSourceUtils.empty(),
        volume: localStorage.getItem("volume") != null ? Number(localStorage.getItem("volume")) : 0.5,
        prevVolume: localStorage.getItem("prevVolume") != null ? Number(localStorage.getItem("prevVolume")) : 0.5,
        currentTime: 0, // in ms
        externalId: localStorage.getItem("externalId") || "",
        platform: localStorage.getItem("platform") || null,
    },
    reducers: {
        setSpotifyDeviceId: (state, action) => {
            state.spotifyDeviceId = action.payload;
        },

        setTrack: (state, action) => {
            // localStorage.setItem("track", JSON.stringify(action.payload)); TODO wait until actually works
            state.track = action.payload;
        },

        setPlaying: (state, action) => {
            state.playing = action.payload;
            if (action.payload) console.log("Currently playing from " + state.platform);
        },

        toggleShuffle: (state) => {
            const newShuffle = !state.shuffle;
            localStorage.setItem("shuffle", String(newShuffle));
            state.shuffle = newShuffle;

            if (!ContinuousPlaybackSourceUtils.isEmpty(state.continuousPlaybackSource)) {
                if (!newShuffle) {
                    playbackSlice.caseReducers.clearShuffleHistory(state);
                } else {
                    playbackSlice.caseReducers.addToShuffleHistory(state, {
                        type: "number",
                        payload: state.continuousPlaybackSource.trackId!,
                    });
                }
            }
        },

        addToShuffleHistory: (state, action) => {
            if (!state.shuffleHistory.includes(action.payload)) state.shuffleHistory.push(action.payload);
        },

        clearShuffleHistory: (state) => {
            state.shuffleHistory = [];
        },

        cycleRepeat: (state) => {
            let newRepeat: Repeat;
            if (state.repeat == "none") {
                newRepeat = "single";
            } else if (state.repeat == "single") {
                newRepeat = "multiple";
            } else {
                newRepeat = "none";
            }

            localStorage.setItem("repeat", newRepeat);
            state.repeat = newRepeat;
        },

        setContinuousPlaybackSource: (state, action) => {
            state.continuousPlaybackSource = action.payload;
        },

        setVolume: (state, action) => {
            localStorage.setItem("volume", action.payload);
            state.volume = action.payload;
        },

        setPrevVolume: (state, action) => {
            localStorage.setItem("prevVolume", action.payload);
            state.prevVolume = action.payload;
        },

        setCurrentTime: (state, action) => {
            state.currentTime = action.payload;
        },

        setExternalId: (state, action) => {
            localStorage.setItem("externalId", action.payload);
            state.externalId = action.payload;
        },

        setPlatform: (state, action) => {
            localStorage.setItem("platform", action.payload);
            state.platform = action.payload;
        },

        clear: () => {
            localStorage.removeItem("track");
            localStorage.removeItem("shuffle");
            localStorage.removeItem("repeat");
            localStorage.removeItem("volume");
            localStorage.removeItem("prevVolume");
            localStorage.removeItem("externalId");
            localStorage.removeItem("platform");
        }
    }
});

export const getSpotifyDeviceId = (state: any): string => state.playback.spotifyDeviceId;
export const getTrack = (state: any): TrackBase => state.playback.track;
export const getPlaying = (state: any): boolean => state.playback.playing;
export const getShuffle = (state: any): boolean => state.playback.shuffle;
export const getShuffleHistory = (state: any): number[] => state.playback.shuffleHistory;
export const getRepeat = (state: any): Repeat => state.playback.repeat;
export const getContinuousPlaybackSource = (state: any): ContinuousPlaybackSource => state.playback.continuousPlaybackSource;
export const getVolume = (state: any): number => state.playback.volume;
export const getPrevVolume = (state: any): number => state.playback.prevVolume;
export const getCurrentTime = (state: any): number => state.playback.currentTime;
export const getExternalId = (state: any): string => state.playback.externalId;
export const getPlatform = (state: any): Platform | null => state.playback.platform;
export const {setSpotifyDeviceId, setTrack, setPlaying, toggleShuffle, addToShuffleHistory, clearShuffleHistory,
    cycleRepeat, setContinuousPlaybackSource, setVolume, setPrevVolume, setCurrentTime, setPlatform} = playbackSlice.actions;
export default playbackSlice.reducer
