import React, {ForwardedRef, useState} from "react";
import {FRONTEND_URL, STATIC} from "../constants";
import {useSelector} from "react-redux";
import {getStorageKey} from "../redux-store/userSlice";
import {SearchBar} from "./SearchBar";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {BACKGROUND, HEADER_FONT_SIZE, PASTEL_PINK, PASTEL_PURPLE, SELECTION} from "./reusable/styled/StyledComponents";
import * as ContextMenu from "@radix-ui/react-context-menu";
import {RedirectMenu} from "./reusable/functional/Menu";
import {getPlaying} from "../redux-store/playbackSlice";

/**
 * TODO
 * - move ticket to track / album / artist page
 * - add stream button and route on the left side
 */

const HeaderContainer = styled.div`
    background-color: ${BACKGROUND};
    border-bottom: 2px solid;
    border-image: linear-gradient(to right, transparent 2%, ${SELECTION} 10%, ${SELECTION} 90%, transparent 98%) 100% 1;
    
    width: 100%;
    height: 70px;
    padding: 0 10px 0 10px;
    position: fixed;
    top: 0;
    z-index: -1;

    overflow-x: auto;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr 10px minmax(275px, 1150px) 10px 1fr;
`;

const Container = styled.div`
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
`;

const LeftContainer = styled(Container)`
    grid-column: 1;
    grid-template-columns: auto 1fr;
`;

const RightContainer = styled(Container)`
    grid-column: 5;
    grid-column-gap: 10px;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
`;

const HomeButton = styled(Link)`
    border-radius: 5px;
    width: 50px;
    height: 50px;
    grid-column: 1;
    transition: all 0.1s ease-in-out;

    &:hover {
        background-color: ${SELECTION};
    }
`;

const HomeButtonImage = styled.img`
    width: 50px;
    height: 50px;
`;

const HeaderButton = styled(Link)`
    border-radius: 5px;
    color: ${PASTEL_PURPLE};
    font-size: ${HEADER_FONT_SIZE};
    height: 35px;
    line-height: 35px;
    padding: 0 10px 0 10px;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        color: ${PASTEL_PINK};
        background-color: ${SELECTION};
    }
`;

export function Header() {
    const loggedIn = !!useSelector(getStorageKey);

    const texts = !loggedIn ? ["Login", "Register"] : ["Library", "Settings", "Logout"];
    let buttons = [];
    for (const text of texts) {
        buttons.push(
            <RedirectMenu
                menuType={ContextMenu}
                component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                    <HeaderButton to={"/" + text.toLowerCase()} key={text} {...props} ref={forwardedRef}>{text}</HeaderButton>)}
                url={FRONTEND_URL + text.toLowerCase()}
            />
        )
    }

    return <HeaderContainer>
        <LeftContainer>
            <RedirectMenu
                menuType={ContextMenu}
                component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                    <CompleteHomeButton to="/" {...props} ref={forwardedRef}/>
                )}
                url={FRONTEND_URL}
            />
        </LeftContainer>
        <SearchBar/>
        <RightContainer>
            <div/>
            {buttons}
        </RightContainer>
    </HeaderContainer>
}

function CompleteHomeButton(props: any) {
    const [hover, setHover] = useState(false);
    const playing = useSelector(getPlaying);

    return <HomeButton {...props} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <HomeButtonImage src={STATIC + "logo." + (hover || playing ? "gif" : "png")}/>
    </HomeButton>
}