import React, {createContext, useEffect, useState} from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import {Clear, Home} from "./routes/home";
import {Search} from "./routes/search";
import {Settings} from "./routes/settings";
import {Lost} from "./routes/lost";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {MediaController} from "./components/playback/MediaController";
import {Provider, useDispatch, useSelector} from "react-redux";
import {store} from "./redux-store/store";
import {Library} from "./routes/library";
import {Playlist} from "./routes/playlist";
import {Header} from "./components/Header";
import {Login} from "./routes/login";
import {Register} from "./routes/register";
import {Logout} from "./routes/logout";
import {GlobalContext, TrackUtils} from "./types";
import {APIException, get_tokens, playback_platforms, spotify_callback, youtube_callback} from "./api";
import {Artist} from "./routes/artist";
import {getPlaybackPlatforms, getSpotifyToken, setAllTokens, setPlaybackPlatforms} from "./redux-store/oAuth2Slice";
import {hasAncestor} from "./util/functions";
import {GlobalStyle} from "./globalStyle";
import {OAuth2} from "./routes/oauth2";
import {getStorageKey} from "./redux-store/userSlice";
import {MacroController} from "./embedControllers";
import {getContinuousPlaybackSource} from "./redux-store/playbackSlice";
import {Track} from "./routes/track";
import {User} from "./routes/user";
import {CustomToaster} from "./components/CustomToaster";
import toast from "react-hot-toast";
import {MasterOverlay} from "./components/overlays/MasterOverlay";
import {External} from "./routes/external";

/*
const backup = console.error;
console.error = function filterWarnings(args) {
    const suppressed = ["Each child in a list should have a unique \"key\" prop."];
    const [msg] = args;
    if (!suppressed.some(entry => msg.includes(entry))) {
        backup(...args);
    }
};
*/

function Content() {
    return <>
        <Header/>

        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/search" element={<Search/>}/>
            <Route path="/external" element={<External/>}/>
            <Route path="/library" element={<Library/>}/>
            <Route path="/settings" element={<Settings/>}/>
            <Route path="/logout" element={<Logout/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/register" element={<Register/>}/>
            <Route path="/spotify" element={<OAuth2 callback={spotify_callback}/>}/>
            <Route path="/youtube" element={<OAuth2 callback={youtube_callback}/>}/>
            <Route path="/clear" element={<Clear/>}/>

            <Route path="/playlist/:id" element={<Playlist/>}/>
            <Route path="/artist/:id" element={<Artist/>}/>
            <Route path="/track/:id" element={<Track/>}/>
            <Route path="/user/:username" element={<User/>}/>
            <Route path="*" element={<Lost/>}/>
        </Routes>

        <CustomToaster/>
        <MediaController/>
        <MasterOverlay/>
    </>
}

export const globalContext = createContext<GlobalContext>({} as GlobalContext);
function ContextProvider() {
    const dispatch = useDispatch();

    // Authentication
    const spotifyToken = useSelector(getSpotifyToken);
    const loggedIn = !!useSelector(getStorageKey);
    const playbackPlatforms = useSelector(getPlaybackPlatforms);
    const [macroController,] = useState<MacroController>(new MacroController());

    const continuousPlaybackSource = useSelector(getContinuousPlaybackSource);

    useEffect(() => {
        (async() => {
            if (!loggedIn) return;
            try {
                dispatch(setAllTokens(await get_tokens()));
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, [loggedIn]);

    useEffect(() => {
        (async() => dispatch(setPlaybackPlatforms(await playback_platforms())))();
    }, [spotifyToken]);

    useEffect(() => {
        if (playbackPlatforms.includes("soundcloud")) (async() => await macroController.init("soundcloud"))();
        if (playbackPlatforms.includes("spotify")) (async() => await macroController.init("spotify"))();
        if (playbackPlatforms.includes("youtube")) (async() => await macroController.init("youtube"))();
    }, [JSON.stringify(playbackPlatforms)]);

    useEffect(() => {
        const onPress = async(e: KeyboardEvent) => {
            if (hasAncestor(e.target as HTMLElement, "textarea")) return;
            if (hasAncestor(e.target as HTMLElement, "input") &&
                !hasAncestor(e.target as HTMLElement, 'input[type="range"]')) return;
            switch (e.key) {
                case " ":
                    e.preventDefault();
                    await macroController.macroTogglePlay(TrackUtils.empty(), continuousPlaybackSource);
                    break;
                case "ArrowLeft":
                    e.preventDefault();
                    await macroController.seekLeft();
                    break;
                case "ArrowRight":
                    e.preventDefault();
                    await macroController.seekRight();
                    break;
            }
        };

        document.addEventListener("keydown", onPress);
        return () => document.removeEventListener("keydown", onPress);
    });

    return <globalContext.Provider value={{macroController}}>
        <Content/>
    </globalContext.Provider>
}

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(<>
    <GlobalStyle/>
    <Provider store={store}>
        {/*<PersistGate loading={null} persistor={persistor}>*/}
        <BrowserRouter>
            <ContextProvider/>
        </BrowserRouter>
        {/*</PersistGate>*/}
    </Provider>
</>);