import styled from "styled-components";
import {RangeProps} from "../../../types";
import {PASTEL_PINK, PASTEL_PURPLE, REGULAR_FONT_SIZE, SELECTION} from "./StyledComponents";

export const Input = styled.input`
    background-color: transparent;
    width: 100%;
    height: 100%;
    color: ${PASTEL_PURPLE};
    border: none;
    border-radius: 5px;
    padding: 0 15px 0 15px;
    transition: all 0.2s ease-in-out;
    
    &:focus,
    &:hover {
        color: ${PASTEL_PINK};
    }

    &::placeholder {
        color: ${PASTEL_PURPLE + "80"};
    }

    &:focus::placeholder,
    &:hover::placeholder {
        color: ${PASTEL_PINK + "80"};
    }
`;

export const AuthInput = styled(Input)`
    background-color: ${SELECTION};
    font-size: ${REGULAR_FONT_SIZE};
    width: 300px;
    height: 45px;
`;

export const RangeInput = styled.input<RangeProps>`
    -webkit-appearance: none;
    accent-color: ${PASTEL_PURPLE};
    background: transparent;
    height: 12px;
    cursor: pointer;

    &::-webkit-slider-runnable-track {
        -webkit-appearance: none;
        border-radius: 2px;
        height: 4px;
        transition: all 0.1s ease-in-out;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        transition: all 0.1s ease-in-out;
        background-color: ${PASTEL_PURPLE};
        border-radius: ${props => props.thumbVisible ? "6px" : "0"};
        color: ${PASTEL_PURPLE};
        width: ${props => props.thumbVisible ? "12px" : "0"};
        height: ${props => props.thumbVisible ? "12px" : "0"};
        position: relative;
        top: 50%;
        transform: translate(0%, -50%);
    }
`;