import {createSlice} from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        key: "",
    },
    reducers: {
        setStorageKey: (state, action) => {
            state.key = action.payload;
            localStorage.setItem("key", action.payload)
        },
        clearStorageKey: (state) => {
            state.key = "";
            localStorage.removeItem("key")
        },
        setStorageUsername: (state, action) => {
            localStorage.setItem("username", action.payload)
        },
        clearStorageUsername: (state) => {
            localStorage.removeItem("username")
        }
    }
});

export const getStorageKey = (): string => localStorage.getItem("key")!;
export const getAuthorizationHeader = () => {
    if (!localStorage.getItem("key")) {
        return {
            "Accept": "application/json",
            "Content-Type": "application/json",
        };
    }

    return {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Token " + localStorage.getItem("key")
    }
};

export const {
    setStorageKey, clearStorageKey,
    setStorageUsername, clearStorageUsername
} = userSlice.actions;
export default userSlice.reducer
