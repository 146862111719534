import {APIException, get_artist, get_playlist, get_tokens, spotify_play} from "./api";
import {ContinuousPlaybackSource, ContinuousPlaybackSourceUtils, Platform, PLATFORMS, TrackBase, TrackExternal, TrackUtils} from "./types";
import {getIndex, getValidPlaybackExternal} from "./util/functions";
import {reduxAddToShuffleHistory, reduxClearShuffleHistory, reduxGetContinuousPlaybackSource, reduxGetCurrentTime, reduxGetExternalId, reduxGetPlatform, reduxGetPlaybackPlatforms, reduxGetPlaying, reduxGetPrevVolume, reduxGetRepeat, reduxGetShuffle, reduxGetShuffleHistory, reduxGetSpotifyToken, reduxGetTrack, reduxGetVolume, reduxSetAllTokens, reduxSetContinuousPlaybackSource, reduxSetCurrentTime, reduxSetExternalId, reduxSetPlatform, reduxSetPlaying, reduxSetPrevVolume, reduxSetTrack, reduxSetVolume} from "./redux-store/store";
import {sample} from "lodash";
import toast from "react-hot-toast";

type Controllers = {
    [key in Platform]: Controller;
};

export class MacroController {
    private controllers: Controllers = {
        "soundcloud": new SoundcloudController(),
        "spotify": new SpotifyController(),
        "youtube": new YoutubeController(),
    };

    constructor() {}

    getControllers() {
        return PLATFORMS.map((platform) => this.controllers[platform]);
    }

    async skipPrevious() {
        if (ContinuousPlaybackSourceUtils.isEmpty(reduxGetContinuousPlaybackSource())) {
            await this.replay();
            return;
        }

        await this.advanceBy(-1);
    }

    async skipNext() {
        await this.seek(TrackUtils.getDuration(reduxGetTrack(), reduxGetExternalId(), reduxGetPlatform()));
    }

    async onTrackEnd() {
        if (reduxGetRepeat() == "single") {
            await this.replay();
            return;
        }

        if (ContinuousPlaybackSourceUtils.isEmpty(reduxGetContinuousPlaybackSource())) {
            reduxSetPlaying(false);
            reduxSetCurrentTime(0);
            return;
        }

        await this.advanceBy(1);
    }

    // can only be called if continuousPlaybackSource is not empty ie track must be in db
    async advanceBy(numPos: number) {
        const shuffle = reduxGetShuffle();
        const repeat = reduxGetRepeat();
        const continuousPlaybackSource = reduxGetContinuousPlaybackSource();

        if (repeat == "single") {
            await this.replay();
            return;
        }

        // find next available track
        try {
            let tracks: TrackBase[];
            let newContinuousPlaybackSource: ContinuousPlaybackSource;
            if (continuousPlaybackSource.playlistId != null) {
                // playlist
                tracks = TrackUtils.sort(
                    (await get_playlist(continuousPlaybackSource.playlistId)).playlist.tracks,
                    continuousPlaybackSource.sortTrackBy,
                    continuousPlaybackSource.sortDirection
                );
                newContinuousPlaybackSource = {
                    playlistId: continuousPlaybackSource.playlistId,
                    artistId: null,
                    trackId: null,
                    sortTrackBy: continuousPlaybackSource.sortTrackBy,
                    sortDirection: continuousPlaybackSource.sortDirection,
                    cachedSourceName: continuousPlaybackSource.cachedSourceName,
                }
            } else {
                // artist
                tracks = TrackUtils.sort(
                    (await get_artist(continuousPlaybackSource.artistId!)).tracks,
                    continuousPlaybackSource.sortTrackBy,
                    continuousPlaybackSource.sortDirection
                );
                newContinuousPlaybackSource = {
                    playlistId: null,
                    artistId: continuousPlaybackSource.artistId,
                    trackId: null,
                    sortTrackBy: continuousPlaybackSource.sortTrackBy,
                    sortDirection: continuousPlaybackSource.sortDirection,
                    cachedSourceName: continuousPlaybackSource.cachedSourceName,
                }
            }

            const trackIds = tracks.map(track => track.track_id!);
            let success = false;
            let currentTrackId = continuousPlaybackSource.trackId!;
            while (!success) {
                if (shuffle) {
                    const shuffleHistory = reduxGetShuffleHistory();
                    const unplayedTrackIds = trackIds.filter(trackId => !shuffleHistory.includes(trackId));
                    const playedTrackIds = shuffleHistory.filter(trackId => trackIds.includes(trackId));

                    const trackIndex = getIndex(playedTrackIds, currentTrackId);
                    if ((numPos > 0 && trackIndex + numPos <= playedTrackIds.length - 1) ||
                        (numPos < 0 && trackIndex + numPos >= 0)) {
                        // go through shuffle history
                        const nextTrack = tracks.filter(track => track.track_id == playedTrackIds[trackIndex + numPos])[0];
                        // await (new Promise((resolve) => setTimeout(resolve, 1000)));
                        newContinuousPlaybackSource.trackId = nextTrack.track_id;

                        success = await this.macroTogglePlay(nextTrack, newContinuousPlaybackSource);
                        if (success) currentTrackId = nextTrack.track_id!;
                    } else if (numPos < 0) {
                        // numPos < 0 (trying to go backwards) but already at first track
                        await this.replay();
                        success = true;
                    } else {
                        // numPos > 0 (trying to go forwards) but already at last track: restart or stop
                        if (playedTrackIds.length == trackIds.length) {
                            // all tracks have been played
                            if (repeat == "multiple") {
                                // restart from random track
                                reduxClearShuffleHistory();
                                const nextTrack = tracks.filter(track => track.track_id == playedTrackIds[0])[0];
                                // await (new Promise((resolve) => setTimeout(resolve, 1000)));
                                newContinuousPlaybackSource.trackId = nextTrack.track_id;

                                success = await this.macroTogglePlay(nextTrack, newContinuousPlaybackSource, null, true);
                                if (success) currentTrackId = nextTrack.track_id!;
                            } else {
                                // end of continuous playback
                                reduxSetPlaying(false);
                                reduxSetCurrentTime(0);
                                success = true;
                            }
                        } else {
                            // grab a new random song to play
                            const randomTrackId = sample(unplayedTrackIds);
                            const nextTrack = tracks.filter(track => track.track_id == randomTrackId)[0];
                            // await (new Promise((resolve) => setTimeout(resolve, 1000)));
                            newContinuousPlaybackSource.trackId = nextTrack.track_id;

                            success = await this.macroTogglePlay(nextTrack, newContinuousPlaybackSource);
                            if (success) currentTrackId = nextTrack.track_id!;
                        }
                    }
                } else {
                    const trackIndex = getIndex(trackIds, currentTrackId);
                    if ((numPos > 0 && trackIndex + numPos <= tracks.length - 1) ||
                        (numPos < 0 && trackIndex + numPos >= 0) || repeat == "multiple") {
                        // can go to a new track
                        const nextTrack = tracks[(trackIndex + numPos + tracks.length) % tracks.length];
                        // await (new Promise((resolve) => setTimeout(resolve, 1000)));
                        newContinuousPlaybackSource.trackId = nextTrack.track_id;

                        success = await this.macroTogglePlay(nextTrack, newContinuousPlaybackSource, null, true);
                        currentTrackId = nextTrack.track_id!;
                    } else {
                        // end of continuous playback
                        reduxSetPlaying(false);
                        reduxSetCurrentTime(0);
                        success = true;
                    }
                }
            }
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    }

    // returns whether action was successful
    async macroTogglePlay(newTrack: TrackBase,
                          newContinuousPlaybackSource: ContinuousPlaybackSource = ContinuousPlaybackSourceUtils.empty(),
                          newExternal: TrackExternal | null = null,
                          forceNewTrack: boolean = false /* force reload of track, even if it could be the same */) {
        const track = reduxGetTrack();
        const playing = reduxGetPlaying();
        const shuffle = reduxGetShuffle();
        const externalId = reduxGetExternalId();
        const platform = reduxGetPlatform();
        const continuousPlaybackSource = reduxGetContinuousPlaybackSource();

        if (TrackUtils.isEmpty(track) && TrackUtils.isEmpty(newTrack)) return false;

        const validPlatforms = reduxGetPlaybackPlatforms();
        for (const validPlatform of validPlatforms) {
            if (validPlatforms.includes(validPlatform) && !this.initialised(validPlatform)) {
                toast.error("Playback not ready.");
                return false;
            }
        }

        let trackToPlay: TrackBase;
        if (forceNewTrack) {
            // force; fall through
            trackToPlay = newTrack;
        } else if (TrackUtils.isEmpty(newTrack) || TrackUtils.equals(track, newTrack)) {
            // same track
            if (newExternal == null || (externalId == newExternal.external_id && platform == newExternal.platform)) {
                // same track same external
                if (!playing) {
                    // not playing: resume
                    await this.resume();
                    return true;
                } else {
                    // playing: pause
                    await this.pause();
                    return true;
                }
            } else {
                // same track different external; fall through
                trackToPlay = track;
            }
        } else {
            // different track; fall through
            trackToPlay = newTrack;
        }

        // force OR different track OR same track different track (falling through from earlier ifs)
        let externalToPlay: TrackExternal;
        try {
            if (newExternal == null) {
                // shortest external with no restrictions (geo-block etc)
                externalToPlay = getValidPlaybackExternal(trackToPlay, validPlatforms);
            } else {
                // specific external
                if (!validPlatforms.includes(newExternal.platform)) {
                    throw new APIException("Could not play from specified platform for \"" + trackToPlay.name +
                        "\" (for Spotify playback, user must be logged in, connected to Spotify, and own Premium). " +
                        "To connect an external service, navigate to Settings > Connections.", "401");
                }
                externalToPlay = newExternal;
            }
        } catch (e) {
            if (e instanceof APIException) {
                toast.error(e.message);
            }
            return false;
        }

        if (platform != externalToPlay.platform) await this.pause(); // pause the old platform's player
        if (!ContinuousPlaybackSourceUtils.sourceEquals(continuousPlaybackSource, newContinuousPlaybackSource)) {
            reduxClearShuffleHistory();
        }

        await this.load(externalToPlay);
        reduxSetPlaying(true);
        reduxSetCurrentTime(0);
        reduxSetExternalId(externalToPlay.external_id);
        reduxSetPlatform(externalToPlay.platform);
        reduxSetTrack(trackToPlay);
        reduxSetContinuousPlaybackSource(newContinuousPlaybackSource);

        if (shuffle) reduxAddToShuffleHistory(trackToPlay.track_id!);
        return true;
    };

    async replay() {
        await this.seek(0);
        await this.resume();
    }

    async update() {
        const platform = reduxGetPlatform();
        if (platform != null) reduxSetCurrentTime(await this.controllers[platform].getPosition());
    }

    initialised(platform: Platform) {
        return this.controllers[platform].initialised;
    }

    async init(platform: Platform) {
        await this.controllers[platform].init(this);
    }

    async load(external: TrackExternal) {
        await this.controllers[external.platform].load(external);
    }

    async resume() {
        const platform = reduxGetPlatform();
        if (platform != null) {
            reduxSetPlaying(true);
            await this.controllers[platform].resume();
        }
    }

    async pause() {
        const platform = reduxGetPlatform();
        if (platform != null) {
            reduxSetPlaying(false);
            for (let controller of this.getControllers()) {
                // in case multiple platforms playing
                await controller.pause();
            }
        }
    }

    async setVolume(volume: number) {
        const platform = reduxGetPlatform();
        reduxSetPrevVolume(volume);
        reduxSetVolume(volume);
        if (platform != null) await this.controllers[platform].setVolume(volume);
    }

    async toggleMute() {
        const volume = reduxGetVolume();
        const prevVolume = reduxGetPrevVolume();
        const platform = reduxGetPlatform();

        const newVolume = volume == 0 ? prevVolume : 0;
        reduxSetPrevVolume(volume);
        reduxSetVolume(newVolume);
        if (platform != null) await this.controllers[platform].setVolume(newVolume);
    }

    async seek(time: number) {
        const platform = reduxGetPlatform();
        reduxSetCurrentTime(time);
        if (platform != null) await this.controllers[platform].seek(time);
    }

    async seekLeft() {
        const time = reduxGetCurrentTime();
        await this.seek(Math.max(0, time - 5000));
    }

    async seekRight() {
        const time = reduxGetCurrentTime();
        const duration = TrackUtils.getDuration(reduxGetTrack(), reduxGetExternalId(), reduxGetPlatform());
        await this.seek(Math.min(duration, time + 5000));
    }

}

// adapted from https://github.com/Tryops/track-playback-controller
export abstract class Controller { // abstract class
    protected tagId: string;
    protected player: any;
    public initialised = false;

    protected constructor(tagId: string) {
        this.tagId = tagId;
    }

    abstract init(macroController: MacroController): Promise<void>;
    async load(external: TrackExternal): Promise<void> {
        // load volume onto player from redux instead of updating every player when volume changes
        await this.setVolume(reduxGetVolume());
    }
    abstract resume(): Promise<void>;
    abstract pause(): Promise<void>;
    abstract setVolume(volume: number): Promise<void>;
    abstract seek(time: number): Promise<void>;
    abstract getPosition(): Promise<number>;
}

// https://developers.soundcloud.com/docs/api/html5-widget
export class SoundcloudController extends Controller {
    constructor() {
        super("soundcloud");
    }

    async init(macroController: MacroController): Promise<void> {
        if (this.initialised) return;
        return new Promise(((resolve: Function, reject: Function) => {
            const parent = document.getElementById(this.tagId)!;
            parent.innerHTML = `<iframe
                                    id="soundcloud_widget"
                                    src="https://w.soundcloud.com/player/?url=https://soundcloud.com/initiationmusic/wren&auto_play=false"
                                    allow="autoplay"
                                />`;
            this.player = window.SC.Widget(parent.firstElementChild);

            this.player.bind(window.SC.Widget.Events.READY, () => {
                console.log("SoundCloud player ready.");
                this.initialised = true;
                resolve();
            });
            this.player.bind(window.SC.Widget.Events.ERROR, () => {
                reject();
            });
            this.player.bind(window.SC.Widget.Events.FINISH, async() => {
                await macroController.onTrackEnd();
            });
        }).bind(this));
    }

    async load(external: TrackExternal) { // async function because loading takes some time and user should not be able to skip through playlist during this
        if (!this.initialised) return;
        const parent = document.getElementById(this.tagId)!;
        const iframe = document.getElementById("soundcloud_widget")!;
        iframe.remove();
        this.player.load(external.external_url, {
            callback: async() => {
                await super.load(external);
                await this.resume();
                // setTimeout(() => this.resume(), 2000); // if somehow still loading
            },
        });
        parent.append(iframe);
    }

    async resume() {
        if (!this.initialised) return;
        await this.player.play();
        // this.player.getCurrentSound((sound: any) => this.player.play())
    }

    async pause() {
        if (!this.initialised) return;
        this.player.pause();
    }

    async setVolume(volume: number) {
        if (!this.initialised) return;
        this.player.setVolume(volume * 45);
    }

    async seek(time: number) {
        if (!this.initialised) return;
        this.player.seekTo(time);
    }

    async getPosition(): Promise<number> {
        if (!this.initialised) return 0;
        return new Promise(resolve => {
            this.player.getPosition((position: number) => resolve(position));
        });
    }
}

export class SpotifyController extends Controller {
    private deviceId = "";
    private state: any = {};

    constructor() {
        super("spotify");
    }

    // page must be accessed via https (not just locahost/http), otherwise spotify drm does not work
    async waitForSpotifyPlaybackReady() { // because of weird behavior when spotify script is already loaded
        return new Promise(resolve => {
            if (window.Spotify) {
                resolve(window.Spotify);
            } else {
                window.onSpotifyWebPlaybackSDKReady = () => {
                    resolve(window.Spotify);
                };
            }
        });
    };

    async init(macroController: MacroController): Promise<void> {
        if (this.initialised) return;
        return new Promise(async (resolve, reject) => {
            await this.waitForSpotifyPlaybackReady();

            this.player = new window.Spotify.Player({
                name: "Amogustream: Spotify Player",
                getOAuthToken: async(cb: Function) => {
                    const tokens = await get_tokens();
                    reduxSetAllTokens(tokens);
                    cb(tokens.spotify);
                },
                volume: reduxGetVolume(),
            });

            // Error handling
            this.player.addListener("initialization_error", (e: any) => reject("Spotify Initialization: " + e.message));
            this.player.addListener("authentication_error", (e: any) => reject("Spotify Authentication: " + e.message));
            this.player.addListener("account_error", (e: any) => reject("Spotify Account: " + e.message));
            this.player.addListener("playback_error", () => {});

            // On stop: https://github.com/spotify/web-playback-sdk/issues/35
            this.player.addListener("player_state_changed", async(state: any) => {
                if (this.state != null && state.position == 0 &&
                    state.track_window.previous_tracks.find((x: any) => x.id == state.track_window.current_track.id) &&
                    !this.state.paused && state.paused) {
                    console.log("old state");
                    console.table(this.state);
                    console.log("new state");
                    console.table(state);
                    console.log("continuousPlaybackSource");
                    console.table(reduxGetContinuousPlaybackSource());
                    await macroController.onTrackEnd();
                    // TODO urgent for some reason being called twice when track stops, causing weird pause/play bugs sometimes
                }
                this.state = state;
            });

            // Ready
            this.player.addListener("ready", (p: any) => {
                console.log("Spotify device ready (id " + p.device_id + ").");
                this.deviceId = p.device_id;
                this.initialised = true;
                resolve();
            });

            // Not Ready
            this.player.addListener("not_ready", (p: any) => {
                console.log("Spotify device has gone offline (id " + p.device_id + ").");
                reject("Spotify device has gone offline (id " + p.device_id + ").");
            });

            this.player.connect();
        });
    }

    async load(external: TrackExternal) {
        if (!this.initialised) return;
        await super.load(external);
        await spotify_play(external.external_id, this.deviceId, reduxGetSpotifyToken());
    }

    async resume() {
        if (!this.initialised) return;
        await this.player.resume();
    }

    async pause() {
        if (!this.initialised) return;
        await this.player.pause();
    }

    async setVolume(volume: number) {
        if (!this.initialised) return;
        await this.player.setVolume(volume * .53);
    }

    async seek(time: number) {
        if (!this.initialised) return;
        await this.player.seek(time);
    }

    async getPosition() {
        if (!this.initialised) return 0;
        const newState = await this.player.getCurrentState();
        return newState != null ? newState.position : 0;
    }
}

// https://developers.google.com/youtube/iframe_api_reference
export class YoutubeController extends Controller {
    constructor() {
        super("youtube");
    }

    async init(macroController: MacroController): Promise<void> {
        if (this.initialised) return;
        return new Promise((resolve: Function, reject: Function) => {
            window.YT.ready(() => {
                this.player = new window.YT.Player(this.tagId, {
                    height: 1,
                    width: 1,
                    videoId: "ee13ycVYKh0",
                    events: {
                        "onReady": () => {
                            console.log("YouTube player ready.");
                            this.initialised = true;
                            resolve();
                        },
                        "onError": () => {
                            reject();
                        },
                        "onStateChange": async(event: any) => {
                            if (event.data == 0) {
                                await macroController.onTrackEnd();
                            }
                        }
                    }
                });
            });
        })
    }

    async load(external: TrackExternal) {
        if (!this.initialised) return;
        await super.load(external);
        this.player.loadVideoById(external.external_id);
    }

    async resume() {
        if (!this.initialised) return;
        this.player.unMute();
        this.player.playVideo();
    }

    async pause() {
        if (!this.initialised) return;
        this.player.pauseVideo();
    }

    async setVolume(volume: number) {
        if (!this.initialised) return;
        this.player.setVolume(volume * 100);
    }

    async seek(time: number) {
        if (!this.initialised) return;
        this.player.seekTo(time / 1000, true);
    }

    async getPosition(): Promise<number> {
        if (!this.initialised) return 0;
        return this.player.getCurrentTime() * 1000;
    }
}
