import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getStorageKey, setStorageKey, setStorageUsername} from "../redux-store/userSlice";
import {Navigate, useNavigate} from "react-router-dom";
import {APIException, get_tokens, login} from "../api";
import {setAllTokens} from "../redux-store/oAuth2Slice";
import styled from "styled-components";
import {setWindowProperties} from "../util/format";
import {AuthContentContainer, BodyContainer} from "../components/reusable/styled/Container";
import {AuthInput} from "../components/reusable/styled/Input";
import {Title} from "../components/reusable/styled/Title";
import toast from "react-hot-toast";

export const LoginForm = styled.form`
    grid-column: 1;
    grid-row: 3;
    width: 100%;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: auto 10px auto 25px auto;
`;

const Username = styled(AuthInput)`
    grid-row: 1;
`;

const Password = styled(AuthInput)`
    grid-row: 3;
`;

const Submit = styled(AuthInput)`
    font-weight: bold;
    grid-row: 5;
    transition: all 0.1s ease-in-out;
`;

export function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!!useSelector(getStorageKey)) {
        return <Navigate to="/"/>
    }

    setWindowProperties("Login");

    const onSubmit = async(e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        try {
            const data = await login(username, password);
            dispatch(setStorageKey(data.key));
            dispatch(setStorageUsername(username));
            setUsername(username);

            dispatch(setAllTokens(await get_tokens()));
            navigate("/");
        } catch (e) {
            if (e instanceof APIException) {
                toast.error(e.message);
                setPassword("");
            }
        }
    };

    return <BodyContainer>
        <AuthContentContainer>
            <Title>Login</Title>
            <LoginForm onSubmit={onSubmit}>
                <Username onChange={(e) => setUsername(e.target.value)} value={username} placeholder="Username"/>
                <Password type="password" onChange={(e) => setPassword(e.target.value)}
                          value={password} placeholder="Password"/>
                <Submit type="submit" value="Login"/>
            </LoginForm>
        </AuthContentContainer>
    </BodyContainer>
}