import * as ContextMenu from "@radix-ui/react-context-menu";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import React, {ForwardRefExoticComponent, RefAttributes} from "react";
import styled from "styled-components";
import {FOREGROUND, PASTEL_NAVY, SELECTION, SMALL_FONT_SIZE} from "../styled/StyledComponents";
import {pick} from "lodash";
import {ArtistBase, ArtistUtils, ExternalBase, ExternalModel, Model, PlaylistBase, TrackBase, TrackExternal, TrackUtils} from "../../../types";
import {onClickLink} from "../../../util/functions";
import {useNavigate} from "react-router-dom";
import {FRONTEND_URL} from "../../../constants";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {showAddArtistOverlay, showAddExternalOverlay, showAddTrackOverlay, showEditArtistOverlay, showEditTrackOverlay, showMergeOverlay, showMergePlaylistOverlay, showModifyPlaylistOverlay} from "../../../redux-store/overlaySlice";
import {getStorageKey} from "../../../redux-store/userSlice";
import {APIException, clone_playlist, delete_playlist, export_hastebin_playlist, follow_artist, get_track_id_by_external, remove_external, remove_track_playlist, unfollow_artist} from "../../../api";
import {getExternalLink} from "../../../util/format";

const Trigger = styled.div`
    &[data-state="closed"] {
        outline: none;
    }    
`;

const Content = styled.div`
    background-color: ${PASTEL_NAVY};
    border-radius: 5px;
    width: max-content;
    max-width: 400px;
    height: fit-content;
    max-height: 400px;
    padding: 5px;
    overflow: hidden;
`;

const Item = styled.div`
    color: ${FOREGROUND};
    font-size: ${SMALL_FONT_SIZE};
    text-align: left;
    width: 100%;
    height: auto;
    border-radius: 5px;
    padding: 8px 10px 8px 10px;
    position: relative;
    cursor: pointer;

    &[data-highlighted] {
        background-color: ${SELECTION}40;
        outline: none;
    }
`;

const Separator = styled.div`
    margin: 4px 8px 4px 8px;
    height: 1px;
    background-color: ${FOREGROUND}C0;
`;

interface MenuProps {
    menuType: typeof ContextMenu | typeof DropdownMenu;
    component: ForwardRefExoticComponent<RefAttributes<any>>;
    type: Model | "redirect";
    id?: string;
    urlOverride?: string;
    text?: string;
    search: string;
    items: any[][];
}

export function Menu(props: MenuProps) {
    const navigate = useNavigate();
    const menuType = props.menuType;
    const url = props.urlOverride ? props.urlOverride : (FRONTEND_URL + props.type + "/" + props.id);
    const searchType = props.type.toLowerCase().replace("external", "");

    let items: React.JSX.Element[] = [];
    for (const item of props.items) {
        items.push(<Separator as={menuType.Separator} key={items.length}/>);
        for (const [key, text, onClick] of item) {
            items.push(<Item as={menuType.Item} onClick={onClick} key={key}>{text}</Item>);
        }
    }

    const onOpenNewTab = () => {
        window.open(url);
        window.focus();
    };
    const onCopyLink = async() => {
        await navigator.clipboard.writeText(url);
        toast.success(`Copied link address to clipboard (${url}).`)
    };

    const onCopyId = async() => {
        await navigator.clipboard.writeText(props.id!);
        toast.success(`Copied ${props.type} ID to clipboard (${props.id!}).`)
    };

    const onCopyText = async() => {
        if (props.text != undefined) {
            await navigator.clipboard.writeText(props.text);
            toast.success(`Copied text to clipboard (${props.text}).`)
        }
    };

    const onSearchAmogustream = async(e: React.MouseEvent) => {
        onClickLink(e, navigate, "search?" + new URLSearchParams({q: props.search, type: props.type}).toString());
        // TODO if from overlay, close overlay eg search from right clicking a track external
    };

    const onSearchGoogle = async(e: React.MouseEvent) => {
        window.open("https://google.com/search?q=" + props.search)
    };

    return <menuType.Root>
        <Trigger as={menuType.Trigger} asChild>
            <props.component/>
        </Trigger>
        <menuType.Portal>
            <Content as={menuType.Content} align="start">
                <Item as={menuType.Item} onClick={onOpenNewTab}>Open link in new tab</Item>
                <Item as={menuType.Item} onClick={onCopyLink}>Copy link address</Item>
                {!["redirect", "artistExternal", "trackExternal", "externalPlaylist"].includes(props.type) && props.id != null &&
                    <Item as={menuType.Item} onClick={onCopyId}>{`Copy ${props.type} ID`}</Item>
                }
                {props.text && menuType == ContextMenu &&
                    <Item as={menuType.Item} onClick={onCopyText}>Copy text</Item>
                }
                {!["playlist", "user", "redirect", "externalPlaylist"].includes(props.type) && [
                    <Item as={menuType.Item} key="sa" onClick={onSearchAmogustream}>{`Search Amogustream for ${searchType}`}</Item>,
                    <Item as={menuType.Item} key="sg" onClick={onSearchGoogle}>{`Search Google for ${searchType}`}</Item>
                ]}
                {items}
            </Content>
        </menuType.Portal>
    </menuType.Root>
}

interface RedirectMenuProps {
    menuType: typeof ContextMenu | typeof DropdownMenu;
    component: ForwardRefExoticComponent<RefAttributes<any>>;
    url: string;
}

export function RedirectMenu(props: RedirectMenuProps) {
    return <Menu
        {...pick(props, ["menuType", "component"])}
        id=""
        type="redirect"
        urlOverride={props.url}
        search=""
        items={[]}
    />
}

interface PlaylistMenuProps {
    menuType: typeof ContextMenu | typeof DropdownMenu;
    component: ForwardRefExoticComponent<RefAttributes<any>>;
    playlist: PlaylistBase;
    isOwner: boolean;
    refreshState: () => void;
    navigate?: boolean; // whether to navigate
}

export function PlaylistMenu(props: PlaylistMenuProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedIn = !!useSelector(getStorageKey);
    const playlist = props.playlist;
    const modifyPayload = {playlist, refresh: props.refreshState};
    const mergePayload = {playlistId: playlist.playlist_id, refresh: props.refreshState};

    const onClonePlaylist = async() => {
        try {
            const playlist_id = await clone_playlist(playlist.playlist_id!);
            if (props.navigate) {
                navigate("/playlist/" + playlist_id);
            } else {
                props.refreshState();
            }
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    const onExportHastebinPlaylist = async() => {
        try {
            const url = await export_hastebin_playlist(playlist.playlist_id!);
            window.open(url);
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    const onDeletePlaylist = async() => {
        try {
            await delete_playlist(playlist.playlist_id!);
            toast.success(`Successfully deleted playlist "${playlist.name}" (${playlist.playlist_id}).`);
            if (props.navigate) {
                navigate("/library");
            } else {
                props.refreshState();
            }
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <Menu
        {...pick(props, ["menuType", "component"])}
        type="playlist"
        id={String(playlist.playlist_id)}
        text={playlist.name}
        search=""
        items={[
            props.isOwner ? [
                ["modify", "Modify playlist", () => dispatch(showModifyPlaylistOverlay(modifyPayload))],
                ["clone", "Clone playlist", onClonePlaylist],
                ["import", "Import all tracks from another playlist", () => dispatch(showMergePlaylistOverlay(mergePayload))],
                ["export", "Export playlist to Hastebin", onExportHastebinPlaylist],
                ["delete", "Delete playlist", onDeletePlaylist],
            ] :
                loggedIn ? [
                    ["clone", "Clone playlist", onClonePlaylist],
                    ["export", "Export playlist to Hastebin", onExportHastebinPlaylist],
                ] : [
                    ["export", "Export playlist to Hastebin", onExportHastebinPlaylist],
                ]
        ]}
    />
}

interface ArtistMenuProps {
    menuType: typeof ContextMenu | typeof DropdownMenu;
    component: ForwardRefExoticComponent<RefAttributes<any>>;
    artist: ArtistBase;
    isAdmin: boolean;
    refreshState: () => void;
    extraItems?: any[][];
}

export function ArtistMenu(props: ArtistMenuProps) {
    const dispatch = useDispatch();
    const loggedIn = !!useSelector(getStorageKey);
    const artist = props.artist;
    const extraItems = props.extraItems == undefined ? [[]] : props.extraItems;

    const mergePayload = {id: artist.artist_id, type: "artist", refresh: props.refreshState};
    const editArtistPayload = {artist, refresh: props.refreshState};

    const onFollow = async() => {
        try {
            const following = false; // TODO un/follow
            if (following) {
                await unfollow_artist(artist.artist_id!);
            } else {
                await follow_artist(artist.artist_id!);
            }
            props.refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    let items: any[][];
    if (props.isAdmin) {
        items = [
            [
                ["merge", "Merge matched artist", () => dispatch(showMergeOverlay(mergePayload))],
                ["edit", "Edit name", () => dispatch(showEditArtistOverlay(editArtistPayload))],
                ...extraItems[0],
            ]
        ]
    } else if (loggedIn) {
        // TODO un/follow
        items = [
            [
                ...extraItems[0],
            ]
        ]
    } else {
        items = []
    }

    return <Menu
        {...pick(props, ["menuType", "component"])}
        type="artist"
        id={String(artist.artist_id!)}
        text={artist.name}
        search={ArtistUtils.searchString(artist)}
        items={items}
    />
}

interface ExternalArtistMenuProps {
    menuType: typeof ContextMenu | typeof DropdownMenu;
    component: ForwardRefExoticComponent<RefAttributes<any>>;
    artist: ArtistBase;
}

export function ExternalArtistMenu(props: ExternalArtistMenuProps) {
    const artist = props.artist;
    const artistParams = new URLSearchParams({
        type: "artist",
        external_id: artist.externals[0].external_id,
        platform: artist.externals[0].platform,
    });

    return <Menu
        {...pick(props, ["menuType", "component"])}
        type="artist"
        text={artist.name}
        search={ArtistUtils.searchString(artist)}
        items={[]}
        urlOverride={"/external?" + artistParams.toString()}
    />
}

interface UserMenuProps {
    menuType: typeof ContextMenu | typeof DropdownMenu;
    component: ForwardRefExoticComponent<RefAttributes<any>>;
    id: string;
}

export function UserMenu(props: UserMenuProps) {
    return <Menu
        {...pick(props, ["menuType", "component", "id"])}
        type="user"
        text={props.id}
        search=""
        items={[]}
    />
}

export interface BaseTrackMenuProps {
    menuType: typeof ContextMenu | typeof DropdownMenu;
    component: ForwardRefExoticComponent<RefAttributes<any>>;
    track: TrackBase;
    isAdmin: boolean;
    refreshState: () => void;
}

interface TrackMenuProps extends BaseTrackMenuProps {
    extraItems?: any[][];
}

export function TrackMenu(props: TrackMenuProps) {
    const dispatch = useDispatch();
    const loggedIn = !!useSelector(getStorageKey);
    const track = props.track;
    const extraItems = props.extraItems == undefined ? [[]] : props.extraItems;

    const addTrackPayload = {track, refresh: props.refreshState};
    const mergePayload = {id: track.track_id, type: "track", refresh: props.refreshState};
    const editTrackPayload = {track, refresh: props.refreshState};
    const addExternalPayload = {trackId: track.track_id, refresh: props.refreshState};
    const addArtistPayload = {trackId: track.track_id, refresh: props.refreshState};

    let items: any[][];
    if (props.isAdmin) {
        items = [
            [
                ["add", "Add to playlist", () => dispatch(showAddTrackOverlay(addTrackPayload))],
                ...extraItems[0],
            ], [
                ["merge", "Merge matched track", () => dispatch(showMergeOverlay(mergePayload))],
                ["edit", "Edit name", () => dispatch(showEditTrackOverlay(editTrackPayload))],
                ["addExternal", "Add external", () => dispatch(showAddExternalOverlay(addExternalPayload))],
                ["addArtist", "Add artist", () => dispatch(showAddArtistOverlay(addArtistPayload))],
            ]
        ]
    } else if (loggedIn) {
        items = [
            [
                ["add", "Add to playlist", () => dispatch(showAddTrackOverlay(addTrackPayload))],
                ...extraItems[0],
            ]
        ]
    } else {
        items = [];
    }

    return <Menu
        {...pick(props, ["menuType", "component"])}
        type="track"
        id={String(track.track_id!)}
        text={track.name}
        search={TrackUtils.searchString(track)}
        items={items}
    />
}

interface ExternalTrackMenuProps {
    menuType: typeof ContextMenu | typeof DropdownMenu;
    component: ForwardRefExoticComponent<RefAttributes<any>>;
    track: TrackBase;
}

export function ExternalTrackMenu(props: ExternalTrackMenuProps) {
    const dispatch = useDispatch();
    const loggedIn = !!useSelector(getStorageKey);
    const track = props.track;

    const trackParams = new URLSearchParams({
        type: "track",
        external_id: track.externals[0].external_id,
        platform: track.externals[0].platform,
    });

    const onAddToPlaylist = async() => {
        const trackId = await get_track_id_by_external(track.externals[0].external_id, track.externals[0].platform);
        let actualTrack = {...track};
        actualTrack.track_id = trackId;
        const addTrackPayload = {track: actualTrack, refresh: () => {}};
        dispatch(showAddTrackOverlay(addTrackPayload));
    };

    let items: any[][];
    if (loggedIn) {
        items = [
            [
                ["add", "Add to playlist", onAddToPlaylist],
            ]
        ]
    } else {
        items = [];
    }

    return <Menu
        {...pick(props, ["menuType", "component"])}
        type="track"
        text={track.name}
        search={TrackUtils.searchString(track)}
        items={items}
        urlOverride={"/external?" + trackParams.toString()}
    />
}

interface PlaylistTrackMenuProps extends BaseTrackMenuProps {
    playlistId: number;
    isOwner: boolean;
}

export function PlaylistTrackMenu(props: PlaylistTrackMenuProps) {
    const onRemoveTrackPlaylist = async() => {
        try {
            await remove_track_playlist(props.playlistId, props.track.track_id!, null, null);
            props.refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    let extraItems: any[][];
    if (props.isOwner) {
        extraItems = [
            [
                ["remove", "Remove from this playlist", onRemoveTrackPlaylist],
            ]
        ]
    } else {
        extraItems = [
            []
        ];
    }

    return <TrackMenu
        {...pick(props, ["menuType", "component", "track", "isAdmin", "refreshState"])}
        extraItems={extraItems}
    />
}

interface ExternalMenuProps {
    menuType: typeof ContextMenu | typeof DropdownMenu;
    component: ForwardRefExoticComponent<RefAttributes<any>>;
    external: ExternalBase;
    isAdmin: boolean;
    search: string;
    type: ExternalModel;
    refreshState: () => void;
    items: any[][];
}

// refers specifically to an external (eg track external, which might have other functionality like being removed
// from a track, as opposed to redirectmenu which is just for links)
export function ExternalMenu(props: ExternalMenuProps) {
    const external = props.external;

    return <Menu
        {...pick(props, ["menuType", "component"])}
        type={props.type}
        id={external.external_id}
        search={props.search}
        items={props.items}
        urlOverride={getExternalLink(external, props.type)}
    />
}

interface TrackExternalMenuProps {
    menuType: typeof ContextMenu | typeof DropdownMenu;
    component: ForwardRefExoticComponent<RefAttributes<any>>;
    external: TrackExternal;
    track: TrackBase;
    isAdmin: boolean;
    refreshState: () => void;
}

export function TrackExternalMenu(props: TrackExternalMenuProps) {
    const loggedIn = !!useSelector(getStorageKey);
    const e = props.external;

    const onRemoveExternal = async() => {
        try {
            await remove_external(props.track.track_id!, e.external_id, e.platform);
            toast.success(`Successfully removed external (${getExternalLink(e, "trackExternal")}) from track "${props.track.name}" (${props.track.track_id}).`);
            props.refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    let items: any[][];
    if (props.isAdmin) {
        items = [
            [
                ["remove", "Remove external", onRemoveExternal],
            ]
        ]
    } else if (loggedIn) {
        items = []
    } else {
        items = [];
    }

    return <ExternalMenu
        {...pick(props, ["menuType", "component", "external", "isAdmin", "refreshState"])}
        items={items}
        search={TrackUtils.searchString(props.track)}
        type="trackExternal"
    />
}
