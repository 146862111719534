import React, {ForwardedRef} from "react";
import styled, {css} from "styled-components";
import {IncompletePlaylist, IncompletePlaylistExtendedIn, IncompletePlaylistExtendedIntersections, PlaylistUtils, TrackBase, TrackUtils} from "../../types";
import {FOREGROUND, LargeCardButton, LargeCardButtonContainer, LargeCardButtonImage, LargeCardButtonText, LargeCardImageLink, LargeCardImageLinkImage, LargeCardText, LargeCardWithButtons, PASTEL_PURPLE, PASTEL_RED, REGULAR_FONT_SIZE, SMALL_FONT_SIZE, Tooltip} from "../reusable/styled/StyledComponents";
import {STATIC} from "../../constants";
import {getDateFormatFromString} from "../../util/format";
import {add_track_playlist, APIException, clone_playlist, delete_playlist, export_hastebin_playlist, merge_playlist, remove_track_playlist} from "../../api";
import {Link} from "react-router-dom";
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";
import {showModifyPlaylistOverlay} from "../../redux-store/overlaySlice";
import * as ContextMenu from "@radix-ui/react-context-menu";
import {PlaylistMenu} from "../reusable/functional/Menu";

const PlaylistCardContainer = styled(LargeCardWithButtons)`
    grid-template-columns: auto 15px auto 5px auto 5px auto 1fr;
`;

const Name = styled(LargeCardText)`
    font-size: ${REGULAR_FONT_SIZE};
    font-weight: bold;
    
    grid-column: 3;
    grid-row: 1;

    &:hover {
        color: ${PASTEL_PURPLE};
    }
`;

const PublicImage = styled.img`
    width: 10px;
    height: 10px;
    
    grid-column: 5;
    grid-row: 1;
`;

const Description = styled(LargeCardText)`
    font-size: ${SMALL_FONT_SIZE};
    /*white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    grid-row: 2 / 4;*/
    
    grid-column: 3 / 11;
    grid-row: 2;
`;

const LeftButtonContainer = styled(LargeCardButtonContainer)`
    grid-column: 3 / 11;
    grid-row: 3;
    grid-template-columns: repeat(4, auto) 1fr;
`;

const CreationDate = styled.p`
    color: ${FOREGROUND};
    font-size: ${REGULAR_FONT_SIZE};
    justify-self: right;
    grid-column: 11;
    grid-row: 1;
`;

const LengthLeft = styled(LargeCardText)`
    font-size: ${SMALL_FONT_SIZE};
    grid-column: 3 / 6;
    grid-row: 3;
`;

const LengthRight = styled.p`
    color: ${FOREGROUND};
    font-size: ${SMALL_FONT_SIZE};
    justify-self: right;
    grid-column: 11;
    grid-row: 2;
`;

interface PlaylistCardProps {
    playlist: IncompletePlaylist;
    refreshState: () => void;
}

// TODO make this extend baseplaylistcard
export function PlaylistCard(props: PlaylistCardProps) {
    const dispatch = useDispatch();
    const playlist = props.playlist;
    const payload = {playlist: props.playlist, refresh: props.refreshState};

    const onClonePlaylist = async() => {
        try {
            await clone_playlist(playlist.playlist_id!);
            props.refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    const onDeletePlaylist = async() => {
        try {
            await delete_playlist(playlist.playlist_id!);
            toast.success(`Successfully deleted playlist "${playlist.name}" (${playlist.playlist_id}).`);
            props.refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    const onExportHastebinPlaylist = async() => {
        try {
            const url = await export_hastebin_playlist(playlist.playlist_id!);
            window.open(url);
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <PlaylistCardContainer>
        <PlaylistMenu
            menuType={ContextMenu}
            component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                <LargeCardImageLink to={"/playlist/" + playlist.playlist_id} {...props} ref={forwardedRef}>
                    <LargeCardImageLinkImage src={playlist.cover}/>
                </LargeCardImageLink>
            )}
            playlist={playlist}
            isOwner={true}
            refreshState={props.refreshState}
        />
        <PlaylistMenu
            menuType={ContextMenu}
            component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                <Name as={Link} to={"/playlist/" + playlist.playlist_id} {...props} ref={forwardedRef}>
                    {PlaylistUtils.formatName(playlist.name)}
                </Name>
            )}
            playlist={playlist}
            isOwner={true}
            refreshState={props.refreshState}
        />
        <Tooltip content={playlist.public ? "Public Playlist" : "Private Playlist"}>
            <PublicImage src={STATIC + (playlist.public ? "public.png" : "private.png")}/>
        </Tooltip>
        <Description>{PlaylistUtils.formatDescription(playlist.description)}</Description>

        <LeftButtonContainer>
            <LargeCardButton onClick={() => dispatch(showModifyPlaylistOverlay(payload))}>
                <LargeCardButtonImage src={STATIC + "edit.png"}/>
                <LargeCardButtonText>Modify playlist</LargeCardButtonText>
            </LargeCardButton>

           <LargeCardButton onClick={onClonePlaylist}>
                <LargeCardButtonImage src={STATIC + "clone.png"}/>
                <LargeCardButtonText>Clone playlist</LargeCardButtonText>
            </LargeCardButton>

           <LargeCardButton onClick={onExportHastebinPlaylist}>
                <LargeCardButtonImage src={STATIC + "paste.png"}/>
                <LargeCardButtonText>Export playlist to Hastebin</LargeCardButtonText>
            </LargeCardButton>

           <LargeCardButton onClick={onDeletePlaylist}>
                <LargeCardButtonImage src={STATIC + "delete.png"}/>
                <LargeCardButtonText>Delete playlist</LargeCardButtonText>
            </LargeCardButton>
        </LeftButtonContainer>

        <CreationDate>{getDateFormatFromString(playlist.creation_date)}</CreationDate>
        <LengthRight>{PlaylistUtils.formatNumTracks(playlist.tracks.length)}</LengthRight>
    </PlaylistCardContainer>
}

const BasePlaylistCardContainer = styled(LargeCardWithButtons)`
    grid-template-columns: auto 15px auto 5px auto auto 5px 1fr;
`;

const ButtonContainer = styled(LargeCardButtonContainer)`
    grid-column: 3 / 11;
    grid-row: 3;
    grid-template-columns: repeat(2, auto) 1fr;
`;

interface BasePlaylistCardProps {
    playlist: IncompletePlaylist;
    buttons: React.JSX.Element[];
    refreshState: () => void;
}

export function BasePlaylistCard(props: BasePlaylistCardProps) {
    const playlist = props.playlist;
    return <BasePlaylistCardContainer>
        <PlaylistMenu
            menuType={ContextMenu}
            component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                <LargeCardImageLink to={"/playlist/" + playlist.playlist_id} {...props} ref={forwardedRef}>
                    <LargeCardImageLinkImage src={playlist.cover}/>
                </LargeCardImageLink>
            )}
            playlist={playlist}
            isOwner={true}
            refreshState={props.refreshState}
        />
        <PlaylistMenu
            menuType={ContextMenu}
            component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                <Name as={Link} to={"/playlist/" + playlist.playlist_id} {...props} ref={forwardedRef}>
                    {PlaylistUtils.formatName(playlist.name)}
                </Name>
            )}
            playlist={playlist}
            isOwner={true}
            refreshState={props.refreshState}
        />
        <PublicImage src={STATIC + (playlist.public ? "public.png" : "private.png")}/>
        <Description>{PlaylistUtils.formatDescription(playlist.description)}</Description>
        <ButtonContainer>{props.buttons}</ButtonContainer>

        <CreationDate>{getDateFormatFromString(playlist.creation_date)}</CreationDate>
        <LengthRight>{PlaylistUtils.formatNumTracks(playlist.tracks.length)}</LengthRight>
    </BasePlaylistCardContainer>
}

interface AddRemoveButtonProps {
    inPlaylist: boolean;
}

const AddRemoveButton = styled(LargeCardButton)<AddRemoveButtonProps>`
    ${props => props.inPlaylist && css`
        border: 1px solid ${PASTEL_PURPLE}C0;
        background-color: ${PASTEL_PURPLE}C0;
        
        &:hover {
            border: 1px solid ${PASTEL_RED}C0;
            background-color: ${PASTEL_RED}C0;
        }
    `}
`;

interface AddTrackPlaylistCardProps {
    playlist: IncompletePlaylistExtendedIn;
    track: TrackBase;
    setVisibility: (newVisibility: boolean) => void;
    refreshState: () => void;
}

// TODO when searching in the backend, for each song, fetch its entry from the db, then merge with any others
//  maybe can go to playlist from here by clicking on name?
export function AddTrackPlaylistCard(props: AddTrackPlaylistCardProps) {
    const playlist = props.playlist;
    const track = props.track;

    const addTrack = async() => {
        try {
            const [externalId, platform] = TrackUtils.getExternalIdAndPlatform(track);
            await add_track_playlist(playlist.playlist_id!, externalId, platform);
            props.refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    const removeTrack = async() => {
        if (track.track_id != null) {
            await remove_track_playlist(playlist.playlist_id!, track.track_id, null, null);
        } else {
            const [externalId, platform] = TrackUtils.getExternalIdAndPlatform(track);
            await remove_track_playlist(playlist.playlist_id!, null, externalId, platform);
        }
        props.refreshState();
    };

    const buttons = [
        <AddRemoveButton onClick={playlist.in ? removeTrack : addTrack} inPlaylist={playlist.in} key="addRemove">
            <LargeCardButtonImage src={STATIC + (playlist.in ? "delete.png" : "add_to_playlist.png")}/>
            <LargeCardButtonText>{playlist.in ? "Remove from playlist" : "Add to playlist"}</LargeCardButtonText>
        </AddRemoveButton>
    ];

    return <BasePlaylistCard playlist={playlist} buttons={buttons} refreshState={props.refreshState}/>
}

interface MergePlaylistCardProps {
    srcPlaylist: IncompletePlaylistExtendedIntersections;
    destPlaylistId: number;
    setVisibility: (newVisibility: boolean) => void;
    refreshState: () => void; // to update the playlist page
}

export function MergePlaylistCard(props: MergePlaylistCardProps) {
    const srcPlaylist = props.srcPlaylist;

    const onMerge = async() => {
        try {
            await merge_playlist(srcPlaylist.playlist_id!, props.destPlaylistId);
            props.refreshState();
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    const buttons = [
        <LargeCardButton onClick={onMerge} key="import">
            <LargeCardButtonImage src={STATIC + "import.png"}/>
            <LargeCardButtonText>
                {`Import ${PlaylistUtils.formatNumTracks(srcPlaylist.tracks.length - srcPlaylist.intersections,false)}`}
            </LargeCardButtonText>
        </LargeCardButton>,
        <LargeCardButtonText>
            {`${PlaylistUtils.formatNumTracks(srcPlaylist.intersections, false)} already in this playlist`}
        </LargeCardButtonText>
    ];

    return <BasePlaylistCard playlist={srcPlaylist} buttons={buttons} refreshState={props.refreshState}/>
}