import styled from "styled-components";
import {TitleProps} from "../../../types";
import {FOREGROUND, PASTEL_PINK, PASTEL_PURPLE, SUBTITLE_FONT_SIZE, TITLE_FONT_SIZE} from "./StyledComponents";

export const Title = styled.p<TitleProps>`
    justify-self: left;
    line-height: ${TITLE_FONT_SIZE};
    font-size: ${TITLE_FONT_SIZE};
    font-weight: bold;
    color: ${FOREGROUND};
    transition: all 0.2s ease-in-out;
    position: relative;
    z-index: 5;

    grid-column: 1 / span 9999;
    grid-row: 1;

    &::before {
        z-index: -1;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        color: ${PASTEL_PINK};
        content: "${props => String(props.children)}";
        transition: top 0.2s ease-in-out, left 0.2s ease-in-out, opacity 0s linear 0.2s;
    }

    &::after {
        z-index: 1;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        color: ${PASTEL_PURPLE};
        content: "${props => String(props.children)}";
        transition: top 0.2s ease-in-out, left 0.2s ease-in-out, opacity 0s linear 0.2s;
    }

    &:hover {
        &::before {
            opacity: 1;
            top: -10px;
            left: -10px;
            transition: top 0.2s ease-in-out, left 0.2s ease-in-out, opacity 0s linear 0s;
        }

        &::after {
            opacity: 1;
            top: 10px;
            left: 10px;
            transition: top 0.2s ease-in-out, left 0.2s ease-in-out, opacity 0s linear 0s;
        }
    }
`;

export const Subtitle = styled(Title)`
    line-height: ${SUBTITLE_FONT_SIZE};
    font-size: ${SUBTITLE_FONT_SIZE};
    grid-row: 3;
    &:hover {
        text-shadow: none;
    }
`;

export const OverlayTitle = styled(Title)`
    line-height: ${SUBTITLE_FONT_SIZE};
    font-size: ${SUBTITLE_FONT_SIZE};
`;