import {Album, ArtistBase, ArtistComposedTracksIsFollowing, ArtistExtendedIsFollowing, ArtistUtils, CompletePlaylistComposedIsOwner, ExternalPlaylist, IncompletePlaylist, IncompletePlaylistExtendedIn, IncompletePlaylistExtendedIntersections, Platform, Tokens, TrackBase, TrackUtils, UserComposedPlaylists} from "./types";
import Cookies from "js-cookie";
import {DUMMY} from "./constants";

const {getAuthorizationHeader} = require("./redux-store/userSlice");
const {API_URL} = require("./constants");

export class APIException extends Error {
    constructor(message: string, errorCode: number | string) {
        super("Error (" + errorCode + "): " + message);
        this.name = this.constructor.name;
    }
}

/* AUTHENTICATION */
export async function login(username: string, password: string) {
    const headers = {
        "X-CSRFToken": Cookies.get("csrftoken") || "",
        "Content-Type": "application/json",
        "credentials": "include",
    };

    const res = await fetch(API_URL + "auth/login/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            username,
            password
        }),
    });

    if (res.status != 200) {
        const data = await res.json();
        throw new APIException(data["non_field_errors"][0], res.status);
    }

    return await res.json();
}

export async function register(username: string, password1: string, password2: string) {
    const headers = {
        "X-CSRFToken": Cookies.get("csrftoken") || "",
        "Content-Type": "application/json",
        "credentials": "include",
    };

    const res = await fetch(API_URL + "auth/registration/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            username,
            password1,
            password2,
        })
    });

    if (res.status != 201) {
        const data = await res.json();
        if (data["username"]) {
            throw new APIException(data["username"][0], res.status);
        } else if (data["non_field_errors"]) {
            throw new APIException(data["non_field_errors"][0], res.status);
        } else if (data["password1"]) {
            throw new APIException(data["password1"][0], res.status);
        } else if (data["password2"]) {
            throw new APIException(data["password2"][0], res.status);
        } else {
            throw new APIException((await res.json()).detail, res.status);
        }
    }

    return await res.json();
}

export async function logout() {
    const headers = {
        "X-CSRFToken": Cookies.get("csrftoken") || "",
        "Content-Type": "application/json",
    };

    const res = await fetch(API_URL + "auth/logout/", {
        headers: headers,
        method: "POST",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function is_admin(): Promise<boolean> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "is_admin/", {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).is_admin;
}


export async function soundcloud_callback(client_id: string) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "soundcloud_callback/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({client_id})
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function spotify_callback(code: string) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "spotify_callback/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({code})
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function youtube_callback(code: string) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "youtube_callback/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({code})
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function get_tokens(): Promise<Tokens> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "get_tokens/", {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).tokens;
}

export async function soundcloud_remove_token() {
    const headers = getAuthorizationHeader();

    const res =await fetch(API_URL + "soundcloud_remove_token/", {
        headers: headers,
        method: "POST",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function spotify_remove_token() {
    const headers = getAuthorizationHeader();

    const res =await fetch(API_URL + "spotify_remove_token/", {
        headers: headers,
        method: "POST",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function youtube_remove_token() {
    const headers = getAuthorizationHeader();

    const res =await fetch(API_URL + "youtube_remove_token/", {
        headers: headers,
        method: "POST",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

/* SEARCH */
export async function search_platforms(): Promise<Platform[]> {
    const headers = getAuthorizationHeader();
    const res = await fetch(API_URL + "search_platforms/", {
        headers: headers,
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).platforms;
}

export async function search(q: string, platforms: string, type: string, page: number) {
    const headers = getAuthorizationHeader();

    const params = new URLSearchParams({
        q,
        platforms,
        type,
        page: page.toString(),
    });

    const res = await fetch(API_URL + "search?" + params.toString(), {
        headers: headers,
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    const results = await res.json();
    let tracks: TrackBase[] = [];
    for (const track of results.tracks) {
        tracks.push(TrackUtils.fromJson(track));
    }
    let albums: Album[] = []; // TODO

    let artists: ArtistExtendedIsFollowing[] = [];
    for (const artist of results.artists) {
        artists.push({
            ...ArtistUtils.fromJson(artist),
            is_following: artist.is_following,
        });
    }

    return {
        tracks,
        albums,
        artists,
    };
}

/* TICKETS */
export async function create_ticket(name: string, description: string) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "create_ticket/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            name,
            description,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

/* LIBRARY */
export async function get_external_playlists(): Promise<ExternalPlaylist[]> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "get_external_playlists/", {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).playlists;
}

export async function import_personal_playlist(external_id: string, platform: Platform) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "import_personal_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            external_id,
            platform,
        })
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).playlist_id;
}

export async function import_url_playlist(url: string) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "import_url_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({url})
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).playlist_id;
}

export async function get_playlist(playlist_id: number): Promise<CompletePlaylistComposedIsOwner> {
    const headers = getAuthorizationHeader();

    const res = (await fetch(API_URL + "get_playlist?playlist_id=" + playlist_id, {
        headers: headers,
        method: "GET",
    }));

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return await res.json();
}

export async function get_playlists(): Promise<IncompletePlaylist[]> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "get_playlists/", {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    let playlists = (await res.json()).playlists;
    playlists.sort((a: IncompletePlaylist, b: IncompletePlaylist) => {
        return a.playlist_id! < b.playlist_id! ? -1 : 1;
    });
    return playlists;
}

export async function in_playlists(trackId: number | null, external_id: string | null, platform: Platform):
    Promise<IncompletePlaylistExtendedIn[]> {
    const headers = getAuthorizationHeader();

    type p = {
        track_id?: string,
        external_id?: string,
        platform?: string,
    }

    let params: p = {};
    if (trackId != null) params.track_id = trackId.toString();
    if (external_id != null) params.external_id = external_id;
    if (platform != null) params.platform = platform;

    const res = await fetch(API_URL + "in_playlists?" + new URLSearchParams(params).toString(), {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    let playlists = (await res.json()).playlists;
    playlists.sort((a: IncompletePlaylistExtendedIn, b: IncompletePlaylistExtendedIn) => {
        return a.playlist_id! < b.playlist_id! ? -1 : 1;
    });
    const inPlaylists = [...playlists].filter((a: IncompletePlaylistExtendedIn) => a.in);
    const outPlaylists = [...playlists].filter((a: IncompletePlaylistExtendedIn) => !a.in);
    return [...inPlaylists, ...outPlaylists];
}

export async function create_playlist(name: string = "A New Playlist",
                                      description: string = "A playlist of wonderful music.",
                                      creation_date: Date = new Date(),
                                      cover: string = DUMMY,
                                      isPublic: boolean = false): Promise<number> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "create_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            name,
            description,
            creation_date,
            cover,
            public: isPublic,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).playlist_id;
}

export async function modify_playlist(playlist_id: number, name: string | null, description: string | null,
                                     cover:string | null, isPublic: boolean | null) {
    const headers = getAuthorizationHeader();

    type playlist = {
        playlist_id: number,
        name?: string,
        description?: string,
        cover?: string,
        public?: boolean,
    }
    let body: playlist = {playlist_id: playlist_id};
    if (name != null) body.name = name;
    if (description != null) body.description = description;
    if (cover != null) body.cover = cover;
    if (isPublic != null) body.public = isPublic;

    const res = await fetch(API_URL + "modify_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify(body),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function reorder_playlist(playlist_id: number, track_ids: number[]) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "reorder_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            playlist_id,
            track_ids,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function add_track_playlist(playlist_id: number, external_id: string, platform: Platform) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "add_track_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            playlist_id,
            external_id,
            platform,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function add_url_track_playlist(playlist_id: number, url: string) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "add_url_track_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            playlist_id,
            url,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function remove_track_playlist(playlist_id: number, track_id: number | null,
                                            external_id: string | null, platform: Platform | null) {
    const headers = getAuthorizationHeader();

    type playlistTrack = {
        playlist_id: number,
        track_id?: number,
        external_id?: string,
        platform?: Platform,
    }
    let body: playlistTrack = {playlist_id: playlist_id};
    if (track_id != null) body.track_id = track_id;
    if (external_id != null) body.external_id = external_id;
    if (platform != null) body.platform = platform;

    const res = await fetch(API_URL + "remove_track_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify(body)
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function clone_playlist(playlist_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "clone_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({playlist_id}),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).playlist_id;
}

export async function playlist_intersections(playlist_id: number): Promise<IncompletePlaylistExtendedIntersections[]> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "playlist_intersections?playlist_id=" + playlist_id, {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).playlists;
}

export async function merge_playlist(src_playlist_id: number, dest_playlist_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "merge_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            src_playlist_id,
            dest_playlist_id,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function delete_playlist(playlist_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "delete_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({playlist_id}),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function export_hastebin_playlist(playlist_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "export_hastebin_playlist?playlist_id=" + playlist_id, {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).url;
}

export async function import_hastebin_playlist(url: string) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "import_hastebin_playlist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({url}),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).playlist_id;
}

export async function get_artist(artist_id: number): Promise<ArtistComposedTracksIsFollowing> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "get_artist?artist_id=" + artist_id, {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json());
}

export async function get_artist_id_by_external(external_id: string, platform: Platform): Promise<number> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "get_artist_id_by_external/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            external_id,
            platform,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).artist_id;
}

export async function refresh_artist(artist_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "refresh_artist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({artist_id}),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function get_followed_artists(): Promise<ArtistBase[]> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "get_followed_artists/", {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).artists;
}

export async function follow_artist(artist_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "follow_artist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({artist_id}),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function unfollow_artist(artist_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "unfollow_artist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({artist_id}),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function import_external_subscriptions(platform: Platform) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "import_external_subscriptions/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({platform}),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function get_track(track_id: number): Promise<TrackBase> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "get_track?track_id=" + track_id, {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).track;
}

export async function get_track_id_by_external(external_id: string, platform: Platform): Promise<number> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "get_track_id_by_external/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            external_id,
            platform,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).track_id;
}

export async function refresh_track(track_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "refresh_track/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({track_id}),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function get_user(username: string): Promise<UserComposedPlaylists> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "get_user?username=" + username, {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json());
}

export async function playback_platforms(): Promise<Platform[]> {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "playback_platforms/", {
        headers: headers,
        method: "GET",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);

    return (await res.json()).platforms;
}

/* MEDIA PLAYBACK */
/*export async function soundcloud_play(soundcloudId: string, clientId: string) {
    const cors = "https://api.codetabs.com/v1/proxy?quest=";

    const res1 = await fetch(cors + "https://api-v2.soundcloud.com/tracks/" + soundcloudId +
        "?client_id=" + clientId, {method: "GET"});
    if (res1.status != 200) throw new APIException((await res1.json()).detail, res1.status);
    const track = await res1.json();
    const url = track["media"]["transcodings"][0]["url"];

    const res2 = await fetch(cors + url + "?client_id=" + clientId, {method: "GET"});
    if (res2.status != 200) throw new APIException((await res2.json()).detail, res2.status);
    const src = await res2.json();
    const streamUrl = src["url"];

    console.log(streamUrl);
}*/

export async function soundcloud_url(soundcloudId: string, clientId: string) {
    const cors = "https://api.codetabs.com/v1/proxy?quest=";

    const res = await fetch(cors + "https://api-v2.soundcloud.com/tracks/" + soundcloudId +
        "?client_id=" + clientId, {method: "GET"});
    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
    return (await res.json())["permalink_url"];
}

export async function spotify_play(spotifyId: string, spotifyDeviceId: string, spotifyToken: string) {
    const res = await fetch("https://api.spotify.com/v1/me/player/play?device_id=" + spotifyDeviceId, {
        method: "PUT",
        body: JSON.stringify({
            uris: ["spotify:track:" + spotifyId],
        }),
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + spotifyToken,
        },
    });

    if (res.status != 202) throw new APIException((await res.json()).detail, res.status);
}

export async function merge_matched_artists(src_artist_id: number, dest_artist_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "merge_matched_artists/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            src_artist_id,
            dest_artist_id,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function merge_matched_albums(src_album_id: number, dest_album_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "merge_matched_albums/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            src_album_id,
            dest_album_id,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function merge_matched_tracks(src_track_id: number, dest_track_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "merge_matched_tracks/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            src_track_id,
            dest_track_id,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function edit_track(track_id: number, name: string) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "edit_track/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            track_id,
            name,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function edit_artist(artist_id: number, name: string) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "edit_artist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            artist_id,
            name,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function add_external(track_id: number, url: string) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "add_external/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            track_id,
            url,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function remove_external(track_id: number, external_id: string, platform: Platform) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "remove_external/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            track_id,
            external_id,
            platform,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function clear_duplicates() {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "clear_duplicates/", {
        headers: headers,
        method: "POST",
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function add_track_artist(track_id: number, artist_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "add_track_artist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            track_id,
            artist_id,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}

export async function remove_track_artist(track_id: number, artist_id: number) {
    const headers = getAuthorizationHeader();

    const res = await fetch(API_URL + "remove_track_artist/", {
        headers: headers,
        method: "POST",
        body: JSON.stringify({
            track_id,
            artist_id,
        }),
    });

    if (res.status != 200) throw new APIException((await res.json()).detail, res.status);
}