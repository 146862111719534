import React, {ForwardedRef, useContext, useState} from "react";
import {globalContext} from "../../index";
import {ArtistExtendedIsFollowing, ArtistUtils, CanPlayProps, ExternalUtils, HoverProps, TrackBase, TrackUtils} from "../../types";
import {getDateFormatFromString, getTimeFormat} from "../../util/format";
import {STATIC} from "../../constants";
import styled, {css} from "styled-components";
import {FOREGROUND, LargeCardButton, LargeCardButtonContainer, LargeCardButtonImage, LargeCardButtonText, LargeCardImage, LargeCardText, LargeCardWithButtons, PASTEL_PURPLE, REGULAR_FONT_SIZE, SMALL_FONT_SIZE, Tooltip, WHITE_TO_GRAY} from "../reusable/styled/StyledComponents";
import {useDispatch, useSelector} from "react-redux";
import {hasValidPlaybackPlatform} from "../../util/functions";
import {getStorageKey} from "../../redux-store/userSlice";
import {APIException, follow_artist, get_artist, get_artist_id_by_external, get_track_id_by_external, unfollow_artist} from "../../api";
import {getPlaybackPlatforms} from "../../redux-store/oAuth2Slice";
import {getPlatform, getPlaying, getTrack} from "../../redux-store/playbackSlice";
import toast from "react-hot-toast";
import {showAddTrackOverlay, showTrackExternalsOverlay} from "../../redux-store/overlaySlice";
import {Link} from "react-router-dom";
import * as ContextMenu from "@radix-ui/react-context-menu";
import {ArtistMenu, ExternalArtistMenu, ExternalTrackMenu} from "../reusable/functional/Menu";

/**
 * TODO
 *  - URGENT search backend route can return id for tracks with the id, then can check here and use them directly if applicable
 *  - clicking on image / name goes to the track; same hover mechanics as playlist cards
 *  - hoverplay tooltip on cover
 */

const SearchTrackCardContainer = styled(LargeCardWithButtons)`
    grid-template-columns: auto 15px auto 1fr;
`;

const Name = styled(LargeCardText)`
    font-size: ${REGULAR_FONT_SIZE};
    font-weight: bold;
    cursor: pointer;
    grid-column: 3 / 5;
    grid-row: 1;
    transition: all 0.1s ease-in-out;

    &:hover {
        color: ${PASTEL_PURPLE};
    }
`;

// TODO remove side scrolling in favour of something else idk what yet
const Artists = styled.div`
    width: 100%;

    grid-column: 3;
    grid-row: 2;

    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(9999, auto) 1fr;

    overflow-x: auto;
    overflow-y: hidden;
    
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track-piece:start,
    &::-webkit-scrollbar-track-piece:end {
        visibility: hidden;
    }
    
    &:hover::-webkit-scrollbar,
    &:hover::-webkit-scrollbar-thumb,
    &:hover::-webkit-scrollbar-track-piece:start,
    &:hover::-webkit-scrollbar-track-piece:end {
        visibility: visible;
    }
`;

const ArtistSeparator = styled(LargeCardText)`
    min-width: max-content;
    font-size: ${SMALL_FONT_SIZE};
    white-space: pre;
`;

const Artist = styled(LargeCardText)`
    min-width: max-content;
    font-size: ${SMALL_FONT_SIZE};
    white-space: pre;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
        color: ${PASTEL_PURPLE};
    }
`;

const LeftButtonContainer = styled(LargeCardButtonContainer)`
    grid-column: 3 / 5;
    grid-row: 3;
    grid-template-columns: repeat(2, auto) 1fr;
`;

const TogglePlayButton = styled(LargeCardButton)<CanPlayProps>`
    &:hover {
        border: 1px solid ${FOREGROUND};
        background-color: transparent;
    }
    
    ${props => props.canPlay ? css`
        &:hover {
            border: 1px solid transparent;
            background-color: ${PASTEL_PURPLE};
        }
    ` : css`
        filter: ${WHITE_TO_GRAY};
        cursor: default !important;
    `}
`;

const ReleaseDate = styled.p`
    color: ${FOREGROUND};
    font-size: ${REGULAR_FONT_SIZE};
    justify-self: right;
    grid-column: 5;
    grid-row: 1;
`;

const Duration = styled.p`
    color: ${FOREGROUND};
    font-size: ${SMALL_FONT_SIZE};
    justify-self: right;
    grid-column: 5;
    grid-row: 2;
`;

const Externals = styled.div`
    cursor: pointer;
    grid-column: 5;
    grid-row: 3;
    justify-self: right;

    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    transition: transform 0.1s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

interface SearchTrackCardProps {
    track: TrackBase;
    isAdmin: boolean;
}

export function SearchTrackCard(props: SearchTrackCardProps) {
    const {macroController} = useContext(globalContext);
    const dispatch = useDispatch();
    const [playHover, setPlayHover] = useState(false);
    const track = props.track;
    const loggedIn = !!useSelector(getStorageKey);
    const playingTrack = useSelector(getTrack);
    const playing = useSelector(getPlaying);
    const currentlyPlaying = TrackUtils.currentlyPlaying(track, playingTrack, playing);
    const platform = useSelector(getPlatform);

    const validPlatforms = useSelector(getPlaybackPlatforms);
    const canPlay = hasValidPlaybackPlatform(track, validPlatforms);

    const addTrackPayload = {track, refresh: () => {}};

    const onPlay = async() => {
        await macroController.macroTogglePlay(track);
    };

    let artists: React.JSX.Element[] = [];
    for (const artist of track.artists) {
        const key = artist.artist_id;
        const artistParams = new URLSearchParams({
            type: "artist",
            external_id: artist.externals[0].external_id,
            platform: artist.externals[0].platform,
        });
        artists.push(
            <ArtistMenu
                menuType={ContextMenu}
                component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                    <Artist as={Link} to={"/external?" + artistParams.toString()} {...props} ref={forwardedRef}>{artist.name}</Artist>
                )}
                artist={artist}
                isAdmin={props.isAdmin}
                refreshState={() => {}}
                key={key}
            />,
            <ArtistSeparator key={key + "separator"}>
                {", "}
            </ArtistSeparator>
        );
    }
    artists.pop();

    const trackParams = new URLSearchParams({
        type: "track",
        external_id: track.externals[0].external_id,
        platform: track.externals[0].platform,
    });

    const onAddToPlaylist = async() => {
        const trackId = await get_track_id_by_external(track.externals[0].external_id, track.externals[0].platform);
        let actualTrack = {...track};
        actualTrack.track_id = trackId;
        const addTrackPayload = {track: actualTrack, refresh: () => {}};
        dispatch(showAddTrackOverlay(addTrackPayload));
    };

    return <SearchTrackCardContainer>
        <LargeCardImage src={TrackUtils.getCover(track)}/>
        <ExternalTrackMenu
            menuType={ContextMenu}
            component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                <Name as={Link} to={"/external?" + trackParams.toString()} {...props} ref={forwardedRef}>{track.name}</Name>
            )}
            track={track}
        />
        <Artists>{artists}</Artists>
        <LeftButtonContainer>
            <Tooltip content="No valid playback source." visible={!canPlay && playHover}>
                <TogglePlayButton onClick={onPlay} canPlay={canPlay}
                                  onMouseEnter={() => setPlayHover(true)}
                                  onMouseLeave={() => setPlayHover(false)}>
                    <LargeCardButtonImage src={STATIC + (currentlyPlaying ? "pause.png" : "play.png")}/>
                    <LargeCardButtonText>{currentlyPlaying ? "Pause" : "Play"}</LargeCardButtonText>
                </TogglePlayButton>
            </Tooltip>

            {loggedIn &&
                <LargeCardButton onClick={onAddToPlaylist}>
                    <LargeCardButtonImage src={STATIC + "add_to_playlist.png"}/>
                    <LargeCardButtonText>Add to playlist</LargeCardButtonText>
                </LargeCardButton>
            }
        </LeftButtonContainer>

        <ReleaseDate>{getDateFormatFromString(TrackUtils.getEarliestReleaseDate(track))}</ReleaseDate>
        <Duration>{getTimeFormat(TrackUtils.getDuration(track) / 1000)}</Duration>
        <Externals onClick={() => dispatch(showTrackExternalsOverlay(track))}>
            {ExternalUtils.toJsx(track.externals, canPlay, track, playingTrack, playing, platform, 20)}
        </Externals>
    </SearchTrackCardContainer>
}

const SearchArtistCardContainer = styled(LargeCardWithButtons)`
    grid-template-columns: auto 15px auto 1fr;
`;

const ArtistContainer = styled.div`
    grid-column: 3;
    grid-row: 1;
`;

const ArtistName = styled(LargeCardText)<HoverProps>`
    font-size: ${REGULAR_FONT_SIZE};
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    ${props => props.hover && css`
        color: ${PASTEL_PURPLE};
    `}
    
    &:hover {
        color: ${PASTEL_PURPLE};
    }
`;

interface SearchArtistCardProps {
    artist: ArtistExtendedIsFollowing;
    isAdmin: boolean;
}

// TODO num tracks in row 2 ? or something else, externals on right side
export function SearchArtistCard(props: SearchArtistCardProps) {
    const [hover, setHover] = useState(false);
    const [artist, setArtist] = useState(props.artist);
    const loggedIn = !!useSelector(getStorageKey);

    const artistParams = new URLSearchParams({
        type: "artist",
        external_id: artist.externals[0].external_id,
        platform: artist.externals[0].platform,
    });

    const onToggleFollow = async() => {
        try {
            let artist_id = artist.artist_id;
            if (artist_id == null) {
                const external = artist.externals[0];
                artist_id = await get_artist_id_by_external(external.external_id!, external.platform!);
            }

            if (artist.is_following) {
                await unfollow_artist(artist_id);
            } else {
                await follow_artist(artist_id);
            }
            const updatedArtist = await get_artist(artist_id);
            setArtist({...updatedArtist.artist, is_following: updatedArtist.is_following});
        } catch (e) {
            if (e instanceof APIException) toast.error(e.message);
        }
    };

    return <SearchArtistCardContainer>
        <LargeCardImage
            src={ArtistUtils.getImage(artist)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        />
        <ArtistContainer>
            <ExternalArtistMenu
                menuType={ContextMenu}
                component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                    <ArtistName as={Link} to={"/external?" + artistParams.toString()} hover={hover} {...props} ref={forwardedRef}>{artist.name}</ArtistName>
                )}
                artist={artist}
            />
        </ArtistContainer>

        <LeftButtonContainer>
            {loggedIn &&
                <LargeCardButton onClick={onToggleFollow}>
                    <LargeCardButtonImage src={STATIC + (artist.is_following ? "unfollow.png" : "follow.png")}/>
                    <LargeCardButtonText>{artist.is_following ? "Unfollow": "Follow"}</LargeCardButtonText>
                </LargeCardButton>
            }
        </LeftButtonContainer>
    </SearchArtistCardContainer>
}