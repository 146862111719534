import React, {useEffect, useState} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {getStorageKey} from "../redux-store/userSlice";
import {Loading} from "../components/Loading";
import {APIException} from "../api";
import {setWindowProperties} from "../util/format";
import toast from "react-hot-toast";

interface OAuth2Props {
    callback: (code: string) => void;
}

export function OAuth2(props: OAuth2Props) {
    if (!useSelector(getStorageKey)) {
        return <Navigate to="/"/>
    }

    setWindowProperties("Verifying connection...");

    const queryString = new URLSearchParams(useLocation().search);
    const code = queryString.get("code")!;

    const [isVerified, setVerified] = useState(false);

    useEffect(() => {
        if (isVerified) return;
        (async() => {
            try {
                await props.callback(code);
                setVerified(true);
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, [isVerified]);

    if (!isVerified) {
        return <Loading/>
    }

    return <Navigate to="/settings"/>
}
