import React, {useContext, useEffect, useState} from "react";
import {getTimeFormat} from "../../util/format";
import {CanPlayProps, Repeat, TrackUtils} from "../../types";
import {STATIC} from "../../constants";
import {globalContext} from "../../index";
import {useDispatch, useSelector} from "react-redux";
import {cycleRepeat, getCurrentTime, getExternalId, getPlatform, getPlaying, getRepeat, getShuffle, getTrack, toggleShuffle} from "../../redux-store/playbackSlice";
import {FOREGROUND, REGULAR_FONT_SIZE, SLIDER_STYLE, Tooltip, WHITE_TO_BACKGROUND, WHITE_TO_PINK, WHITE_TO_PURPLE} from "../reusable/styled/StyledComponents";
import styled, {css} from "styled-components";
import "../../css/seekBar.css";
import {followCursor} from "tippy.js";
import {RangeInput} from "../reusable/styled/Input";

const PlaybackPanelContainer = styled.div`
    justify-self: center;
    width: 100%;
    padding: 0 5px 0 5px;
    
    grid-column: 3;
    
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, auto 18px) repeat(2, auto 5px) 1fr 5px auto;
`;

const Button = styled.button`
    width: 24px;
    height: 24px;
    transition: transform 0.1s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
`;

const CanPlayButton = styled(Button)<CanPlayProps>`
    &:hover {
        transform: none;
    }
    
    ${props => props.canPlay ? css`
        &:hover {
            transform: scale(1.1);
        }
    ` : css`
        filter: ${WHITE_TO_BACKGROUND};
        cursor: default !important;
    `}
`;

const SkipPreviousButton = styled(CanPlayButton)`
    grid-column: 1;
`;

const TogglePlayButton = styled(CanPlayButton)`
    grid-column: 3;
`;

const SkipNextButton = styled(CanPlayButton)`
    grid-column: 5;
`;

interface ToggleShuffleProps {
    shuffle: boolean;
}

const ToggleShuffleButton = styled(Button)<ToggleShuffleProps>`
    grid-column: 7;

    ${props => props.shuffle && css`
        filter: ${WHITE_TO_PURPLE};
    `}
`;

interface ToggleRepeatProps {
    repeat: Repeat;
}

const ToggleRepeatButton = styled(Button)<ToggleRepeatProps>`
    grid-column: 9;
    
    ${props => props.repeat == "single" && css`
        filter: ${WHITE_TO_PINK};
    `}
    
    ${props => props.repeat == "multiple" && css`
        filter: ${WHITE_TO_PURPLE};
    `}
`;

const ButtonImage = styled.img`
    width: 24px;
    height: 24px;
`;

const Time = styled.p`
    text-align: center;
    width: 50px;
    color: ${FOREGROUND};
    font-size: ${REGULAR_FONT_SIZE};
`;

const CurrentTime = styled(Time)`
    grid-column: 11;
`;

const DurationTime = styled(Time)`
    grid-column: 15;
`;

const SeekBar = styled(RangeInput)`
    grid-column: 13;
`;

export function PlaybackPanel() {
    const {macroController} = useContext(globalContext);
    const track = useSelector(getTrack);
    const playing = useSelector(getPlaying);
    const shuffle = useSelector(getShuffle);
    const repeat = useSelector(getRepeat);
    const currentTime = useSelector(getCurrentTime);
    const externalId = useSelector(getExternalId);
    const platform = useSelector(getPlatform);
    const dispatch = useDispatch();

    const [playHover, setPlayHover] = useState(false);
    const [shuffleHover, setShuffleHover] = useState(false);
    const [repeatHover, setRepeatHover] = useState(false);
    const [seekHover, setSeekHover] = useState(false);
    const [x, setX] = useState(0);

    const samples = 1000; // number of points in the progress bar
    const playPauseImage = STATIC + (playing ? "pause.png" : "play.png");
    const duration = TrackUtils.getDuration(track, externalId, platform); // in ms

    const canPlay = !TrackUtils.isEmpty(track);

    const repeatTooltipContent = () => {
        const x = repeat == "none" ? "None" :
            (repeat == "single" ? "Track" : "Playlist / Artist");
        return "Toggle Repeat (Currently " + x + ")";
    };

    const onSkipPrevious = async() => {
        if (!canPlay) return;
        await macroController.skipPrevious();
    };

    const onSkipNext = async() => {
        if (!canPlay) return;
        await macroController.skipNext();
    };

    const onTogglePlay = async() => {
        if (!canPlay) return;
        await macroController.macroTogglePlay(TrackUtils.empty());
    };

    const onSeek = async(e: React.ChangeEvent<HTMLInputElement>) => {
        if (!canPlay) return;
        const percent = Number(e.target.value) / samples;
        const newTime = percent * duration;
        await macroController.seek(newTime);
    };

    // update seek bar 50 times a second
    useEffect(() => {
        if (!playing) return;
        const interval = setInterval(async() => {
            await macroController.update();
        }, 20);
        return () => clearInterval(interval);
    }, [playing]);

    useEffect(() => {
        const seekBar = document.getElementById("seekBar")!;
        seekBar.style.setProperty("--seek", SLIDER_STYLE((currentTime / duration * 100) || 0));
    }, [currentTime]);

    const onMouseMove = (e: React.MouseEvent<HTMLInputElement>) => {
        const seekBar = e.target as HTMLInputElement;
        // https://stackoverflow.com/questions/30473169/how-to-get-the-value-of-the-hovered-time-on-input-range#comment64635650_32579181
        const newX = (e.clientX - seekBar.getBoundingClientRect().left - 6) / (seekBar.clientWidth - 12);
        setX(Math.max(Math.min(newX, 1), 0));
    };

    // TODO user can type exact time in currenttime
    return <PlaybackPanelContainer>
        <SkipPreviousButton onClick={onSkipPrevious} canPlay={canPlay}>
            <ButtonImage src={STATIC + "skip_previous.png"}/>
        </SkipPreviousButton>

        <Tooltip content="No track to play." visible={!canPlay && playHover}>
            <TogglePlayButton onClick={onTogglePlay} canPlay={canPlay}
                              onMouseEnter={() => setPlayHover(true)}
                              onMouseLeave={() => setPlayHover(false)}>
                <ButtonImage src={playPauseImage}/>
            </TogglePlayButton>
        </Tooltip>

        <SkipNextButton onClick={onSkipNext} canPlay={canPlay}>
            <ButtonImage src={STATIC + "skip_next.png"}/>
        </SkipNextButton>

        <Tooltip content={"Toggle Shuffle (Currently " + (shuffle ? "On" : "Off") + ")"} visible={shuffleHover}>
            <ToggleShuffleButton onClick={() => dispatch(toggleShuffle())} shuffle={shuffle}
                                 onMouseEnter={() => setShuffleHover(true)}
                                 onMouseLeave={() => setShuffleHover(false)}>
                <ButtonImage src={STATIC + "shuffle.png"}/>
            </ToggleShuffleButton>
        </Tooltip>

        <Tooltip content={repeatTooltipContent()} visible={repeatHover}>
            <ToggleRepeatButton onClick={() => dispatch(cycleRepeat())} repeat={repeat}
                                onMouseEnter={() => setRepeatHover(true)}
                                onMouseLeave={() => setRepeatHover(false)}>
                <ButtonImage src={STATIC + "repeat.png"}/>
            </ToggleRepeatButton>
        </Tooltip>



        <CurrentTime>
            {getTimeFormat(currentTime / 1000 || 0)}
        </CurrentTime>
        <Tooltip content={getTimeFormat(x * duration / 1000 || 0)} visible={seekHover}
                 followCursor="horizontal" plugins={[followCursor]}>
            <SeekBar className="seekBar" id="seekBar" thumbVisible={seekHover} type="range" min="0" max={samples}
                     value={(currentTime * samples / duration) || 0} onInput={onSeek}
                     onMouseEnter={() => setSeekHover(true)} onMouseLeave={() => setSeekHover(false)}
                     onMouseMove={onMouseMove}/>
        </Tooltip>
        <DurationTime>
            {getTimeFormat(duration / 1000)}
        </DurationTime>
    </PlaybackPanelContainer>
}