import React, {ForwardedRef, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {APIException, get_user} from "../api";
import {DropdownButton, DropdownButtonImage, MIDTITLE_FONT_SIZE, PASTEL_PURPLE} from "../components/reusable/styled/StyledComponents";
import {DUMMY, LOGO, STATIC} from "../constants";
import styled from "styled-components";
import {IncompletePlaylist, PlaylistUtils, SortDirection, SortPlaylistBy, UserBase, UserUtils} from "../types";
import {setWindowProperties} from "../util/format";
import {BodyContainer, ContentContainer} from "../components/reusable/styled/Container";
import {PlaylistSortHeaders} from "../components/SortHeader";
import {UserPlaylistCard} from "../components/cards/SortablePlaylistCard";
import {Helmet} from "react-helmet";
import toast from "react-hot-toast";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {UserMenu} from "../components/reusable/functional/Menu";
import {Lost} from "./lost";

const UserContentContainer = styled(ContentContainer)`
    grid-template-columns: auto 15px 1fr 15px auto 15px;
    grid-template-rows: auto 0 auto 25px auto 15px; // 2nd row can be adjusted if desired
`;

const Image = styled.img`
    grid-column: 1;
    grid-row: 1 / 4;
    width: 125px;
    height: 125px;
    cursor: pointer;
    box-shadow: 0 0 10px 5px #00000040;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        transform: scale(1.02);
    }
`;

const Name = styled.p`
    justify-self: left;
    height: 85px;
    line-height: 85px;
    font-size: ${MIDTITLE_FONT_SIZE};
    color: ${PASTEL_PURPLE};
    font-weight: bold;
    grid-column: 3;
    grid-row: 1 / 4;

    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const PositionedDropdownButton = styled(DropdownButton)`
    grid-column: 7;
    grid-row: 1 / 4;
`;

const UserPlaylistResults = styled.div`
    width: 100%;
    grid-column: 1 / span 9999;
    grid-row: 8;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
`;

export function User() {
    const params = useParams();
    const username = params.username!;

    const [user, setUser] = useState<UserBase | null>(UserUtils.empty());
    const [playlists, setPlaylists] = useState<IncompletePlaylist[]>([]);
    const [sortBy, setSortBy] = useState<SortPlaylistBy>("creationDate");
    const [sortDirection, setSortDirection] = useState<SortDirection>("descending");

    const [cards, setCards] = useState<React.JSX.Element[]>([]);
    const [state, setState] = useState(true); // whenever user is modified (playlists changed)
    const refreshState = () => setState(!state);

    useEffect(() => {
        (async() => {
            try {
                const results = await get_user(username);
                const user = results.user;
                setPlaylists(results.playlists);
                setUser(user);
                setWindowProperties(user.username, "Browse " + user.username + "'s profile on Amogustream.");
            } catch (e) {
                if (e instanceof APIException) {
                    toast.error(e.message);
                    setUser(null);
                }
            }
        })();
    }, [state, username]);

    useEffect(() => {
        let actualCards = [];
        for (const playlist of PlaylistUtils.sort(playlists, sortBy, sortDirection)) {
            actualCards.push(
                <UserPlaylistCard
                    playlist={playlist as IncompletePlaylist}
                    number={actualCards.length + 1}
                    refreshState={refreshState}
                />
            )
        }
        setCards(actualCards);
    }, [playlists, sortBy, sortDirection]);

    // Other Functions
    const onSort = (newSortBy: SortPlaylistBy) => {
        if (newSortBy != sortBy) {
            setSortDirection("ascending");
            setSortBy(newSortBy);
        } else {
            setSortDirection(sortDirection == "ascending" ? "descending" : "ascending");
        }
    };

    if (user == null) return <Lost/>;

    return <BodyContainer>
        <Helmet>
            <meta property="og:title" content={username}/>
            <meta property="og:description" content={"Browse " + username + "'s profile on Amogustream."}/>
            <meta property="og:image" content={LOGO}/>
        </Helmet>
        <UserContentContainer>
            <Image src={DUMMY}/> {/*TODO clicking brings up image*/}
            <Name>{user.username}</Name>
            <UserMenu
                menuType={DropdownMenu}
                component={React.forwardRef((props, forwardedRef: ForwardedRef<any>) =>
                    <PositionedDropdownButton {...props} ref={forwardedRef}>
                        <DropdownButtonImage src={STATIC + "dropdown.png"}/>
                    </PositionedDropdownButton>
                )}
                id={username}
            />

            <PlaylistSortHeaders gridRow={5} onSort={onSort} stateSortBy={sortBy} stateSortDirection={sortDirection}/>
            <UserPlaylistResults>{cards}</UserPlaylistResults>
        </UserContentContainer>
    </BodyContainer>
}