import {LargeCardImage, LargeCardText, LargeCardWithoutButtons, PASTEL_PURPLE, REGULAR_FONT_SIZE, SMALL_FONT_SIZE} from "../reusable/styled/StyledComponents";
import styled, {css} from "styled-components";
import React, {ForwardedRef, useState} from "react";
import {ExternalBase, ExternalModel, ExternalPlaylist, HoverProps, PlaylistUtils, TrackBase, TrackExternal} from "../../types";
import {STATIC} from "../../constants";
import {getDateFormatFromString, getExternalLink, getTimeFormat} from "../../util/format";
import {ExternalMenu, TrackExternalMenu} from "../reusable/functional/Menu";
import * as ContextMenu from "@radix-ui/react-context-menu";
import {Link} from "react-router-dom";

const BaseCardContainer = styled(LargeCardWithoutButtons)`
    grid-template-columns: auto 15px auto 5px auto 5px 1fr auto;
    //                     image     left     publicImg    right
    //                          pad       pad      pad space
`;

const Cover = styled(LargeCardImage)`
    object-fit: contain;
    background-color: #00000040;
`;

const TopText = styled(LargeCardText)<HoverProps>`
    font-size: ${REGULAR_FONT_SIZE};
    font-weight: bold;
    cursor: pointer;

    grid-column: 3;
    grid-row: 1;

    ${props => props.hover && css`
        color: ${PASTEL_PURPLE};
    `}
`;

const MidText = styled(LargeCardText)`
    font-size: ${SMALL_FONT_SIZE};
    grid-column: 3 / 8;
    grid-row: 2;
`;

const BottomText = styled(LargeCardText)`
    font-size: ${SMALL_FONT_SIZE};
    grid-column: 3 / 8;
    grid-row: 3;
`;

const PublicImage = styled.img`
    width: 10px;
    height: 10px;

    grid-column: 5;
    grid-row: 1;
`;

const External = styled(Link)`
    width: 70px;
    height: 70px;
    display: grid;
    grid-column: 8;
    grid-row: 1 / 4;
`;

const ExternalImage = styled.img`
    width: 100%;
    height: 100%;
    scale: 0.5;
    transition: all 0.1s ease-in-out;
    grid-column: 8;
    grid-row: 1 / 4;
    &:hover {
        transform: scale(1.1);
    }
`;

interface BaseExternalFunctionalCardProps {
    onClickMain: () => void;
    menu: (props: any) => React.JSX.Element;
    menuProps: any;
    image: string; // main click
    topText: string; // main click
    midText: string;
    bottomText: string;
    publicImage?: string;
    external: ExternalBase;
    isAdmin: boolean;
    url: string;
    search: string;
    type: ExternalModel;
}

// clicking on the external isnt just to open it to a new tab, e.g. importing external playlist
function BaseExternalFunctionalCard(props: BaseExternalFunctionalCardProps) {
    const [hover, setHover] = useState(false);
    const img = STATIC + props.external.platform + ".png";

    return <props.menu
        {...props.menuProps}
        component={React.forwardRef((nestedProps, forwardedRef: ForwardedRef<any>) =>
            <BaseCardContainer {...nestedProps} ref={forwardedRef} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <Cover src={props.image} onClick={props.onClickMain}/>
                <TopText onClick={props.onClickMain} hover={hover}>
                    {props.topText}
                </TopText>
                <MidText>{props.midText}</MidText>
                {props.publicImage && <PublicImage src={props.publicImage}/>}
                <External as="button">
                    <ExternalImage src={img}/>
                </External>
                <BottomText>{props.bottomText}</BottomText>
            </BaseCardContainer>
        )}
    />
}

interface BaseExternalLinkCardProps {
    menu: (props: any) => React.JSX.Element;
    menuProps: any;
    isAdmin: boolean;
    image: string;
    topText: string;
    midText: string;
    bottomText: string;
    publicImage?: string;
    external: ExternalBase;
    url: string;
    type: ExternalModel;
}

// clicking on the external just opens in new tab
// TODO fix styling for link and image: hovering card should make them all behave as if theyre being hovered
function BaseExternalLinkCard(props: BaseExternalLinkCardProps) {
    const [hover, setHover] = useState(false);
    const img = STATIC + props.external.platform + ".png";

    return <props.menu
        {...props.menuProps}
        menuType={ContextMenu}
        component={React.forwardRef((nestedProps, forwardedRef: ForwardedRef<any>) =>
            <BaseCardContainer as={Link} to={props.url} target="_blank" onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} {...nestedProps} ref={forwardedRef}>
                <Cover src={props.image}/>
                <TopText hover={hover}>
                    {props.topText}
                </TopText>
                <MidText>{props.midText}</MidText>
                {props.publicImage && <PublicImage src={props.publicImage}/>}
                <External as="button">
                    <ExternalImage src={img}/>
                </External>
                <BottomText>{props.bottomText}</BottomText>
            </BaseCardContainer>
        )}
    />
}

interface ExternalPlaylistCardProps {
    externalPlaylist: ExternalPlaylist;
    onClickMain: () => void;
    isAdmin: boolean;
}

export function ExternalPlaylistCard(props: ExternalPlaylistCardProps) {
    const e = props.externalPlaylist;
    // TODO add button row to base card then have whole card go to link, then add button here to import
    return <BaseExternalFunctionalCard
        isAdmin={props.isAdmin}
        onClickMain={props.onClickMain}
        menu={ExternalMenu}
        menuProps={{
            items: [],
            menuType: ContextMenu,
            external: e,
            isAdmin: props.isAdmin,
            search: "",
            type: "externalPlaylist",
            refreshState: () => {},
        }}
        image={e.cover}
        topText={PlaylistUtils.formatName(e.name)}
        midText={PlaylistUtils.formatDescription(e.description)}
        bottomText={PlaylistUtils.formatNumTracks(e.length)}
        external={e}
        url={getExternalLink(e, "externalPlaylist")}
        search=""
        type="externalPlaylist"
    />
}

interface ExternalTrackCardProps {
    track: TrackBase;
    external: TrackExternal;
    isAdmin: boolean;
    refreshState: () => void;
}

export function ExternalTrackCard(props: ExternalTrackCardProps) {
    const e = props.external;

    return <BaseExternalLinkCard
        menu={TrackExternalMenu}
        menuProps={{
            menuType: ContextMenu,
            external: e,
            track: props.track,
            isAdmin: props.isAdmin,
            type: "trackExternal",
            refreshState: props.refreshState,
        }}
        isAdmin={props.isAdmin}
        image={e.cover}
        topText={e.name /*+ " (from " + albumName + ")" TODO*/}
        midText={getDateFormatFromString(e.release_date)}
        bottomText={getTimeFormat(e.duration_ms / 1000)}
        external={e}
        url={getExternalLink(e, "trackExternal")}
        type="trackExternal"
    />
}