import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getStorageKey, setStorageKey, setStorageUsername} from "../redux-store/userSlice";
import {APIException, register} from "../api";
import {Navigate} from "react-router-dom";
import styled from "styled-components";
import {setWindowProperties} from "../util/format";
import {AuthContentContainer, BodyContainer} from "../components/reusable/styled/Container";
import {AuthInput} from "../components/reusable/styled/Input";
import {Title} from "../components/reusable/styled/Title";
import toast from "react-hot-toast";

export const RegisterForm = styled.form`
    grid-column: 1;
    grid-row: 3;
    width: 100%;

    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: repeat(2, auto 10px) auto 25px auto;
`;

const Username = styled(AuthInput)`
    grid-row: 1;
`;

const Password1 = styled(AuthInput)`
    grid-row: 3;
`;

const Password2 = styled(AuthInput)`
    grid-row: 5;
`;

const Submit = styled(AuthInput)`
    font-weight: bold;
    grid-row: 7;
    transition: all 0.1s ease-in-out;
`;

export function Register() {

    const [username, setUsername] = useState("");
    const [password1, setPassword1] = useState ("");
    const [password2, setPassword2] = useState ("");
    const dispatch = useDispatch();

    if (!!useSelector(getStorageKey)) {
        return <Navigate to="/"/>
    }

    setWindowProperties("Register");

    const onSubmit = async(e: React.FormEvent<HTMLElement>) => {
        e.preventDefault();
        try {
            const data = await register(username, password1, password2);
            dispatch(setStorageKey(data.key));
            dispatch(setStorageUsername(username));
            setUsername(username);
        } catch (e) {
            if (e instanceof APIException) {
                toast.error(e.message);
                setPassword1("");
                setPassword2("");
            }
        }
    };

    return <BodyContainer>
        <AuthContentContainer>
            <Title>Register</Title>
            <RegisterForm onSubmit={onSubmit}>
                <Username onChange={(e) => setUsername(e.target.value)} value={username} placeholder="Username"/>
                <Password1 type="password" onChange={(e) => setPassword1(e.target.value)}
                           value={password1} placeholder="Password"/>
                <Password2 type="password" onChange={(e) => setPassword2(e.target.value)}
                           value={password2} placeholder="Confirm Password"/>
                <Submit type="submit" value="Register"/>
            </RegisterForm>
        </AuthContentContainer>
    </BodyContainer>
}