import React, {useState} from "react";
import {STATIC} from "../constants";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import {REGULAR_FONT_SIZE, SELECTION} from "./reusable/styled/StyledComponents";
import {Input} from "./reusable/styled/Input";
import {onClickLink} from "../util/functions";

/**
 * TODO
 * - search dropdown with history in localstorage
 */

const SearchBarForm = styled.form`
    background-color: ${SELECTION};
    border-radius: 5px;
    
    width: 100%;
    height: 45px;
    grid-column: 3;
    
    padding-right: 10px;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr auto;
`;

const SearchInput = styled(Input)`
    font-size: ${REGULAR_FONT_SIZE};
`;

const SearchButton = styled.img`
    grid-column: 2;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    
    &:hover {
        transform: scale(1.1);
    }
`;

export function SearchBar() {
    const navigate = useNavigate();
    const [input, setInput] = useState("");

    const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setInput((e.target as HTMLInputElement).value);
    };

    const onSubmit = (e: React.FormEvent | React.MouseEvent, isClickEvent: boolean) => {
        e.preventDefault();
        if (isClickEvent) {
            onClickLink(e as React.MouseEvent, navigate, "search?q=" + input);
        } else {
            navigate("/search?q=" + input);
        }
    };

    return <SearchBarForm onSubmit={(e) => onSubmit(e, false)}>
        <SearchInput autoComplete="false" onInput={onInputChange} placeholder="Track, artist, or album name..."/>
        <SearchButton onClick={(e) => onSubmit(e, true)} src={STATIC + "search.png"}/>
    </SearchBarForm>
}