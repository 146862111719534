import React, {useEffect, useState} from "react";
import {FOREGROUND, PASTEL_GREEN, PASTEL_ORANGE, PASTEL_PURPLE, PASTEL_RED, REGULAR_FONT_SIZE} from "../components/reusable/styled/StyledComponents";
import styled, {css} from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {Platform, Model, MODELS, PLATFORMS} from "../types";
import {APIException, is_admin, search, search_platforms} from "../api";
import {SearchArtistCard, SearchTrackCard} from "../components/cards/SearchCard";
import {setWindowProperties} from "../util/format";
import {BodyContainer, ContentContainer} from "../components/reusable/styled/Container";
import {Subtitle, Title} from "../components/reusable/styled/Title";
import {Helmet} from "react-helmet";
import {LOGO} from "../constants";
import toast from "react-hot-toast";

/**
 * TODO
 * - pagination
 */

const SearchContentContainer = styled(ContentContainer)`
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(4, auto 15px) 1fr;
`;

const PlatformFilters = styled.div`
    width: 100%;
    height: 30px;
    grid-column: 1 / span 9999;
    grid-row: 5;

    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(3, auto 5px) auto 1fr;
`;

const FilterText = styled.p`
    font-size: ${REGULAR_FONT_SIZE};
    color: ${FOREGROUND};
    grid-column: 1;
`;

interface FilterProps {
    checked: boolean,
}

const Filter = styled.button<FilterProps>`
    color: ${FOREGROUND};
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    padding: 0 10px 0 10px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    
    ${props => props.disabled && css`
        cursor: not-allowed;
        &:hover {
            background-color: transparent !important;
        }
    `}
`;

const PlatformFilterSoundcloud = styled(Filter)`
    grid-column: 3;
    
    &:hover {
        background-color: ${PASTEL_ORANGE};
    }
    
    ${props => props.checked && css`
        background-color: ${PASTEL_ORANGE}C0;
    `}
`;

const PlatformFilterSpotify = styled(Filter)`
    grid-column: 5;
    
    &:hover {
        background-color: ${PASTEL_GREEN};
    }
    
    ${props => props.checked && css`
        background-color: ${PASTEL_GREEN}C0;
    `}
`;

const PlatformFilterYoutube = styled(Filter)`
    grid-column: 7;
    
    &:hover {
        background-color: ${PASTEL_RED};
    }
    
    ${props => props.checked && css`
        background-color: ${PASTEL_RED}C0;
    `}
`;

const TypeFilters = styled.div`
    width: 100%;
    height: 30px;
    grid-column: 1 / span 9999;
    grid-row: 7;

    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(3, auto 5px) auto 1fr;
`;

const TypeFilter = styled(Filter)`
    &:hover {
        background: ${PASTEL_PURPLE};
    }
    
    ${props => props.checked && css`
        background: ${PASTEL_PURPLE}C0;
    `}
`;

const TypeFilterTrack = styled(TypeFilter)`
    grid-column: 3;
`;

const TypeFilterAlbum = styled(TypeFilter)`
    grid-column: 5;
`;

const TypeFilterArtist = styled(TypeFilter)`
    grid-column: 7;
`;

const SearchResults = styled.div`
    width: 100%;
    grid-column: 1 / span 9999;
    grid-row: 9;
    
    display: grid;
    justify-items: center;
    align-items: center;
    grid-auto-flow: row;
`;

export function Search() {
    const queryString = new URLSearchParams(useLocation().search);
    const query = queryString.get("q") || "";
    const initialType = MODELS.includes(queryString.get("type") || "") ? (queryString.get("type") as Model) : "track";
    let initialPlatforms = (queryString.get("platforms") || "").split(",");
    initialPlatforms = initialPlatforms.map(p => p.toLowerCase()).filter(p => (PLATFORMS as string[]).includes(p));

    const [cards, setCards] = useState<React.JSX.Element[]>([]);
    const [platforms, setPlatforms] = useState<Platform[]>(initialPlatforms as Platform[]);
    const [type, setType] = useState<Model>(initialType);
    const [validPlatforms, setValidPlatforms] = useState<Platform[]>([]);
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        (async() => {
            try {
                const validPlatforms = await search_platforms();
                setPlatforms(platforms.length == 0 ? validPlatforms : platforms.filter(p => validPlatforms.includes(p)));
                setValidPlatforms(validPlatforms);
                setIsAdmin(await is_admin());
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams({
            q: query,
            ...(platforms.length > 0 && {platforms: platforms.join(",")}),
            type,
        });

        navigate("/search?" + params.toString(), {replace: true});

        if (platforms.length == 0) {
            setCards([]);
            return;
        }
        (async() => {
            try {
                const searchResults = await search(query, platforms.join(","), type, 0);
                setWindowProperties(`Search results for "${query}"`,
                    `Browse search results for ${query} on Amogustream.`);

                let actualCards = [];
                for (const track of searchResults.tracks) {
                    actualCards.push(<SearchTrackCard track={track} key={JSON.stringify(track)} isAdmin={isAdmin}/>);
                }
                for (const album of searchResults.albums) {
                    // TODO
                }
                for (const artist of searchResults.artists) {
                    actualCards.push(<SearchArtistCard artist={artist} key={JSON.stringify(artist)} isAdmin={isAdmin}/>);
                }
                // TODO if actualCards length == 0, show text for no search results
                setCards(actualCards);
            } catch (e) {
                if (e instanceof APIException) toast.error(e.message);
            }
        })();
    }, [queryString.get("q"), JSON.stringify(platforms), type]);

    const togglePlatformFilter = (platform: Platform) => {
        let newFilters = [...platforms];
        if (newFilters.includes(platform)) {
            newFilters.splice(newFilters.indexOf(platform), 1);
        } else {
            newFilters.push(platform);
        }
        setPlatforms(newFilters);
    };

    return <BodyContainer>
        <Helmet>
            <meta property="og:title" content={"Search results for " + query}/>
            <meta property="og:description" content={"Browse results for \"" + query + "\" on Amogustream."}/>
            <meta property="og:image" content={LOGO}/>
        </Helmet>
        <SearchContentContainer>
            <Title>Search</Title>
            <Subtitle>Results for "{query}":</Subtitle>

            <PlatformFilters>
                <FilterText>Filter by platform:</FilterText>
                <PlatformFilterSoundcloud checked={platforms.includes("soundcloud")}
                                          disabled={!validPlatforms.includes("soundcloud")}
                                          onClick={() => togglePlatformFilter("soundcloud")}>
                    SoundCloud
                </PlatformFilterSoundcloud>

                <PlatformFilterSpotify checked={platforms.includes("spotify")}
                                       disabled={!validPlatforms.includes("spotify")}
                                       onClick={() => togglePlatformFilter("spotify")}>
                    Spotify
                </PlatformFilterSpotify>

                <PlatformFilterYoutube checked={platforms.includes("youtube")}
                                       disabled={!validPlatforms.includes("youtube")}
                                       onClick={() => togglePlatformFilter("youtube")}>
                    YouTube
                </PlatformFilterYoutube>
            </PlatformFilters>

            <TypeFilters>
                <FilterText>Filter by type:</FilterText>
                <TypeFilterTrack checked={type == "track"} onClick={() => setType("track")}>
                    Track
                </TypeFilterTrack>
                <TypeFilterAlbum checked={type == "album"} onClick={() => setType("album")}>
                    Album
                </TypeFilterAlbum>
                <TypeFilterArtist checked={type == "artist"} onClick={() => setType("artist")}>
                    Artist
                </TypeFilterArtist>
            </TypeFilters>

            <SearchResults>{cards}</SearchResults>
        </SearchContentContainer>
    </BodyContainer>
}
