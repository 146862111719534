import {DUMMY, LOGO, STATIC} from "./constants";
import {getValidPlaybackExternal, sharesElement} from "./util/functions";
import React from "react";
import styled, {css} from "styled-components";
import {MacroController} from "./embedControllers";
import {DimImage, WHITE_TO_GRAY, WHITE_TO_PLATFORM} from "./components/reusable/styled/StyledComponents";
import {getTimeFormat} from "./util/format";

// TODO move props to relevant file like was done for styledComponents?

/* GLOBAL */
declare global {
    interface Window {
        onSpotifyWebPlaybackSDKReady: Function;
        SC: any;
        Spotify: any;
        YT: any;
    }
}

export interface GlobalContext {
    macroController: MacroController;
}

export type Repeat = "none" | "single" | "multiple";

export const PLATFORMS: Platform[] = ["soundcloud", "spotify", "youtube"];
export type Platform = "soundcloud" | "spotify" | "youtube";
export type Platforms = {
    [key in Platform]: string[];
};

export interface Tokens {
    soundcloud: string,
    spotify: string,
    youtube: string,
}

export type Position = [number, number];

export interface UncertainTrack {
    track_id: number | null;
    externals: Platforms;
}

export class UncertainUtils {
    static fromTrack(trackJson: any): UncertainTrack {
        return {
            track_id: trackJson.track_id || null,
            externals: {
                "soundcloud": trackJson.soundcloud_ids,
                "spotify": trackJson.spotify_ids,
                "youtube": trackJson.youtube_ids,
            }
        }
    }
}

export type SortTrackBy = "default" | "name" | "artists" | "releaseDate" | "duration";
export type SortExternalTrackBy = "name" | "releaseDate" | "platform";
export type SortPlaylistBy = "name" | "creationDate" | "length";
export type SortBy = SortTrackBy | SortExternalTrackBy | SortPlaylistBy;

export type SortDirection = "ascending" | "descending" | null;

export interface TitleProps {
    children: React.ReactNode;
}

export interface OverlayProps {
    visible: boolean;
    setVisibility: (newVisibility: boolean) => void;
}

export interface HoverProps {
    hover: boolean,
}

export interface TabProps {
    selected: boolean;
}

export interface VisibleProps {
    visible: boolean;
}

export interface RangeProps {
    thumbVisible: boolean;
}

export interface NumProps {
    num: number;
}

/* MODELS */
export const EXTERNAL_MODELS = ["artistExternal", "trackExternal", "externalPlaylist"];
export type ExternalModel = "artistExternal" | "trackExternal" | "externalPlaylist";
export const MODELS = ["artist", "album", "track", "playlist", "user", ...EXTERNAL_MODELS];
export type Model = "artist" | "album" | "track" | "playlist" | "user" | ExternalModel;

export interface ExternalBase {
    external_id: string;
    platform: Platform;
    external_url: string;
}

export interface ArtistBase {
    artist_id: number | null;
    name: string;
    externals: ArtistExternal[];
}

export interface ArtistExternal extends ExternalBase {
    image: string;
}

export interface ArtistExtendedIsFollowing extends ArtistBase {
    is_following: boolean;
}

export interface ArtistComposedTracksIsFollowing {
    artist: ArtistBase;
    tracks: TrackBase[];
    is_following: boolean;
}

// this one should be exported
export interface CanPlayProps {
    canPlay: boolean;
}

export class ArtistUtils {
    static fromJson(artistJson: any): ArtistBase {
        return {
            artist_id: artistJson.artist_id || null,
            name: artistJson.name,
            externals: artistJson.externals,
        };
    }

    static empty(): ArtistBase {
        return {
            artist_id: null,
            name: "",
            externals: [],
        };
    }

    static getNames(artists: ArtistBase[], separator: string = ", ") {
        return artists.map(artist => artist.name).join(separator);
    }

    // TODO turn into animated slideshow thing later
    static getImage(artist: ArtistBase) {
        const images = artist.externals.filter(a => a.image != "").map(a => a.image);
        return images.length > 0 ? images[0] : DUMMY;
    }

    static searchString(artist: ArtistBase) {
        return artist.name;
    }
}

export interface Album {

}

export interface TrackBase {
    track_id: number | null;
    artists: ArtistBase[];
    albums: Album[];
    name: string;
    externals: TrackExternal[];
}

export interface TrackExternal extends ExternalBase {
    name: string;
    release_date: string;
    duration_ms: number;
    cover: string;
}

export class TrackUtils {
    static fromJson(trackJson: any): TrackBase {
        return {
            track_id: trackJson.track_id || null,
            artists: trackJson.artists,
            albums: trackJson.albums,
            name: trackJson.name,
            externals: trackJson.externals,
        };
    }

    static empty(): TrackBase {
        return {
            track_id: null,
            artists: [],
            albums: [],
            name: "",
            externals: [],
        };
    }

    static isEmpty(track: TrackBase) {
        return track.track_id == null && track.externals.length == 0;
    }

    static equals(trackA: TrackBase, trackB: TrackBase) {
        return (trackA.track_id != null && trackA.track_id == trackB.track_id) ||
            sharesElement(trackA.externals, trackB.externals);
    }

    static getExternalIdAndPlatform(track: TrackBase): [string, Platform] {
        return [track.externals[0].external_id, track.externals[0].platform];
    }

    static getExternalIdForPlatform(track: TrackBase, platform: Platform) {
        const externalIds = track.externals.filter(t => t.platform == platform).map(a => a.external_id);
        return externalIds.length > 0 ? externalIds[0] : null;
    }

    // assumes already checked for valid platform
    static getPlaybackSourceForPlatform(track: TrackBase, platform: Platform) {
        return track.externals.filter(e => e.platform == platform)[0];
    }

    static getEarliestReleaseDate(track: TrackBase) {
        return track.externals[0].release_date;
    }

    static getDuration(track: TrackBase, external_id: string = "", platform: Platform | null = null) {
        if (TrackUtils.isEmpty(track)) return 0;
        if (external_id != "") {
            return track.externals.filter(e => e.external_id == external_id && e.platform == platform)[0].duration_ms;
        }
        try {
            return getValidPlaybackExternal(track).duration_ms;
        } catch (e) {
            return 0;
        }
    }

    // TODO could be fun to also take in currentlyPlaying and change it to the animated logo if playing
    static getCover(track: TrackBase, external_id: string = "", platform: Platform | null = null) {
        if (TrackUtils.isEmpty(track)) return LOGO;
        let cover;
        if (external_id != "") {
            cover = track.externals.filter(e => e.external_id == external_id && e.platform == platform)[0].cover;
        } else {
            try {
                cover = getValidPlaybackExternal(track).cover;
            } catch (e) {
                cover = LOGO;
            }
        }
        return cover == "" ? LOGO: cover;
    }

    // TODO maybe sort by multiple fields if equal on first field? also consider album tracklist order when same release date}
    static sort(tracks: TrackBase[], sortBy: SortTrackBy, direction: SortDirection) {
        const s = direction == "ascending" ? -1 : 1;

        switch (sortBy) {
            case "name": {
                return [...tracks].sort((a: TrackBase, b: TrackBase) => {
                    return a.name.toLowerCase() < b.name.toLowerCase() ? s : -s;
                });
            } case "artists": {
                return [...tracks].sort((a: TrackBase, b: TrackBase) => {
                    return ArtistUtils.getNames(a.artists).toLowerCase() < ArtistUtils.getNames(b.artists).toLowerCase() ?
                        s : -s;
                });
            } case "releaseDate": {
                return [...tracks].sort((a: TrackBase, b: TrackBase) => {
                    return TrackUtils.getEarliestReleaseDate(a) < TrackUtils.getEarliestReleaseDate(b) ? s : -s;
                });
            } case "duration": {
                return [...tracks].sort((a: TrackBase, b: TrackBase) => {
                    return TrackUtils.getDuration(a) < TrackUtils.getDuration(b) ? s : -s;
                });
            } default: {
                return tracks;
            }
        }
    }

    /**
     * Returns true if the currently playing track is not the provided track OR if player is paused.
     * If an external id is provided, it will also be compared.
     */
    static currentlyPlaying(track: TrackBase, playingTrack: TrackBase, playing: boolean,
                            externalId: string | null = null, playingExternalId: string | null = null,
                            platform: Platform | null = null, playingPlatform: Platform | null = null) {
        return playing && TrackUtils.equals(track, playingTrack) &&
            (externalId == null || externalId == playingExternalId) &&
            (platform == null || platform == playingPlatform);
    }

    static searchString(track: TrackBase) {
        return `${ArtistUtils.getNames(track.artists, " ")} ${track.name}`;
    }
}

interface ExternalProps {
    canPlay: boolean;
    currentlyPlaying: boolean;
    platform: Platform;
}

const External = styled(DimImage)<ExternalProps>`
    ${props => !props.canPlay && css`
        filter: ${WHITE_TO_GRAY};
    `};
    ${props => props.currentlyPlaying && css`
        filter: ${WHITE_TO_PLATFORM(props.platform)};
    `}
`;

export class ExternalUtils {
    static toJsx(externals: ExternalBase[], canPlay: boolean, track: TrackBase, playingTrack: TrackBase,
                 playing: boolean, playingPlatform: Platform | null, dim: number) {
        let ret: React.JSX.Element[] = [];
        for (const platform of new Set(externals.map(e => e.platform).sort())) {
            const currentlyPlaying = TrackUtils.currentlyPlaying(track, playingTrack, playing,
                null, null, platform, playingPlatform);
            ret.push(
                <External canPlay={canPlay} currentlyPlaying={currentlyPlaying} platform={platform} dim={dim}
                          src={STATIC + platform + ".png"} key={platform}/>
            );
        }
        return ret;
    }

    static sortForTrack(externals: TrackExternal[], sortBy: SortExternalTrackBy, direction: SortDirection) {
        const s = direction == "ascending" ? -1 : 1;

        switch (sortBy) {
            case "name": {
                return [...externals].sort((a: TrackExternal, b: TrackExternal) => {
                    return a.name < b.name ? s : -s;
                });
            } case "releaseDate": {
                return [...externals].sort((a: TrackExternal, b: TrackExternal) => {
                    return a.release_date < b.release_date ? s : -s;
                });
            } case "platform": {
                return [...externals].sort((a: TrackExternal, b: TrackExternal) => {
                    return a.platform < b.platform ? s : -s;
                });
            } default: {
                return externals;
            }
        }
    }
}

export interface PlaylistBase {
    playlist_id: number | null;
    owner: UserBase;
    tracks: TrackBase[] | number[];
    name: string;
    description: string;
    creation_date: string;
    cover: string;
    public: boolean;
}

export interface IncompletePlaylist extends PlaylistBase {
    tracks: number[];
}

export interface IncompletePlaylistExtendedIn extends IncompletePlaylist {
    in: boolean;
}

export interface IncompletePlaylistExtendedIntersections extends IncompletePlaylist {
    intersections: number;
}

export interface CompletePlaylist extends PlaylistBase {
    tracks: TrackBase[];
}

export interface CompletePlaylistComposedIsOwner {
    playlist: CompletePlaylist;
    is_owner: boolean;
}

export interface ExternalPlaylist extends ExternalBase {
    owner: string;
    length: number;
    name: string;
    description: string;
    cover: string;
    public: boolean;
}

export class PlaylistUtils {
    static empty(): PlaylistBase {
        return {
            playlist_id: null,
            owner: UserUtils.empty(),
            tracks: [],
            name: "",
            description: "",
            creation_date: "",
            cover: DUMMY,
            public: false,
        };
    }

    static formatName(name: string) {
        return name == "" ? "(Untitled Playlist)" : name;
    }

    static formatDescription(description: string) {
        return description == "" ? "(No Description)" : description;
    }

    static formatNumTracks(length: number, capitalise: boolean = true) {
        const t = capitalise ? "T" : "t";
        return length.toString() + " " + (length == 1 ? t + "rack" : t + "racks");
    }

    static formatDuration(tracks: TrackBase[]) {
        return getTimeFormat(tracks.reduce((sum, current) => sum + TrackUtils.getDuration(current), 0) / 1000);
    }

    static sort(playlists: PlaylistBase[], sortBy: SortPlaylistBy, direction: SortDirection) {
        const s = direction == "ascending" ? -1 : 1;

        switch (sortBy) {
            case "name": {
                return [...playlists].sort((a: PlaylistBase, b: PlaylistBase) => {
                    return a.name.toLowerCase() < b.name.toLowerCase() ? s : -s;
                });
            } case "creationDate": {
                return [...playlists].sort((a: PlaylistBase, b: PlaylistBase) => {
                    return a.creation_date < b.creation_date ? s : -s;
                });
            } case "length": {
                return [...playlists].sort((a: PlaylistBase, b: PlaylistBase) => {
                    return a.tracks.length < b.tracks.length ? s : -s;
                });
            } default: {
                return playlists;
            }
        }
    }
}

export interface UserBase {
    username: string;
}

export interface UserComposedPlaylists {
    user: UserBase;
    playlists: IncompletePlaylist[];
}

export class UserUtils {
    static empty(): UserBase {
        return {
            username: "",
        };
    }

    static searchString(user: UserBase) {
        return user.username;
    }
}

/* PLAYBACK */
export interface ContinuousPlaybackSource {
    playlistId: number | null;
    artistId: number | null;
    trackId: number | null;
    sortTrackBy: SortTrackBy;
    sortDirection: SortDirection;
    cachedSourceName: string | null; // either playlist name or artist name
}

export class ContinuousPlaybackSourceUtils {
    static empty() {
        const continuousPlaybackSource: ContinuousPlaybackSource = {
            playlistId: null,
            artistId: null,
            trackId: null,
            sortTrackBy: "default",
            sortDirection: null,
            cachedSourceName: null,
        };
        return continuousPlaybackSource;
    }

    static isEmpty(continuousPlaybackSource: ContinuousPlaybackSource) {
        return continuousPlaybackSource.playlistId == null &&
            continuousPlaybackSource.artistId == null;
    }

    static sourceEquals(a: ContinuousPlaybackSource, b: ContinuousPlaybackSource) {
        return (a.playlistId != null && a.playlistId == b.playlistId) ||
            ((a.artistId) != null && a.artistId == b.artistId)
    }

    static getSourceName(continuousPlaybackSource: ContinuousPlaybackSource): [string, string] {
        if (continuousPlaybackSource.playlistId == null && continuousPlaybackSource.artistId == null) return ["", ""];
        return [
            continuousPlaybackSource.playlistId != null ? "playlist" : "artist",
            continuousPlaybackSource.cachedSourceName!
        ];
    }

    static getSourceUrl(continuousPlaybackSource: ContinuousPlaybackSource) {
        if (continuousPlaybackSource.playlistId == null && continuousPlaybackSource.artistId == null) return "";
        return continuousPlaybackSource.playlistId == null ? "artist/" + continuousPlaybackSource.artistId :
            "playlist/" + continuousPlaybackSource.playlistId;
    }
}